/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getNearestRestaurants } from '../fn/restaurants/get-nearest-restaurants';
import { GetNearestRestaurants$Params } from '../fn/restaurants/get-nearest-restaurants';
import { getRestaurantByIndex } from '../fn/restaurants/get-restaurant-by-index';
import { GetRestaurantByIndex$Params } from '../fn/restaurants/get-restaurant-by-index';
import { getRestaurants } from '../fn/restaurants/get-restaurants';
import { GetRestaurants$Params } from '../fn/restaurants/get-restaurants';
import { getRestaurantsPreview } from '../fn/restaurants/get-restaurants-preview';
import { GetRestaurantsPreview$Params } from '../fn/restaurants/get-restaurants-preview';
import { NearestRestaurantDto } from '../models/nearest-restaurant-dto';
import { RestaurantDto } from '../models/restaurant-dto';
import { RestaurantPreviewDto } from '../models/restaurant-preview-dto';
import { RestaurantsPaginatedResponseDto } from '../models/restaurants-paginated-response-dto';
import { RestaurantsPreviewPaginatedResponseDto } from '../models/restaurants-preview-paginated-response-dto';
import { searchRestaurants } from '../fn/restaurants/search-restaurants';
import { SearchRestaurants$Params } from '../fn/restaurants/search-restaurants';
import { searchRestaurantsPreview } from '../fn/restaurants/search-restaurants-preview';
import { SearchRestaurantsPreview$Params } from '../fn/restaurants/search-restaurants-preview';

@Injectable({ providedIn: 'root' })
export class RestaurantsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRestaurants()` */
  static readonly GetRestaurantsPath = '/api/v1/restaurants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestaurants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestaurants$Response(
    params?: GetRestaurants$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RestaurantDto>>> {
    return getRestaurants(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestaurants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestaurants(
    params?: GetRestaurants$Params,
    context?: HttpContext,
  ): Observable<Array<RestaurantDto>> {
    return this.getRestaurants$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<Array<RestaurantDto>>): Array<RestaurantDto> =>
          r.body,
      ),
    );
  }

  /** Path part for operation `getRestaurantsPreview()` */
  static readonly GetRestaurantsPreviewPath = '/api/v1/restaurants/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestaurantsPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestaurantsPreview$Response(
    params?: GetRestaurantsPreview$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RestaurantPreviewDto>>> {
    return getRestaurantsPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestaurantsPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestaurantsPreview(
    params?: GetRestaurantsPreview$Params,
    context?: HttpContext,
  ): Observable<Array<RestaurantPreviewDto>> {
    return this.getRestaurantsPreview$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<RestaurantPreviewDto>>,
        ): Array<RestaurantPreviewDto> => r.body,
      ),
    );
  }

  /** Path part for operation `searchRestaurants()` */
  static readonly SearchRestaurantsPath = '/api/v1/restaurants/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRestaurants()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRestaurants$Response(
    params?: SearchRestaurants$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RestaurantsPaginatedResponseDto>> {
    return searchRestaurants(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchRestaurants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRestaurants(
    params?: SearchRestaurants$Params,
    context?: HttpContext,
  ): Observable<RestaurantsPaginatedResponseDto> {
    return this.searchRestaurants$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<RestaurantsPaginatedResponseDto>,
        ): RestaurantsPaginatedResponseDto => r.body,
      ),
    );
  }

  /** Path part for operation `searchRestaurantsPreview()` */
  static readonly SearchRestaurantsPreviewPath =
    '/api/v1/restaurants/search/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchRestaurantsPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRestaurantsPreview$Response(
    params?: SearchRestaurantsPreview$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RestaurantsPreviewPaginatedResponseDto>> {
    return searchRestaurantsPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchRestaurantsPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchRestaurantsPreview(
    params?: SearchRestaurantsPreview$Params,
    context?: HttpContext,
  ): Observable<RestaurantsPreviewPaginatedResponseDto> {
    return this.searchRestaurantsPreview$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<RestaurantsPreviewPaginatedResponseDto>,
        ): RestaurantsPreviewPaginatedResponseDto => r.body,
      ),
    );
  }

  /** Path part for operation `getRestaurantByIndex()` */
  static readonly GetRestaurantByIndexPath = '/api/v1/restaurants/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestaurantByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestaurantByIndex$Response(
    params: GetRestaurantByIndex$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RestaurantDto>> {
    return getRestaurantByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestaurantByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestaurantByIndex(
    params: GetRestaurantByIndex$Params,
    context?: HttpContext,
  ): Observable<RestaurantDto> {
    return this.getRestaurantByIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestaurantDto>): RestaurantDto => r.body),
    );
  }

  /** Path part for operation `getNearestRestaurants()` */
  static readonly GetNearestRestaurantsPath = '/api/v1/restaurants/nearest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNearestRestaurants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestRestaurants$Response(
    params: GetNearestRestaurants$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<NearestRestaurantDto>>> {
    return getNearestRestaurants(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNearestRestaurants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearestRestaurants(
    params: GetNearestRestaurants$Params,
    context?: HttpContext,
  ): Observable<Array<NearestRestaurantDto>> {
    return this.getNearestRestaurants$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<NearestRestaurantDto>>,
        ): Array<NearestRestaurantDto> => r.body,
      ),
    );
  }
}
