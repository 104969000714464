/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RouteDto } from '../../models/route-dto';

export interface GetRouteByIndex$Params {
  index: string;
}

export function getRouteByIndex(
  http: HttpClient,
  rootUrl: string,
  params: GetRouteByIndex$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<RouteDto>> {
  const rb = new RequestBuilder(rootUrl, getRouteByIndex.PATH, 'get');
  if (params) {
    rb.path('index', params.index, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RouteDto>;
      }),
    );
}

getRouteByIndex.PATH = '/api/v1/routes/{index}';
