import { Component, Input } from '@angular/core';
import {
  ReviewsApiService,
  TripAdvisorReviewsListDto,
} from '../api/generated/abuduba-api';

@Component({
  selector: 'app-tripadvisor-reviews',
  templateUrl: 'tripadvisor-reviews.component.html',
  styleUrl: 'tripadvisor-reviews.component.scss',
})
export class TripadvisorReviewsComponent {
  public reviewsData: TripAdvisorReviewsListDto;

  @Input()
  tripadvisorLocationId: number;

  constructor(private readonly reviewsApiService: ReviewsApiService) {}

  ngOnInit() {
    this.reviewsApiService
      .getTripadvisorReviews({
        locationId: this.tripadvisorLocationId,
      })
      .subscribe((data) => {
        this.reviewsData = data;
      });
  }
}
