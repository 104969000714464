/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRegionById } from '../fn/regions/get-region-by-id';
import { GetRegionById$Params } from '../fn/regions/get-region-by-id';
import { getRegionByIndex } from '../fn/regions/get-region-by-index';
import { GetRegionByIndex$Params } from '../fn/regions/get-region-by-index';
import { getRegions } from '../fn/regions/get-regions';
import { GetRegions$Params } from '../fn/regions/get-regions';
import { RegionDto } from '../models/region-dto';

@Injectable({ providedIn: 'root' })
export class RegionsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRegionById()` */
  static readonly GetRegionByIdPath = '/api/v1/regions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionById$Response(
    params: GetRegionById$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegionDto>> {
    return getRegionById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionById(
    params: GetRegionById$Params,
    context?: HttpContext,
  ): Observable<RegionDto> {
    return this.getRegionById$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegionDto>): RegionDto => r.body),
    );
  }

  /** Path part for operation `getRegionByIndex()` */
  static readonly GetRegionByIndexPath = '/api/v1/regions/index/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegionByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionByIndex$Response(
    params: GetRegionByIndex$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegionDto>> {
    return getRegionByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegionByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionByIndex(
    params: GetRegionByIndex$Params,
    context?: HttpContext,
  ): Observable<RegionDto> {
    return this.getRegionByIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegionDto>): RegionDto => r.body),
    );
  }

  /** Path part for operation `getRegions()` */
  static readonly GetRegionsPath = '/api/v1/regions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegions$Response(
    params?: GetRegions$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegionDto>>> {
    return getRegions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegions(
    params?: GetRegions$Params,
    context?: HttpContext,
  ): Observable<Array<RegionDto>> {
    return this.getRegions$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<Array<RegionDto>>): Array<RegionDto> => r.body,
      ),
    );
  }
}
