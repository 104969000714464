import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RestaurantPreviewDto } from '../../core/api/generated/abuduba-api';
import { CurrencyManager } from '../../core/currency-manager';
import { getMainPictureUrl, removeHtmlTags } from '../../places/places.utils';
import {
  CreateFavouriteItemDtoItemTypeEnum,
  RestaurantDtoAwardsEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import {
  getAwardName,
  getCuisineName,
  getHighlightName,
  getPriceLevelName,
} from '../restaurants.utils';

@Component({
  selector: 'app-restaurant-preview',
  styleUrls: ['restaurant-preview.component.scss'],
  templateUrl: 'restaurant-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantPreviewComponent {
  @Input()
  public restaurant: RestaurantPreviewDto;

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  @Input()
  public saved = false;

  protected readonly getMainPictureUrl = getMainPictureUrl;

  getRestaurantAwards() {
    return this.horizontal
      ? this.restaurant.awards
      : this.restaurant.awards.slice(0, 3);
  }

  getRestaurantHighlights() {
    return this.horizontal
      ? this.restaurant.highlights
      : this.restaurant.highlights.slice(0, 3);
  }

  getRestaurantCuisines() {
    return this.horizontal
      ? this.restaurant.cuisines
      : this.restaurant.cuisines.slice(0, 3);
  }

  isMichelinStar() {
    return this.restaurant.awards.includes(
      RestaurantDtoAwardsEnum.MichelinStar,
    );
  }

  protected readonly removeHtmlTags = removeHtmlTags;

  constructor(public readonly currencyManager: CurrencyManager) {}

  protected readonly getAwardName = getAwardName;
  protected readonly getPriceLevelName = getPriceLevelName;
  protected readonly getCuisineName = getCuisineName;
  protected readonly getHighlightName = getHighlightName;
  protected readonly CreateFavouriteItemDtoItemTypeEnum =
    CreateFavouriteItemDtoItemTypeEnum;
}
