import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  RestaurantPreviewDto,
  RestaurantsApiService,
  RoutePreviewDto,
  RoutesApiService,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { placeActivities } from '../../places/places.types';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { AiChatComponent } from '../../core/ai-chat/ai-chat.component';
import { ApiHelper } from '../../core/api/api.helper';
import { Meta, Title } from '@angular/platform-browser';
import { getMainPictureUrl } from '../../places/places.utils';
import {
  GetPlacesPreviewSortByEnum,
  PlaceCollectionDataDtoActivitiesEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import { forkJoin, switchMap } from 'rxjs';
import { skip } from 'rxjs/operators';

dayjs.extend(utc);

@Component({
  selector: 'app-region-full-page',
  styleUrls: ['region.component.scss'],
  templateUrl: 'region.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegionComponent implements OnInit {
  public region?: RegionDto | null;
  public places: PlacePreviewDto[];
  public routes: RoutePreviewDto[];
  public restaurants: RestaurantPreviewDto[];
  public photoSpots: PlacePreviewDto[];
  public totalPlaces: number = 0;
  public totalRestaurants: number = 0;
  public totalRoutes: number = 0;

  public isDescriptionOpen = false;

  @ViewChild(AiChatComponent)
  private aiChatComponent: AiChatComponent;

  public placeActivities = [
    placeActivities.Shopping,
    placeActivities.Photo,
    placeActivities.Natural,
    placeActivities.Entertainment,
    placeActivities.Cultural,
    placeActivities.Beaches,
    placeActivities.Trekking,
  ];

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly regionsApiService: RegionsApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly restaurantsApiService: RestaurantsApiService,
    private apiHelper: ApiHelper,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    titleService.setTitle('Abuduba - Region Information');
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.pipe(skip(1)).subscribe(() => {
        this.updateData();
      });
    }
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Regions',
        url: '/regions',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
    ];
  }

  private generatePageMeta(data: RegionDto) {
    const title = `Abuduba - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl([data.file], 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData(): void {
    const params = this.route.snapshot.paramMap;
    const index = String(params.get('index'));

    this.regionsApiService
      .getRegionByIndex({ index })
      .pipe(
        switchMap((regionData) => {
          this.region = regionData;
          this.generatePageMeta(regionData);

          const regionId = regionData.id;

          // Fetch all dependent data in parallel
          return forkJoin({
            places: this.placesApiService.searchPlacesPreview({
              regionIds: [regionId],
              limit: 15,
              sortBy: GetPlacesPreviewSortByEnum.Rating,
            }),
            photoSpots: this.placesApiService.getPlacesPreview({
              limit: 15,
              sortBy: GetPlacesPreviewSortByEnum.Rating,
              activities: [PlaceCollectionDataDtoActivitiesEnum.Photo],
              regionIds: [regionId],
            }),
            routes: this.routesApiService.searchRoutesPreview({
              regionIds: [regionId],
              limit: 15,
            }),
            restaurants: this.restaurantsApiService.searchRestaurantsPreview({
              regionIds: [regionId],
              limit: 15,
            }),
          });
        }),
      )
      .subscribe({
        next: ({ places, photoSpots, routes, restaurants }) => {
          // Update component state with fetched data
          this.places = places.items;
          this.totalPlaces = places.totalFilteredItems;

          this.photoSpots = photoSpots;

          this.routes = routes.items;
          this.totalRoutes = routes.totalFilteredItems;

          this.restaurants = restaurants.items;
          this.totalRestaurants = restaurants.totalFilteredItems;

          // Trigger change detection
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          if (err?.status === 404) {
            this.region = null;
            this.changeDetectorRef.detectChanges();
          } else {
            console.error('Error fetching data:', err);
          }
        },
      });
  }

  openAiChat() {
    this.aiChatComponent.open();
  }
}
