<div id="articles_list" class="content-wrap">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="header">
    <div class="categories">
      <div
        class="category"
        *ngFor="let c of categories"
        [class.filtered]="c.filtered"
        (click)="filterByCategory(c.id, !c.filtered)">
        {{ c.name }}
        <ng-icon [name]="c.icon"></ng-icon>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="articles-content">
      <div id="articles_list_body">
        <div class="not-found" *ngIf="articles?.length === 0 && !isLoading">
          Articles not found
        </div>

        <app-loader *ngIf="isLoading"></app-loader>

        <div class="list">
          <app-article-preview
            *ngFor="let article of articles; trackBy: articleTrackBy"
            [horizontal]="isHorizontal"
            [article]="article"></app-article-preview>
        </div>

        <div class="pagination-block" *ngIf="articles && articles.length > 0">
          <app-pagination
            [totalPages]="totalPages"
            [currentPage]="page"
            (pageChanged)="onPageChanged($event)"
            [isLoading]="isLoading"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
