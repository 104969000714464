import { Component, Input } from '@angular/core';

export interface IBreadcrumb {
  label: string;
  url: string;
  qs?: Record<string, any>;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  styleUrl: 'breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  @Input() items: IBreadcrumb[] = [];
}
