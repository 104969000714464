<div class="favourite-popup-component">
  <div
    class="favourite-button-text"
    [class.saved]="saved"
    *ngIf="textMode"
    (click)="open($event)">
    <ng-icon name="bootstrapHeartFill"></ng-icon>
    <span>{{ saved ? 'Saved' : 'Save' }}</span>
  </div>

  <div
    class="favourite-button-symbol"
    [class.saved]="saved"
    *ngIf="!textMode"
    (click)="open($event)"
    title="Add to favourites">
    <ng-icon name="bootstrapHeartFill"></ng-icon>
  </div>

  <div
    class="favourite-popup-content"
    *ngIf="isVisible && popup"
    (click)="close($event)">
    <div
      class="favourite-popup"
      (click)="$event.stopPropagation(); $event.preventDefault()">
      <div class="header">
        <div class="title">Save to favourites</div>
        <div class="actions">
          <div class="close" (click)="close($event)">
            <ng-icon name="matCloseOutline"></ng-icon>
          </div>
        </div>
      </div>

      <div class="body">
        <div class="lists-body" *ngIf="!createListMode">
          <app-entities-preview-list
            [favouriteLists]="lists"
            [onFavouriteListClick]="onFavouriteListClick.bind(this)"
            [nowrap]="false"></app-entities-preview-list>

          <div class="create-list-button" (click)="createListMode = true">
            <ng-icon name="matPlus"></ng-icon>
            Create new list
          </div>

          <div
            class="close-button"
            *ngIf="lists.length > 0"
            (click)="close($event)">
            Close
          </div>
        </div>

        <div class="create-list-body" *ngIf="createListMode">
          <input type="text" [(ngModel)]="listTitleValue" placeholder="Name" />

          <div class="buttons">
            <div class="clear" (click)="createListMode = false">Clear</div>
            <div class="create" (click)="createList()">Create</div>
          </div>
        </div>

        <div class="error" *ngIf="isError">
          Oops! Something went wrong. Please try again later.
        </div>
      </div>
    </div>
  </div>
</div>
