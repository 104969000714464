import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AssistantApiService,
  PlacePreviewDto,
} from '../api/generated/abuduba-api';
import { keyBy } from 'lodash';
import { ApiHelper } from '../api/api.helper';

export interface IChatMessage {
  message: string;
  date: Date;
  senderName: string;
  isMe: boolean;
  places?: PlacePreviewDto[];
}

@Component({
  selector: 'app-ai-chat',
  templateUrl: 'ai-chat.component.html',
  styleUrl: 'ai-chat.component.scss',
})
export class AiChatComponent implements OnInit {
  @Input() title: string;

  @Input() placeId?: number;

  @Input() isClosed?: boolean;

  @Input() firstMessage?: string;

  @ViewChild('content')
  contentBlock: ElementRef;

  ASSISTANT_NAME = 'Assistant';

  messages: IChatMessage[] = [];

  inProgress = false;
  _isClosed = false;

  threadId?: string;
  errorMessage?: string;
  userMessage: string = '';

  constructor(
    private readonly assistantApiService: AssistantApiService,
    private readonly apiHelper: ApiHelper,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this._isClosed = !!this.isClosed;

    if (this.firstMessage) {
      this.messages.push({
        message: this.firstMessage,
        date: new Date(),
        isMe: false,
        senderName: this.ASSISTANT_NAME,
      });
    }
  }

  close() {
    this._isClosed = true;
  }

  open() {
    this._isClosed = false;
  }

  sendMessage() {
    const body = this.userMessage.trim();

    if (!body || this.inProgress) {
      return;
    }

    const message: IChatMessage = {
      date: new Date(),
      isMe: true,
      senderName: 'User',
      message: body,
    };

    this.messages.push(message);

    this.inProgress = true;
    this.errorMessage = undefined;
    this.userMessage = '';
    this.changeDetectorRef.detectChanges();
    this.scrollDown();

    this.assistantApiService
      .askAssistant({
        input: body,
        threadId: this.threadId,
        placeId: this.placeId,
      })
      .subscribe({
        next: (res) => {
          this.addAssistantMessage(res.output, res.places);
          this.scrollDown();
          this.threadId = res.threadId;
        },
        error: (err: Error) => {
          this.errorMessage = this.apiHelper.getErrorMessage(err);
        },
        complete: () => {
          this.inProgress = false;
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  addAssistantMessage(body: string, places?: PlacePreviewDto[]) {
    const placesMap = keyBy(places, 'id');

    const preparedBody = body.replace(
      /\[placeId:(\d+)\]/g,
      function (match, id) {
        const place = placesMap[+id];

        if (!place) {
          return `<span class="place-link">unknown place</span>`;
        }

        return `<a href="/places/${place.index}" target="_blank">${place.name}</a>`;
      },
    );

    const message: IChatMessage = {
      date: new Date(),
      isMe: false,
      senderName: this.ASSISTANT_NAME,
      message: preparedBody,
      places: places?.filter((p) => p.id !== this.placeId),
    };

    this.messages.push(message);
  }

  scrollDown() {
    setTimeout(() => {
      this.contentBlock.nativeElement.scrollTop =
        this.contentBlock.nativeElement.scrollHeight;
    }, 0);
  }
}
