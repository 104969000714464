import { RestaurantDtoPriceLevelEnum } from '../../shared-enums';
import { RestaurantDtoAwardsEnum } from '../../shared-enums';
import { RestaurantDtoHighlightsEnum } from '../../shared-enums';
import { RestaurantDtoCuisinesEnum } from '../../shared-enums';
import { GetPlacesPreviewWorkingTimesEnum } from '../../shared-enums';
import { GetRestaurantsPreviewSortByEnum } from '../../shared-enums';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RestaurantDto } from '../../models/restaurant-dto';

export interface GetRestaurants$Params {
  offset?: number;
  limit?: number;

  /**
   * Cheap = Cheap; Medium = Medium; Expensive = Expensive
   */
  priceLevels?: RestaurantDtoPriceLevelEnum[];

  /**
   * MichelinStar = MichelinStar; Worlds50BestRestaurants = Worlds50BestRestaurants; JamesBeardAward = JamesBeardAward; WineSpectatorAward = WineSpectatorAward; ForbesTravelGuideStars = ForbesTravelGuideStars; TimeOutDubaiRestaurantAward = TimeOutDubaiRestaurantAward; BBCGoodFoodMiddleEastAward = BBCGoodFoodMiddleEastAward
   */
  awards?: RestaurantDtoAwardsEnum[];

  /**
   * HiddenGem = HiddenGem; InstagramSpot = InstagramSpot; BestForDining = BestForDining; FamilyFriendly = FamilyFriendly; Romantic = Romantic; BestForGroups = BestForGroups; OutdoorSeating = OutdoorSeating; PetFriendly = PetFriendly; LiveMusic = LiveMusic; RooftopView = RooftopView; CasualDining = CasualDining; FineDining = FineDining; QuickBites = QuickBites; LateNightDining = LateNightDining; VeganFriendly = VeganFriendly; GlutenFreeOptions = GlutenFreeOptions; KidFriendly = KidFriendly; ScenicView = ScenicView
   */
  highlights?: RestaurantDtoHighlightsEnum[];

  /**
   * Afghan = Afghan; African = African; American = American; Arabic = Arabic; Argentinean = Argentinean; Armenian = Armenian; Asian = Asian; Assyrian = Assyrian; Australian = Australian; Bangladeshi = Bangladeshi; Bar = Bar; Barbecue = Barbecue; Belgian = Belgian; Brazilian = Brazilian; BrewPub = BrewPub; British = British; Burmese = Burmese; Cafe = Cafe; CajunAndCreole = CajunAndCreole; Campania = Campania; Cantonese = Cantonese; Caribbean = Caribbean; CentralAmerican = CentralAmerican; CentralAsian = CentralAsian; CentralEuropean = CentralEuropean; Chinese = Chinese; Contemporary = Contemporary; Deli = Deli; Diner = Diner; Diningbars = Diningbars; EasternEuropean = EasternEuropean; Egyptian = Egyptian; Ethiopian = Ethiopian; European = European; FastFood = FastFood; Filipino = Filipino; French = French; Fusion = Fusion; Gastropub = Gastropub; Georgian = Georgian; German = German; Greek = Greek; Hawaiian = Hawaiian; Healthy = Healthy; HongKong = HongKong; Indian = Indian; Indonesian = Indonesian; International = International; Irish = Irish; Italian = Italian; Japanese = Japanese; JapaneseFusion = JapaneseFusion; Korean = Korean; Latin = Latin; Lebanese = Lebanese; Malaysian = Malaysian; Mediterranean = Mediterranean; Mexican = Mexican; MiddleEastern = MiddleEastern; Mongolian = Mongolian; Moroccan = Moroccan; Neapolitan = Neapolitan; Pakistani = Pakistani; Persian = Persian; Peruvian = Peruvian; Pizza = Pizza; Portuguese = Portuguese; Pub = Pub; Romanian = Romanian; Seafood = Seafood; Singaporean = Singaporean; SouthAmerican = SouthAmerican; Southwestern = Southwestern; Spanish = Spanish; SriLankan = SriLankan; Steakhouse = Steakhouse; StreetFood = StreetFood; Sushi = Sushi; Szechuan = Szechuan; Thai = Thai; Turkish = Turkish; Tuscan = Tuscan; Uzbek = Uzbek; Vietnamese = Vietnamese; WineBar = WineBar
   */
  cuisines?: RestaurantDtoCuisinesEnum[];

  /**
   * OpenNow = OpenNow; Morning = Morning; Afternoon = Afternoon; Evening = Evening; Night = Night
   */
  workingTimes?: GetPlacesPreviewWorkingTimesEnum[];
  ids?: Array<number>;
  indexes?: Array<string>;
  regionIds?: Array<number>;
  ratings?: Array<number>;
  sortBy?: GetRestaurantsPreviewSortByEnum;
}

export function getRestaurants(
  http: HttpClient,
  rootUrl: string,
  params?: GetRestaurants$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<RestaurantDto>>> {
  const rb = new RequestBuilder(rootUrl, getRestaurants.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('priceLevels', params.priceLevels, {});
    rb.query('awards', params.awards, {});
    rb.query('highlights', params.highlights, {});
    rb.query('cuisines', params.cuisines, {});
    rb.query('workingTimes', params.workingTimes, {});
    rb.query('ids', params.ids, {});
    rb.query('indexes', params.indexes, {});
    rb.query('regionIds', params.regionIds, {});
    rb.query('ratings', params.ratings, {});
    rb.query('sortBy', params.sortBy, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RestaurantDto>>;
      }),
    );
}

getRestaurants.PATH = '/api/v1/restaurants';
