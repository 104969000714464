import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ArticlePreviewDto } from '../../core/api/generated/abuduba-api';
import { getMainPictureUrl } from '../../places/places.utils';
import { getCategoryName } from '../articles.utils';

@Component({
  selector: 'app-article-preview',
  styleUrls: ['article-preview.component.scss'],
  templateUrl: 'article-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePreviewComponent {
  @Input()
  public article: ArticlePreviewDto;

  @Input()
  public newTab = false;

  @Input()
  public horizontal = false;

  protected readonly getMainPictureUrl = getMainPictureUrl;
  protected readonly getCategoryName = getCategoryName;
}
