/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { check } from '../fn/health/check';
import { Check$Params } from '../fn/health/check';
import { HealthCheckResponseDto } from '../models/health-check-response-dto';

@Injectable({ providedIn: 'root' })
export class HealthApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `check()` */
  static readonly CheckPath = '/health/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `check()` instead.
   *
   * This method doesn't expect any request body.
   */
  check$Response(
    params?: Check$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<HealthCheckResponseDto>> {
    return check(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `check$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  check(
    params?: Check$Params,
    context?: HttpContext,
  ): Observable<HealthCheckResponseDto> {
    return this.check$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<HealthCheckResponseDto>,
        ): HealthCheckResponseDto => r.body,
      ),
    );
  }
}
