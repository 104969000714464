import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface EventData<T = any> {
  name: string;
  data?: T;
}

@Injectable({
  providedIn: 'root',
})
export class EventBus {
  private eventSubject = new Subject<EventData>();

  emit<T>(name: string, data?: T): void {
    this.eventSubject.next({ name, data });
  }

  on<T>(name: string): Observable<T> {
    return this.eventSubject.asObservable().pipe(
      filter((event) => event.name === name),
      map((event) => event.data as T),
    );
  }
}
