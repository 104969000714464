<div id="not_found">
  <div class="not-found-content">
    <ng-lottie
      *ngIf="apiHelper.isBrowser"
      width="300px"
      [options]="{
        path: 'assets/404.json'
      }" />

    <div class="title">Oops! Page Not Found</div>

    <div class="subtitle">
      Sorry, the page you’re looking for doesn’t exist. It might have been moved
      or deleted.
    </div>

    <a class="home" routerLink="/">Go Back Home</a>
  </div>
</div>
