<div class="filter-slider">
  <div class="title-block">
    <div class="title">{{ title }}</div>
    <div class="clear" (click)="clear()">clear</div>
  </div>

  <div class="slider" *ngIf="apiHelper.isBrowser">
    <ngx-slider
      [options]="options"
      [(value)]="valueMin"
      [(highValue)]="valueMax"
      [manualRefresh]="manualRefresh"
      (userChangeEnd)="onChange()"></ngx-slider>
  </div>
</div>
