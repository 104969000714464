import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  ActivityPreviewDto,
  ArticlePreviewDto,
  CombedInfoMainPageMetricsDto,
  CombinedInfoApiService,
  PlaceCollectionDto,
  PlacePreviewDto,
  RegionDto,
  RestaurantPreviewDto,
  RoutePreviewDto,
} from '../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { placeActivities } from '../places/places.types';
import { getMainPictureUrl } from '../places/places.utils';
import { AiChatComponent } from '../core/ai-chat/ai-chat.component';
import { AppComponent } from '../app.component';
import { CurrencyManager } from '../core/currency-manager';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

dayjs.extend(utc);

@Component({
  selector: 'app-main',
  styleUrls: ['main.component.scss'],
  templateUrl: 'main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  public articles: ArticlePreviewDto[];
  public activities: ActivityPreviewDto[];
  public places: PlacePreviewDto[];
  public restaurants: RestaurantPreviewDto[];
  public placeCollections: PlaceCollectionDto[] = [];
  public regions: RegionDto[];
  public photoSpots: PlacePreviewDto[] = [];
  public routes: RoutePreviewDto[];
  public metrics?: CombedInfoMainPageMetricsDto;

  public menu: any = [];

  @ViewChild(AiChatComponent)
  private aiChatComponent: AiChatComponent;

  public shortNumber(number: number, i = 100) {
    if (number <= i) {
      return number.toString();
    }

    const roundedNumber = Math.floor(number / i) * i;
    return `${roundedNumber}+`;
  }

  constructor(
    private readonly combinedInfoApiService: CombinedInfoApiService,
    public currencyManager: CurrencyManager,
    @Optional() parent: AppComponent,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.menu = parent.menu;

    this.generatePageMeta();
  }

  public generatePageMeta() {
    const title = 'Abuduba - Main Page';
    const description =
      'Welcome to Abuduba, your gateway to discovering the best experiences in the UAE. Explore top attractions, activities, and curated collections across popular emirates like Dubai, Abu Dhabi, and more. Plan your next adventure with Abuduba’s expert guidance.';
    const keywords =
      'Abuduba UAE, explore UAE, Dubai attractions, Abu Dhabi activities, top places UAE, restaurants UAE, Abuduba collections, UAE travel guide, popular emirates, Abuduba service, UAE tourism, best experiences UAE';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  ngOnInit() {
    this.updateData();
  }

  public updateData(): void {
    this.combinedInfoApiService.getMainPageData().subscribe((data) => {
      this.regions = data.regions;
      this.places = data.places;
      this.restaurants = data.restaurants;
      this.activities = data.activities;
      this.photoSpots = data.photoSpots;
      this.routes = data.routes;
      this.articles = data.articles;
      this.placeCollections = data.placeCollections;
      this.metrics = data.metrics;
      this.changeDetectorRef.detectChanges();
    });
  }

  openAiChat() {
    this.aiChatComponent.open();
  }

  public placeActivities = [
    placeActivities.Shopping,
    placeActivities.Photo,
    placeActivities.Natural,
    placeActivities.Entertainment,
    placeActivities.Cultural,
    placeActivities.Beaches,
    placeActivities.Trekking,
  ];
  protected readonly getMainPictureUrl = getMainPictureUrl;
}
