<div id="favourites" class="content-wrap">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="content">
    <div class="title">My Favorite Lists</div>

    <app-loader
      *ngIf="isLoading && !lists"
      [animation]="false"
      width="200px"
      height="100px"
      marginTop="30px"
      [count]="5"></app-loader>

    <div class="empty" *ngIf="!isLoading && lists && lists.length === 0">
      You haven’t created any lists yet! Start by creating your first list to
      save your favorite items.
    </div>

    <div class="list" *ngIf="lists && lists.length > 0">
      <app-entities-preview-list
        [favouriteLists]="lists"
        [nowrap]="false"></app-entities-preview-list>
    </div>
  </div>
</div>
