import { RouteDtoTypeEnum } from '../core/api/generated/abuduba-api/shared-enums';

export const routeTypes: {
  [key in RouteDtoTypeEnum]: any;
} = {
  RoadTrip: {
    name: 'Road trip',
    icon: 'matDirectionsCarOutline',
  },
  Hiking: {
    name: 'Hiking',
    icon: 'matNordicWalkingOutline',
  },
  CityTour: {
    name: 'City tour',
    icon: 'matLocationCityOutline',
  },
};
