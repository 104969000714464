import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  SearchApiService,
  SearchResultItemDto,
} from '../api/generated/abuduba-api';
import { Router } from '@angular/router';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { uniqueId } from 'lodash';

export type SearchItem = SearchResultItemDto & {
  label: 'place' | 'activity' | 'route' | 'restaurant';
};

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: 'autocomplete-input.component.html',
  styleUrl: 'autocomplete-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent {
  public searchResults: SearchItem[] = [];
  public value = '';
  public isLoading = false;

  @Input()
  public id: string = '';

  @Input()
  public placeholder: string = '';

  @Input()
  public minQueryLength: number = 2;

  @Input()
  public disableEntityRedirect = false;

  @ViewChild(AutocompleteComponent)
  public searchInput: AutocompleteComponent;

  constructor(
    private readonly searchApiService: SearchApiService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.id = uniqueId(`${'nb'.toLowerCase()}_`);
  }

  public setInitialValue(input: string) {
    const inputBlock =
      this.searchInput.elementRef.nativeElement.querySelector('input');

    setTimeout(() => {
      inputBlock.value = input;
    }, 800);
  }

  ngAfterViewInit() {
    const input =
      this.searchInput.elementRef.nativeElement.querySelector('input');

    input.onkeyup = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        this.router.navigate(['/search'], {
          queryParams: { input: input.value },
        });
      }

      this.value = input.value;
    };
  }

  public search(input: string) {
    if (input) {
      this.isLoading = true;

      this.searchApiService
        .search({
          input,
        })
        .subscribe((data) => {
          this.searchResults = [
            ...data.places.map(
              (p) =>
                <SearchItem>{
                  ...p,
                  label: 'place',
                },
            ),
            ...data.restaurants.map(
              (p) =>
                <SearchItem>{
                  ...p,
                  label: 'restaurant',
                },
            ),
            ...data.routes.map(
              (p) =>
                <SearchItem>{
                  ...p,
                  label: 'route',
                },
            ),
            ...data.activities.map(
              (p) =>
                <SearchItem>{
                  ...p,
                  label: 'activity',
                },
            ),
          ];
          this.isLoading = false;
          this.cdr.detectChanges();
        });
    } else {
      this.searchResults = [];
      this.value = '';
      this.searchInput.close();
    }
  }

  onSearchResultClick(data: SearchItem) {
    let url: string = '';

    if (this.disableEntityRedirect) {
      this.router.navigate(['/search'], {
        queryParams: { input: data.title },
      });
      return;
    }

    if (data.label === 'place') {
      url = `/places/${data.index}`;
    }

    if (data.label === 'route') {
      url = `/routes/${data.index}`;
    }

    if (data.label === 'activity') {
      url = `/activities/${data.index}`;
    }

    if (data.label === 'restaurant') {
      url = `/restaurants/${data.index}`;
    }

    if (url) {
      this.router.navigateByUrl(url);
      this.searchResults = [];
    }
  }

  public customFilter(items: any[]) {
    return items;
  }
}
