<div class="auth-popup-content" *ngIf="isVisible" (click)="close()">
  <div class="auth-popup" (click)="$event.stopPropagation()">
    <div class="header">
      <div class="title">Log in or sign up</div>
      <div class="actions">
        <div class="close" (click)="close()">
          <ng-icon name="matCloseOutline"></ng-icon>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="login-options">
        <div class="google" (click)="loginGoogle()">
          <ng-icon name="bootstrapGoogle"></ng-icon>
          Google
        </div>
      </div>
    </div>
  </div>
</div>
