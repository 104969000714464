import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { UserDto } from '../api/generated/abuduba-api';

@Injectable({ providedIn: 'root' })
export class GoogleAuthService {
  constructor(private readonly oauthService: OAuthService) {
    this.init();
  }

  private init() {
    const config: AuthConfig = {
      issuer: 'https://accounts.google.com',
      redirectUri: environment.googleRedirectUrl,
      clientId: environment.googleClientId,
      scope: 'openid profile email',
      showDebugInformation: !environment.production,
      strictDiscoveryDocumentValidation: false,
      responseType: 'token id_token',
    };

    this.oauthService.configure(config);
  }

  public fetchToken() {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  public login() {
    this.oauthService.loadDiscoveryDocumentAndLogin({
      state: window.location.href,
    });
  }

  public logout() {
    this.oauthService.logOut();
  }

  public getState() {
    return this.oauthService.state;
  }

  public getProfile(): UserDto | undefined {
    const data = this.oauthService.getIdentityClaims();

    if (data) {
      return {
        email: data['email'],
        id: data['sub'],
        name: data['name'],
        pictureUrl: data['picture'],
      };
    } else {
      return data;
    }
  }

  public getIdToken() {
    return this.oauthService.getIdToken();
  }

  public getToken() {
    return this.oauthService.getAccessToken();
  }
}
