import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  ActivityPreviewDto,
  ArticlePreviewDto,
  FavouriteItemDto,
  FavouriteListDto,
  PlaceCollectionPreviewDto,
  PlacePreviewDto,
  RegionDto,
  RestaurantPreviewDto,
  RoutePreviewDto,
} from '../api/generated/abuduba-api';
import { getMainPictureUrl } from '../../places/places.utils';
import { ApiHelper } from '../api/api.helper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { remove } from 'lodash';
import { FavouritesService } from '../favourites.service';
import { CreateFavouriteItemDtoItemTypeEnum } from '../api/generated/abuduba-api/shared-enums';
import { EventBus } from '../event-bus';

dayjs.extend(utc);

@Component({
  selector: 'app-entities-preview-list',
  styleUrls: ['entities-preview-list.component.scss'],
  templateUrl: 'entities-preview-list.component.html',
})
export class EntitiesPreviewListComponent implements OnInit {
  @Input()
  public nowrap: boolean;

  @Input()
  public blockTitle: string;

  @Input()
  public subtitle?: string;

  @Input()
  public queryParams?: Record<string, any>;

  @Input()
  public routerLinkValue?: string;

  @Input()
  public places?: PlacePreviewDto[];

  @Input()
  public articles?: ArticlePreviewDto[];

  @Input()
  public restaurants?: RestaurantPreviewDto[];

  @Input()
  public activities?: ActivityPreviewDto[];

  @Input()
  public routes?: (RoutePreviewDto & { distance?: number })[];

  @Input()
  public regions?: RegionDto[];

  @Input()
  public collections?: PlaceCollectionPreviewDto[];

  @Input()
  public favouriteLists?: FavouriteListDto[];

  @Input()
  public newTab = false;

  @Input()
  public minimize = false;

  @Input()
  public horizontal = false;

  @Input()
  public enableAutoHorizontalChange = false;

  @Input()
  public onFavouriteListClick?: (
    event: Event,
    list: FavouriteListDto & Partial<{ checked: boolean }>,
  ) => void;

  @ViewChild('entitiesBody')
  public entitiesBody: ElementRef;

  public isScrolled = false;

  public favouriteItems: FavouriteItemDto[];

  constructor(
    public apiHelper: ApiHelper,
    private readonly breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private favouritesService: FavouritesService,
    private eventBus: EventBus,
  ) {}

  loadFavourites() {
    this.favouritesService.getItems().subscribe((items) => {
      this.favouriteItems = items;
      this.changeDetectorRef.detectChanges();
    });
  }

  checkIfItemIsSaved(
    itemId: number,
    itemType: CreateFavouriteItemDtoItemTypeEnum,
  ) {
    if (!this.favouriteItems) {
      return false;
    }

    return this.favouriteItems.some(
      (item) => item.itemId === itemId && item.itemType === itemType,
    );
  }

  public get entities() {
    return (
      this.places ||
      this.articles ||
      this.routes ||
      this.regions ||
      this.collections ||
      this.activities ||
      this.restaurants ||
      this.favouriteLists ||
      []
    );
  }

  public isLoad() {
    return (
      !this.places &&
      !this.articles &&
      !this.routes &&
      !this.regions &&
      !this.collections &&
      !this.restaurants &&
      !this.favouriteLists &&
      !this.activities
    );
  }

  public isEmpty() {
    return this.entities.length === 0;
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;

  // ngAfterViewInit() {
  //   if (this.apiHelper.isBrowser) {
  //     const block =
  //       this.entitiesBody.nativeElement.querySelector('.entities-list');
  //
  //     if (block) {
  //       block.addEventListener('scroll', () => {
  //         if (block.scrollLeft > 0) {
  //           this.isScrolled = true;
  //         } else {
  //           this.isScrolled = false;
  //         }
  //       });
  //     }
  //   }
  // }
  protected readonly Math = Math;

  ngOnInit() {
    if (this.enableAutoHorizontalChange) {
      const initialValue =
        this.breakpointObserver.isMatched('(max-width: 820px)');
      this.horizontal = !initialValue;

      this.breakpointObserver
        .observe('(max-width: 820px)')
        .subscribe((result) => {
          this.horizontal = !result.matches;
          this.changeDetectorRef.detectChanges();
        });
    }

    this.loadFavourites();

    this.eventBus
      .on<FavouriteItemDto>('favourite-item-added')
      .subscribe((data: FavouriteItemDto) => {
        if (!this.favouriteItems) {
          this.favouriteItems = [];
        }

        this.favouriteItems.push(data);
        this.changeDetectorRef.detectChanges();
      });

    this.eventBus
      .on<{
        listId: number;
        itemId: number;
        itemType: CreateFavouriteItemDtoItemTypeEnum;
      }>('favourite-item-removed')
      .subscribe(
        (data: {
          listId: number;
          itemId: number;
          itemType: CreateFavouriteItemDtoItemTypeEnum;
        }) => {
          if (!this.favouriteItems) {
            return;
          }

          this.favouriteItems = this.favouriteItems.filter(
            (item) =>
              item.itemId !== data.itemId || item.itemType !== data.itemType,
          );
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  favouriteListDeleted(listId: number) {
    if (!this.favouriteLists) {
      return;
    }

    this.favouriteLists = remove(this.favouriteLists, (l) => l.id !== listId);
    this.changeDetectorRef.detectChanges();
  }

  protected readonly CreateFavouriteItemDtoItemTypeEnum =
    CreateFavouriteItemDtoItemTypeEnum;
}
