<div
  class="tripadvisor-rating"
  [class.small]="small"
  [class.text-only]="textOnly"
  *ngIf="data.rating">
  <div class="rating-block" *ngIf="!textOnly">
    <img
      class="rating"
      [ngSrc]="data.ratingImageUrl"
      [width]="small ? 100 : 120"
      [height]="small ? 15 : 20"
      alt="Rating" />
    <a class="num-reviews" [href]="data.tripadvisorUrl" target="_blank"
      >{{ data.numReviews }} reviews</a
    >
  </div>

  <div class="summary-block" *ngIf="!small || textOnly">
    <div class="rating-block" *ngIf="textOnly">
      <div class="rating-number">
        <ng-icon name="matStarOutline"></ng-icon>
        <div class="value">{{ data.rating }}</div>
      </div>

      <a class="num-reviews" [href]="data.tripadvisorUrl" target="_blank"
        >{{ data.numReviews }} reviews</a
      >
    </div>

    <div class="rank" [innerHTML]="data.rankingString"></div>
  </div>
</div>
