<div id="places_list" class="content-wrap" [class.map-view]="isMapView">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="collections-list">
    <app-entities-preview-list
      blockTitle="Explore Our Curated Collections"
      subtitle="Handpicked destinations tailored just for you"
      routerLinkValue="/collections"
      [nowrap]="true"
      [minimize]="true"
      [collections]="collections"></app-entities-preview-list>
  </div>

  <div class="header">
    <div class="filters">
      <div class="filters-button" (click)="isFiltersOpen = true">
        <ng-icon name="matFilterAltOutline"></ng-icon>
        Filters
      </div>

      <div class="map-filter">
        <div
          class="map-button"
          *ngIf="!isMapView"
          (click)="toggleMapView(true)">
          <ng-icon name="matMapOutline" *ngIf="!isMapLoading"></ng-icon>
          <div class="loading-animation" *ngIf="isMapLoading"></div>
          Show map
        </div>

        <div
          class="list-button"
          *ngIf="isMapView"
          (click)="toggleMapView(false)">
          <ng-icon name="matListOutline"></ng-icon>
          Show list
        </div>
      </div>

      <div
        class="locate-block"
        (click)="updateCurrentPosition()"
        *ngIf="userLocation === null">
        <ng-icon name="matMyLocationOutline"></ng-icon>
        <span>Locate me</span>
      </div>
    </div>

    <div class="header-group">
      <div class="title-block">
        <div class="title">
          <h1>Explore places</h1>
          <div class="loading-animation" *ngIf="isLoading"></div>
        </div>
        <div class="totals">
          <div class="total">{{ totalFilteredItems }} results</div>
        </div>
      </div>

      <div class="actions-block">
        <div
          class="locate-block"
          (click)="updateCurrentPosition()"
          *ngIf="userLocation === null">
          <ng-icon name="matMyLocationOutline"></ng-icon>
          <span>Locate me</span>
        </div>

        <div class="sorting-block" *ngIf="!isMapView">
          <app-custom-select
            icon="bootstrapSortDown"
            title="Sort By"
            (valueChange)="updateSortBy($event)"
            value="Favourite"
            [items]="[
              {
                label: 'Traveler Favorites',
                value: 'Favourite'
              },
              {
                label: 'Rating',
                value: 'Rating'
              }
            ]"></app-custom-select>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div
      id="filters"
      [class.open]="isFiltersOpen"
      (click)="closeFilters($event)">
      <div class="filters-body shadow-block">
        <div class="header">
          <div class="title">Filters</div>
          <div class="actions">
            <div class="close" (click)="isFiltersOpen = false">
              <ng-icon name="matCloseOutline"></ng-icon>
            </div>
          </div>
        </div>

        <div class="filters-list">
          <app-filter-checkbox
            [options]="activitiesFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="activitiesFilter"
            title="Activities"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="regionsFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="regionsFilter"
            title="Regions"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="ratingFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="ratingFilter"
            class="rating-filter"
            title="Rating"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="hoursFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="hoursFilter"
            title="Working hours"></app-filter-checkbox>

          <app-filter-slider
            [min]="0"
            [max]="24 * 60"
            (valueChange)="updateDurationFilter($event)"
            [format]="formatDuration.bind(this)"
            [step]="5"
            title="Duration"></app-filter-slider>

          <app-filter-slider
            [min]="0"
            [max]="maxCostsAed"
            (valueChange)="updateCostsFilter($event)"
            [format]="formatCosts.bind(this)"
            #costsFilter
            title="Costs"></app-filter-slider>

          <app-filter-checkbox
            [options]="additionalFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="additionalFilter"
            title="Additional"></app-filter-checkbox>
        </div>

        <div class="show-button" (click)="isFiltersOpen = false">
          Show {{ totalFilteredItems }} results
        </div>
      </div>
    </div>

    <div class="places-content">
      <div class="error-message" *ngIf="userLocationError">
        Geolocation error: {{ userLocationError }}
      </div>

      <div id="nearest_places" *ngIf="nearestPlaces">
        <div class="nearest-places-list">
          <app-place-near-by
            *ngFor="let p of nearestPlaces"
            [place]="p.place"
            [distance]="p.distance"></app-place-near-by>
        </div>
      </div>

      <div id="places_map_body" *ngIf="isMapView">
        <app-places-map
          [loading]="isMapLoading"
          (located)="updateCurrentPosition()"
          [center]="userLocation || undefined"></app-places-map>
      </div>

      <div id="places_list_body" *ngIf="!isMapView">
        <div class="not-found" *ngIf="places?.length === 0 && !isLoading">
          Places not found
        </div>

        <app-loader *ngIf="isLoading && !places"></app-loader>

        <div class="list">
          <app-entities-preview-list
            [enableAutoHorizontalChange]="true"
            [places]="places"></app-entities-preview-list>
        </div>

        <div class="pagination-block" *ngIf="places && places.length > 0">
          <app-pagination
            [totalPages]="totalPages"
            [currentPage]="page"
            (pageChanged)="onPageChanged($event)"
            [isLoading]="isLoading"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
