import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  PlaceCollectionDto,
  PlaceCollectionsApiService,
  PlacePreviewDto,
  PlacesApiService,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { uniq } from 'lodash';
import { getMainPictureUrl } from '../places.utils';
import { ActivatedRoute } from '@angular/router';
import { ApiHelper } from '../../core/api/api.helper';
import { Meta, Title } from '@angular/platform-browser';
import { catchError, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { skip } from 'rxjs/operators';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';

dayjs.extend(utc);

@Component({
  selector: 'app-place-collection-fill-page',
  styleUrls: ['place-collection-full-page.component.scss'],
  templateUrl: 'place-collection-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceCollectionFullPageComponent implements OnInit {
  public places: PlacePreviewDto[];
  public collection: PlaceCollectionDto;
  public collections: PlaceCollectionDto[];
  public isDescriptionOpen = false;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly placeCollectionsApiService: PlaceCollectionsApiService,
    private route: ActivatedRoute,
    private apiHelper: ApiHelper,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    titleService.setTitle('Abuduba - Collection Information');
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.collection) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Collections',
        url: `/collections`,
      },
      {
        label: this.collection.name,
        url: `/collections/${this.collection.index}`,
      },
    ];
  }

  ngOnInit() {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.pipe(skip(1)).subscribe(() => {
        this.updateData();
      });
    }
  }

  private generatePageMeta(data: PlaceCollectionDto) {
    const title = `Abuduba - ${data.name} Collection`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl([data.file], 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  private updateData() {
    const params = this.route.snapshot.paramMap;
    const index = String(params.get('index'));

    // Fetch collection by index and related data in parallel
    this.placeCollectionsApiService
      .getPlaceCollectionByIndex({ index })
      .pipe(
        tap((data) => {
          this.collection = data;
          this.generatePageMeta(data);
        }),
        switchMap((data) => {
          const placeIds = uniq(data.placeIds);

          // Fetch related data in parallel using forkJoin
          return forkJoin({
            places: this.placesApiService.getPlacesPreview({ ids: placeIds }),
            collections: this.placeCollectionsApiService
              .getPlaceCollections({ limit: 10 })
              .pipe(
                map((collections) =>
                  collections.filter((c) => c.index !== index),
                ),
              ),
          });
        }),
        catchError((err) => {
          console.error('Failed to update data', err);
          return of({ places: [], collections: [] }); // Graceful fallback
        }),
      )
      .subscribe(({ places, collections }) => {
        this.places = places;
        this.collections = collections;
        this.changeDetectorRef.detectChanges();
      });
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
}
