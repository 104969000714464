'use strict';

/**
 * Validators are functions which assert certain type.
 * They can return a string which can then be used
 * to display a helpful error message.
 * They can also return a function for a custom error message.
 */
var isPlainObject = require('is-plain-obj');
var xtend = require('xtend');
var DEFAULT_ERROR_PATH = 'value';
var NEWLINE_INDENT = '\n  ';
var v = {};

/**
 * Runners
 *
 * Take root validators and run assertion
 */
v.assert = function (rootValidator, options) {
  options = options || {};
  return function (value) {
    var message = validate(rootValidator, value);
    // all good
    if (!message) {
      return;
    }
    var errorMessage = processMessage(message, options);
    if (options.apiName) {
      errorMessage = options.apiName + ': ' + errorMessage;
    }
    throw new Error(errorMessage);
  };
};

/**
 * Higher Order Validators
 *
 * validators which take other validators as input
 * and output a new validator
 */
v.shape = function shape(validatorObj) {
  var validators = objectEntries(validatorObj);
  return function shapeValidator(value) {
    var validationResult = validate(v.plainObject, value);
    if (validationResult) {
      return validationResult;
    }
    var key, validator;
    var errorMessages = [];
    for (var i = 0; i < validators.length; i++) {
      key = validators[i].key;
      validator = validators[i].value;
      validationResult = validate(validator, value[key]);
      if (validationResult) {
        // return [key].concat(validationResult);
        errorMessages.push([key].concat(validationResult));
      }
    }
    if (errorMessages.length < 2) {
      return errorMessages[0];
    }

    // enumerate all the error messages
    return function (options) {
      errorMessages = errorMessages.map(function (message) {
        var key = message[0];
        var renderedMessage = processMessage(message, options).split('\n').join(NEWLINE_INDENT); // indents any inner nesting
        return '- ' + key + ': ' + renderedMessage;
      });
      var objectId = options.path.join('.');
      var ofPhrase = objectId === DEFAULT_ERROR_PATH ? '' : ' of ' + objectId;
      return 'The following properties' + ofPhrase + ' have invalid values:' + NEWLINE_INDENT + errorMessages.join(NEWLINE_INDENT);
    };
  };
};
v.strictShape = function strictShape(validatorObj) {
  var shapeValidator = v.shape(validatorObj);
  return function strictShapeValidator(value) {
    var shapeResult = shapeValidator(value);
    if (shapeResult) {
      return shapeResult;
    }
    var invalidKeys = Object.keys(value).reduce(function (memo, valueKey) {
      if (validatorObj[valueKey] === undefined) {
        memo.push(valueKey);
      }
      return memo;
    }, []);
    if (invalidKeys.length !== 0) {
      return function () {
        return 'The following keys are invalid: ' + invalidKeys.join(', ');
      };
    }
  };
};
v.arrayOf = function arrayOf(validator) {
  return createArrayValidator(validator);
};
v.tuple = function tuple() {
  var validators = Array.isArray(arguments[0]) ? arguments[0] : Array.prototype.slice.call(arguments);
  return createArrayValidator(validators);
};

// Currently array validation fails when the first invalid item is found.
function createArrayValidator(validators) {
  var validatingTuple = Array.isArray(validators);
  var getValidator = function (index) {
    if (validatingTuple) {
      return validators[index];
    }
    return validators;
  };
  return function arrayValidator(value) {
    var validationResult = validate(v.plainArray, value);
    if (validationResult) {
      return validationResult;
    }
    if (validatingTuple && value.length !== validators.length) {
      return 'an array with ' + validators.length + ' items';
    }
    for (var i = 0; i < value.length; i++) {
      validationResult = validate(getValidator(i), value[i]);
      if (validationResult) {
        return [i].concat(validationResult);
      }
    }
  };
}
v.required = function required(validator) {
  function requiredValidator(value) {
    if (value == null) {
      return function (options) {
        return formatErrorMessage(options, isArrayCulprit(options.path) ? 'cannot be undefined/null.' : 'is required.');
      };
    }
    return validator.apply(this, arguments);
  }
  requiredValidator.__required = true;
  return requiredValidator;
};
v.oneOfType = function oneOfType() {
  var validators = Array.isArray(arguments[0]) ? arguments[0] : Array.prototype.slice.call(arguments);
  return function oneOfTypeValidator(value) {
    var messages = validators.map(function (validator) {
      return validate(validator, value);
    }).filter(Boolean);

    // If we don't have as many messages as no. of validators,
    // then at least one validator was ok with the value.
    if (messages.length !== validators.length) {
      return;
    }

    // check primitive type
    if (messages.every(function (message) {
      return message.length === 1 && typeof message[0] === 'string';
    })) {
      return orList(messages.map(function (m) {
        return m[0];
      }));
    }

    // Complex oneOfTypes like
    // `v.oneOftypes(v.shape({name: v.string})`, `v.shape({name: v.number}))`
    // are complex ¯\_(ツ)_/¯. For the current scope only returning the longest message.
    return messages.reduce(function (max, arr) {
      return arr.length > max.length ? arr : max;
    });
  };
};

/**
 * Meta Validators
 * which take options as argument (not validators)
 * and return a new primitive validator
 */
v.equal = function equal(compareWith) {
  return function equalValidator(value) {
    if (value !== compareWith) {
      return JSON.stringify(compareWith);
    }
  };
};
v.oneOf = function oneOf() {
  var options = Array.isArray(arguments[0]) ? arguments[0] : Array.prototype.slice.call(arguments);
  var validators = options.map(function (value) {
    return v.equal(value);
  });
  return v.oneOfType.apply(this, validators);
};
v.range = function range(compareWith) {
  var min = compareWith[0];
  var max = compareWith[1];
  return function rangeValidator(value) {
    var validationResult = validate(v.number, value);
    if (validationResult || value < min || value > max) {
      return 'number between ' + min + ' & ' + max + ' (inclusive)';
    }
  };
};

/**
 * Primitive validators
 *
 * simple validators which return a string or undefined
 */
v.any = function any() {
  return;
};
v.boolean = function boolean(value) {
  if (typeof value !== 'boolean') {
    return 'boolean';
  }
};
v.number = function number(value) {
  if (typeof value !== 'number') {
    return 'number';
  }
};
v.plainArray = function plainArray(value) {
  if (!Array.isArray(value)) {
    return 'array';
  }
};
v.plainObject = function plainObject(value) {
  if (!isPlainObject(value)) {
    return 'object';
  }
};
v.string = function string(value) {
  if (typeof value !== 'string') {
    return 'string';
  }
};
v.func = function func(value) {
  if (typeof value !== 'function') {
    return 'function';
  }
};
function validate(validator, value) {
  // assertions are optional by default unless wrapped in v.require
  if (value == null && !validator.hasOwnProperty('__required')) {
    return;
  }
  var result = validator(value);
  if (result) {
    return Array.isArray(result) ? result : [result];
  }
}
function processMessage(message, options) {
  // message array follows the convention
  // [...path, result]
  // path is an array of object keys / array indices
  // result is output of the validator
  var len = message.length;
  var result = message[len - 1];
  var path = message.slice(0, len - 1);
  if (path.length === 0) {
    path = [DEFAULT_ERROR_PATH];
  }
  options = xtend(options, {
    path: path
  });
  return typeof result === 'function' ? result(options) // allows customization of result
  : formatErrorMessage(options, prettifyResult(result));
}
function orList(list) {
  if (list.length < 2) {
    return list[0];
  }
  if (list.length === 2) {
    return list.join(' or ');
  }
  return list.slice(0, -1).join(', ') + ', or ' + list.slice(-1);
}
function prettifyResult(result) {
  return 'must be ' + addArticle(result) + '.';
}
function addArticle(nounPhrase) {
  if (/^an? /.test(nounPhrase)) {
    return nounPhrase;
  }
  if (/^[aeiou]/i.test(nounPhrase)) {
    return 'an ' + nounPhrase;
  }
  if (/^[a-z]/i.test(nounPhrase)) {
    return 'a ' + nounPhrase;
  }
  return nounPhrase;
}
function formatErrorMessage(options, prettyResult) {
  var arrayCulprit = isArrayCulprit(options.path);
  var output = options.path.join('.') + ' ' + prettyResult;
  var prepend = arrayCulprit ? 'Item at position ' : '';
  return prepend + output;
}
function isArrayCulprit(path) {
  return typeof path[path.length - 1] == 'number' || typeof path[0] == 'number';
}
function objectEntries(obj) {
  return Object.keys(obj || {}).map(function (key) {
    return {
      key: key,
      value: obj[key]
    };
  });
}
v.validate = validate;
v.processMessage = processMessage;
module.exports = v;