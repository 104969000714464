import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isString } from 'lodash';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class ApiHelper {
  public isBrowser: boolean;
  public isServer: boolean;
  public isMobile: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    if (this.isBrowser) {
      this.isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset);
      this.breakpointObserver
        .observe([Breakpoints.Handset])
        .subscribe((result) => {
          this.isMobile = result.matches;
        });
    }
  }

  getErrorMessage(e: unknown) {
    const err = e as HttpErrorResponse;
    try {
      if (err.error) {
        let errBody = err.error;
        if (isString(errBody)) {
          errBody = JSON.parse(errBody);
        }
        return errBody?.fullMessage || err.message;
      }
    } catch (e) {
      return err.message;
    }

    return err.message;
  }
}
