<app-google-rating
  *ngIf="highestRating && highestRating.source === 'google'"
  [small]="small"
  [textOnly]="textOnly"
  [data]="highestRating.data">
</app-google-rating>

<app-tripadvisor-rating
  *ngIf="highestRating && highestRating.source === 'tripadvisor'"
  [small]="small"
  [textOnly]="textOnly"
  [data]="highestRating.data">
</app-tripadvisor-rating>
