import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { compact, uniq } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import {
  ActivitiesApiService,
  ActivityPreviewDto,
  PlacePreviewDto,
  PlacesApiService,
  RestaurantPreviewDto,
  RestaurantsApiService,
  RoutePreviewDto,
  RoutesApiService,
  SearchApiService,
} from '../core/api/generated/abuduba-api';
import { ApiHelper } from '../core/api/api.helper';
import { AutocompleteInputComponent } from '../core/autocomplete-input/autocomplete-input.component';
import { Title } from '@angular/platform-browser';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-search-page',
  styleUrls: ['search-page.component.scss'],
  templateUrl: 'search-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPageComponent implements OnInit, AfterViewInit {
  public places: PlacePreviewDto[] = [];
  public routes: RoutePreviewDto[] = [];
  public restaurants: RestaurantPreviewDto[] = [];
  public activities: ActivityPreviewDto[] = [];

  public input: string;
  public limit = 30;

  @ViewChild(AutocompleteInputComponent)
  private autocompleteComponent: AutocompleteInputComponent;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly restaurantsApiService: RestaurantsApiService,
    private readonly activitiesApiService: ActivitiesApiService,
    private readonly searchApiService: SearchApiService,
    private route: ActivatedRoute,
    private apiHelper: ApiHelper,
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    titleService.setTitle('Abuduba - Search');
  }

  ngOnInit() {
    if (this.apiHelper.isBrowser) {
      this.route.queryParamMap.subscribe((params) => {
        this.input = params.get('input') || '';

        if (this.input.length >= 1) {
          this.updateData();
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.input) {
      this.autocompleteComponent.setInitialValue(this.input);
    }
  }

  public updateData(): void {
    this.searchApiService
      .search({
        input: this.input,
        limit: this.limit,
      })
      .pipe(
        switchMap((searchResults) => {
          // Extract unique indexes for places, restaurants, routes, and activities
          const places = uniq(
            compact(searchResults.places.map((i) => i.index)),
          );
          const restaurants = uniq(
            compact(searchResults.restaurants.map((i) => i.index)),
          );
          const routes = uniq(
            compact(searchResults.routes.map((i) => i.index)),
          );
          const activities = uniq(
            compact(searchResults.activities.map((i) => i.index)),
          );

          // Prepare API calls for data previews
          return forkJoin({
            places: places.length
              ? this.placesApiService
                  .getPlacesPreview({ indexes: places, limit: this.limit })
                  .pipe(catchError(() => of([]))) // Handle error gracefully
              : of([]),
            restaurants: restaurants.length
              ? this.restaurantsApiService
                  .getRestaurantsPreview({
                    indexes: restaurants,
                    limit: this.limit,
                  })
                  .pipe(catchError(() => of([])))
              : of([]),
            routes: routes.length
              ? this.routesApiService
                  .getRoutesPreview({ indexes: routes, limit: this.limit })
                  .pipe(catchError(() => of([])))
              : of([]),
            activities: activities.length
              ? this.activitiesApiService
                  .searchActivities({
                    productCodes: activities,
                    limit: this.limit,
                  })
                  .pipe(
                    map((data) => data.items),
                    catchError(() => of([])),
                  )
              : of([]),
          });
        }),
      )
      .subscribe({
        next: ({ places, restaurants, routes, activities }) => {
          // Update component state
          this.places = places;
          this.restaurants = restaurants;
          this.routes = routes;
          this.activities = activities;

          // Trigger change detection once
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          console.error('Error updating data:', err);
        },
      });
  }
}
