import { ArticlePreviewDto } from '../core/api/generated/abuduba-api';
import { articleCategories } from './articles.types';

export function getCategoryName(
  category: ArticlePreviewDto['category'],
): string {
  return articleCategories[category]?.name || '';
}

export function getCategoryIcon(
  category: ArticlePreviewDto['category'],
): string {
  return articleCategories[category]?.icon || '';
}
