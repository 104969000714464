/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TripAdvisorReviewsListDto } from '../../models/trip-advisor-reviews-list-dto';

export interface GetTripadvisorReviews$Params {
  locationId: number;
}

export function getTripadvisorReviews(
  http: HttpClient,
  rootUrl: string,
  params: GetTripadvisorReviews$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<TripAdvisorReviewsListDto>> {
  const rb = new RequestBuilder(rootUrl, getTripadvisorReviews.PATH, 'get');
  if (params) {
    rb.query('locationId', params.locationId, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripAdvisorReviewsListDto>;
      }),
    );
}

getTripadvisorReviews.PATH = '/api/v1/reviews/tripadvisor';
