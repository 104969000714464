/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivitiesMaxCostDto } from '../models/activities-max-cost-dto';
import { ActivitiesPaginatedResponseDto } from '../models/activities-paginated-response-dto';
import { ActivityAvailabilityDto } from '../models/activity-availability-dto';
import { ActivityDto } from '../models/activity-dto';
import { ActivityPreviewDto } from '../models/activity-preview-dto';
import { getActivityAvailability } from '../fn/activities/get-activity-availability';
import { GetActivityAvailability$Params } from '../fn/activities/get-activity-availability';
import { getActivityByCode } from '../fn/activities/get-activity-by-code';
import { GetActivityByCode$Params } from '../fn/activities/get-activity-by-code';
import { getMaxActivitiesCost } from '../fn/activities/get-max-activities-cost';
import { GetMaxActivitiesCost$Params } from '../fn/activities/get-max-activities-cost';
import { getSimilarActivities } from '../fn/activities/get-similar-activities';
import { GetSimilarActivities$Params } from '../fn/activities/get-similar-activities';
import { searchActivities } from '../fn/activities/search-activities';
import { SearchActivities$Params } from '../fn/activities/search-activities';
import { searchByPlace } from '../fn/activities/search-by-place';
import { SearchByPlace$Params } from '../fn/activities/search-by-place';

@Injectable({ providedIn: 'root' })
export class ActivitiesApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchByPlace()` */
  static readonly SearchByPlacePath = '/api/v1/activities/search/place';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchByPlace()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchByPlace$Response(
    params: SearchByPlace$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ActivitiesPaginatedResponseDto>> {
    return searchByPlace(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchByPlace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchByPlace(
    params: SearchByPlace$Params,
    context?: HttpContext,
  ): Observable<ActivitiesPaginatedResponseDto> {
    return this.searchByPlace$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<ActivitiesPaginatedResponseDto>,
        ): ActivitiesPaginatedResponseDto => r.body,
      ),
    );
  }

  /** Path part for operation `getActivityByCode()` */
  static readonly GetActivityByCodePath = '/api/v1/activities/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityByCode$Response(
    params: GetActivityByCode$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ActivityDto>> {
    return getActivityByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivityByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityByCode(
    params: GetActivityByCode$Params,
    context?: HttpContext,
  ): Observable<ActivityDto> {
    return this.getActivityByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDto>): ActivityDto => r.body),
    );
  }

  /** Path part for operation `getActivityAvailability()` */
  static readonly GetActivityAvailabilityPath =
    '/api/v1/activities/availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityAvailability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getActivityAvailability$Response(
    params: GetActivityAvailability$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ActivityAvailabilityDto>>> {
    return getActivityAvailability(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivityAvailability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getActivityAvailability(
    params: GetActivityAvailability$Params,
    context?: HttpContext,
  ): Observable<Array<ActivityAvailabilityDto>> {
    return this.getActivityAvailability$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<ActivityAvailabilityDto>>,
        ): Array<ActivityAvailabilityDto> => r.body,
      ),
    );
  }

  /** Path part for operation `getMaxActivitiesCost()` */
  static readonly GetMaxActivitiesCostPath = '/api/v1/activities/maxCost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaxActivitiesCost()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaxActivitiesCost$Response(
    params?: GetMaxActivitiesCost$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ActivitiesMaxCostDto>> {
    return getMaxActivitiesCost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaxActivitiesCost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaxActivitiesCost(
    params?: GetMaxActivitiesCost$Params,
    context?: HttpContext,
  ): Observable<ActivitiesMaxCostDto> {
    return this.getMaxActivitiesCost$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<ActivitiesMaxCostDto>): ActivitiesMaxCostDto =>
          r.body,
      ),
    );
  }

  /** Path part for operation `searchActivities()` */
  static readonly SearchActivitiesPath = '/api/v1/activities/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchActivities$Response(
    params?: SearchActivities$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ActivitiesPaginatedResponseDto>> {
    return searchActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchActivities(
    params?: SearchActivities$Params,
    context?: HttpContext,
  ): Observable<ActivitiesPaginatedResponseDto> {
    return this.searchActivities$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<ActivitiesPaginatedResponseDto>,
        ): ActivitiesPaginatedResponseDto => r.body,
      ),
    );
  }

  /** Path part for operation `getSimilarActivities()` */
  static readonly GetSimilarActivitiesPath = '/api/v1/activities/similar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimilarActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimilarActivities$Response(
    params: GetSimilarActivities$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ActivityPreviewDto>>> {
    return getSimilarActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimilarActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimilarActivities(
    params: GetSimilarActivities$Params,
    context?: HttpContext,
  ): Observable<Array<ActivityPreviewDto>> {
    return this.getSimilarActivities$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<ActivityPreviewDto>>,
        ): Array<ActivityPreviewDto> => r.body,
      ),
    );
  }
}
