import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import dayjs from 'dayjs';
import { placeActivities } from './places/places.types';
import { CurrencyManager } from './core/currency-manager';
import { ApiHelper } from './core/api/api.helper';
import { CreateSuggestionDtoEntityTypeEnum } from './core/api/generated/abuduba-api/shared-enums';
import { AuthService } from './core/auth/auth.service';
import { UserDto } from './core/api/generated/abuduba-api';
import { AuthPopupComponent } from './core/auth/auth-popup/auth-popup.component';
import { GoogleAuthService } from './core/auth/google-auth.service';
import { EventBus } from './core/event-bus';

dayjs.locale('en');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class AppComponent implements OnInit {
  @ViewChild(AuthPopupComponent)
  private authPopup: AuthPopupComponent;

  public menu = [
    {
      name: 'Places',
      routerLink: '/places',
      queryParams: {},
    },
    {
      name: 'Activities',
      routerLink: '/activities',
      queryParams: {},
    },
    {
      name: 'Restaurants',
      routerLink: '/restaurants',
      queryParams: {},
    },
    {
      name: 'Routes',
      routerLink: '/routes',
      queryParams: {},
    },
    {
      name: 'Collections',
      routerLink: '/collections',
      queryParams: {},
    },
    {
      name: 'Articles',
      routerLink: '/articles',
      queryParams: {},
    },
    // {
    //   name: 'Photo spots',
    //   routerLink: '/places',
    //   queryParams: {
    //     activities: ['Photo'],
    //   },
    // },
    // {
    //   name: 'Iconic buildings',
    //   routerLink: '/places',
    //   queryParams: {
    //     activities: ['IconicBuilding'],
    //   },
    // },
    // {
    //   name: 'City tours',
    //   routerLink: '/routes',
    //   queryParams: {
    //     types: ['CityTour'],
    //   },
    // },
    // {
    //   name: 'Road trips',
    //   routerLink: '/routes',
    //   queryParams: {
    //     types: ['RoadTrip'],
    //   },
    // },
    // {
    //   name: 'Hiking',
    //   routerLink: '/routes',
    //   queryParams: {
    //     types: ['Hiking'],
    //   },
    // },
  ];

  public menuNavBar = [
    {
      name: 'Main',
      routerLink: '/',
      queryParams: {},
    },
    ...this.menu,
    {
      name: 'Regions',
      routerLink: '/regions',
      queryParams: {},
    },
  ];

  protected readonly placeActivities = Object.entries(placeActivities);

  public isSearchOpen = false;
  public isMenuOpen = false;
  public profile?: UserDto | null;
  public isProfileMenuVisible = false;

  constructor(
    public currencyManager: CurrencyManager,
    public apiHelper: ApiHelper,
    public authService: AuthService,
    private ref: ChangeDetectorRef,
    public googleAuthService: GoogleAuthService,
    public eventBus: EventBus,
  ) {}

  openAuthPopup() {
    this.authPopup.open();
  }

  public logout() {
    this.googleAuthService.logout();
    this.authService.clearTokens();
  }

  ngOnInit() {
    if (this.apiHelper.isBrowser) {
      this.initProfile();
    }

    this.eventBus.on('open-auth-popup').subscribe(() => {
      this.openAuthPopup();
    });
  }

  public initProfile() {
    //await this.authService.fetchGoogleToken();

    const isAuth = this.authService.isAuth();

    if (!isAuth) {
      this.profile = null;
      this.ref.detectChanges();
    } else {
      this.authService.getUser().subscribe((user) => {
        this.profile = user;
        this.ref.detectChanges();
      });
    }
  }

  public closeMenu(e: MouseEvent) {
    const barBody = document.querySelector('#nav_bar .bar-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isMenuOpen = false;
    }
  }

  protected readonly CreateSuggestionDtoEntityTypeEnum =
    CreateSuggestionDtoEntityTypeEnum;
}
