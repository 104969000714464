'use strict';

var MapiClient = require('../../lib/classes/mapi-client');
// This will create the environment-appropriate client.
var createClient = require('../../lib/client');
function createServiceFactory(ServicePrototype) {
  return function (clientOrConfig) {
    var client;
    if (MapiClient.prototype.isPrototypeOf(clientOrConfig)) {
      client = clientOrConfig;
    } else {
      client = createClient(clientOrConfig);
    }
    var service = Object.create(ServicePrototype);
    service.client = client;
    return service;
  };
}
module.exports = createServiceFactory;