import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ApiHelper } from '../api/api.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private apiHelper: ApiHelper,
  ) {}

  canActivate(): boolean {
    if (this.apiHelper.isBrowser) {
      const isLoggedIn = this.authService.isAuth();

      if (!isLoggedIn) {
        this.router.navigate(['/error']);
        return false;
      }
      return true;
    }

    return false;
  }
}
