import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FavouriteListDto } from '../../core/api/generated/abuduba-api';
import { getMainPictureUrl } from '../../places/places.utils';
import { FavouritesService } from '../../core/favourites.service';
import { compact } from 'lodash';

@Component({
  selector: 'app-favourite-list-preview',
  styleUrls: ['favourite-list-preview.component.scss'],
  templateUrl: 'favourite-list-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouriteListPreviewComponent {
  @Input()
  public list: FavouriteListDto & Partial<{ checked: boolean }>;

  @Output()
  public deleted: EventEmitter<number> = new EventEmitter();

  @Input()
  public onClick?: (event: Event, list: FavouriteListDto) => void;

  public isDeletedPopupOpened = false;
  public error: string;

  protected readonly getMainPictureUrl = getMainPictureUrl;

  constructor(
    private favouritesService: FavouritesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  clickWrapper(e: Event, list: FavouriteListDto) {
    if (this.onClick) {
      this.onClick(e, list);
      e.preventDefault();
    }
  }

  delete() {
    this.favouritesService.removeList(this.list.id).subscribe({
      next: () => {
        this.deleted.emit(this.list.id);
      },
      error: (err) => {
        this.error =
          'An error occurred while deleting list. Please try again later. If the problem persists, please contact us. Thank you.';
        console.error(err);
      },
    });
  }

  open(e: Event) {
    this.isDeletedPopupOpened = true;
    this.changeDetectorRef.detectChanges();
    e.preventDefault();
    e.stopPropagation();
  }

  close() {
    this.isDeletedPopupOpened = false;
    this.changeDetectorRef.detectChanges();
  }

  public getListPhotos(): string[] {
    const images = compact(
      this.list.pictures.map((f) => f.thumbSizeUrl || f.smallSizeUrl),
    ).slice(0, 4);

    if (images.length < 4) {
      for (let i = images.length; i <= 4; i++) {
        images.push('/assets/stub.jpg');
      }
    }

    return images;
  }
}
