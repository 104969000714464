import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import { ApiHelper } from '../core/api/api.helper';
import { AuthApiService } from '../core/api/generated/abuduba-api';
import { GoogleAuthService } from '../core/auth/google-auth.service';

@Component({
  selector: 'app-auth',
  styleUrls: ['auth.component.scss'],
  templateUrl: 'auth.component.html',
})
export class AuthComponent implements OnInit {
  public provider: string;
  public error?: string;

  constructor(
    private route: ActivatedRoute,
    private readonly authService: AuthService,
    public googleAuthService: GoogleAuthService,
    private readonly authApi: AuthApiService,
    private apiHelper: ApiHelper,
    private ref: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    const params = this.route.snapshot.paramMap;
    this.provider = String(params.get('provider'));

    if (!this.provider) {
      this.setError('Invalid provider');
      this.ref.detectChanges();
    }

    if (this.apiHelper.isBrowser) {
      if (this.provider === 'google') {
        await this.googleAuth();
      }
    }
  }

  public setError(message: string) {
    this.error = message;
    this.ref.detectChanges();
  }

  public async googleAuth() {
    const hasValidToken = await this.googleAuthService.fetchToken();

    if (hasValidToken) {
      const state = this.googleAuthService.getState();
      const idToken = this.googleAuthService.getIdToken();

      this.authApi
        .authGoogleUser({
          idToken,
        })
        .subscribe({
          next: (data) => {
            this.authService.storeTokens(data.accessToken, data.refreshToken);
            this.authService.storeUser(data.user);
            window.location.href = String(state);
          },
          error: (err) => {
            this.setError(err.error.message);
          },
        });
    } else {
      this.setError('Invalid token');
    }
  }
}
