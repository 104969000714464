<div class="app-gallery" *ngIf="files.length > 0">
  <div class="main-image" (click)="openImage(0)">
    <img [ngSrc]="mainImage.mediumUrl" fill alt="Main image" />
  </div>

  <div class="side-images">
    <div
      class="side-image-block"
      *ngFor="let img of sideImages; index as i; trackBy: imageTrackBy"
      (click)="openImage(+i + 1)">
      <img [ngSrc]="img.thumbUrl" fill alt="Side image" />
    </div>
  </div>

  <div class="show-all" (click)="openImage(0)">
    Show all {{ files.length }} photos
  </div>
</div>
