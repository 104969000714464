<div class="app-weather">
  <div class="current">
    <div class="value">
      <div class="real">{{ weather.current.temp.toFixed(0) }}°C</div>
      <div class="apparent">
        feels like {{ weather.current.apparentTemp.toFixed(0) }}°C
      </div>
    </div>

    <div class="additional">
      <div class="line">
        <div class="humidity" title="humidity">
          <ng-icon name="matWaterDropOutline"></ng-icon>
          <span>{{ weather.current.humidity }}%</span>
        </div>
        <div class="wind" title="wind">
          <ng-icon name="matAirOutline"></ng-icon>
          <span>{{ weather.current.wind.toFixed(0) }}m/s</span>
        </div>
      </div>
      <div class="line">
        <div class="precipitation" title="precipitation">
          <ng-icon name="matThunderstormOutline"></ng-icon>
          <span>{{ weather.current.precipitation }}mm</span>
        </div>
        <div class="visibility" title="visibility">
          <ng-icon name="matVisibilityOutline"></ng-icon>
          <span>{{ (weather.current.visibility / 1000).toFixed(1) }}km</span>
        </div>
      </div>
    </div>
  </div>

  <div class="hourly">
    <div class="h-weather" *ngFor="let w of hourlyWeather">
      <div class="value">{{ w.temp.toFixed(0) }}°C</div>

      <div class="time">{{ w.time | date: 'shortTime' }}</div>
    </div>
  </div>
</div>
