<div class="suggestion-component">
  <div
    class="suggest-button"
    (click)="isVisible = !isVisible"
    *ngIf="!hideButton">
    <ng-icon name="bootstrapChatDotsFill"></ng-icon>
    <span>Leave suggestion</span>
  </div>

  <div class="suggestion-popup-content" *ngIf="isVisible">
    <div class="suggestion-popup">
      <div class="header">
        <div class="title">{{ title }}</div>
        <div class="actions">
          <div class="close" (click)="isVisible = false">
            <ng-icon name="matCloseOutline"></ng-icon>
          </div>
        </div>
      </div>

      <div class="body">
        <div class="prompt">
          Notice something off or have an idea to improve this content? We’d
          love to hear from you! Please leave your suggestion below to help us
          make this site better.
        </div>

        <label for="suggestion_body">Suggestion text</label>
        <textarea id="suggestion_body" [(ngModel)]="bodyValue"></textarea>

        <button
          [disabled]="isProgress || !bodyValue"
          *ngIf="!isSuccess"
          (click)="sendSuggestion(bodyValue)">
          Send
        </button>

        <button class="close-bth" *ngIf="isSuccess" (click)="isVisible = false">
          Close
        </button>

        <div class="success" *ngIf="isSuccess">
          Thanks for your feedback! We appreciate your input and will review
          your suggestion to improve our content.
        </div>

        <div class="error" *ngIf="isError">
          Oops! Something went wrong while sending your suggestion. Please try
          again later.
        </div>
      </div>
    </div>
  </div>
</div>
