<div class="restaurant-near-by">
  <a
    class="photo"
    routerLink="/restaurants/{{ restaurant.index }}"
    target="_blank">
    <img
      [ngSrc]="getMainPictureUrl([restaurant.preview], 'thumb')"
      fill
      alt="{{ restaurant.name }}" />
  </a>

  <div class="info">
    <div class="title-block">
      <a
        class="title-value"
        routerLink="/restaurants/{{ restaurant.index }}"
        target="_blank">
        <h4>{{ restaurant.name }}</h4>
      </a>
      <div class="distance" *ngIf="distance">
        <span class="dot"></span>
        <span class="value">{{ formatDistance(distance) }}</span>
      </div>
    </div>

    <div class="cuisines">
      {{ this.restaurant.cuisines.slice(0, 3).map(getCuisineName).join(', ') }}
    </div>

    <div class="rating-block">
      <div
        class="rating"
        *ngIf="restaurant.tripadvisorData || restaurant.googleData">
        <app-rating-selector
          [tripadvisor]="restaurant!.tripadvisorData"
          [small]="true"
          [google]="restaurant!.googleData"></app-rating-selector>
      </div>
    </div>

    <!--    <div class="awards" *ngIf="restaurant?.awards">-->
    <!--      <div-->
    <!--        class="award"-->
    <!--        *ngFor="let a of getRestaurantAwards()"-->
    <!--        [title]="getAwardName(a)">-->
    <!--        {{ getAwardName(a) }}-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
