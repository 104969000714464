/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivityDto } from '../../models/activity-dto';

export interface GetActivityByCode$Params {
  code: string;
}

export function getActivityByCode(
  http: HttpClient,
  rootUrl: string,
  params: GetActivityByCode$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ActivityDto>> {
  const rb = new RequestBuilder(rootUrl, getActivityByCode.PATH, 'get');
  if (params) {
    rb.path('code', params.code, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDto>;
      }),
    );
}

getActivityByCode.PATH = '/api/v1/activities/{code}';
