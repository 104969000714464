/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivityAvailabilityDto } from '../../models/activity-availability-dto';
import { GetActivityAvailabilityDto } from '../../models/get-activity-availability-dto';

export interface GetActivityAvailability$Params {
  body: GetActivityAvailabilityDto;
}

export function getActivityAvailability(
  http: HttpClient,
  rootUrl: string,
  params: GetActivityAvailability$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<ActivityAvailabilityDto>>> {
  const rb = new RequestBuilder(rootUrl, getActivityAvailability.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ActivityAvailabilityDto>>;
      }),
    );
}

getActivityAvailability.PATH = '/api/v1/activities/availability';
