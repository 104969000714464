<div class="place-preview" [class.horizontal]="horizontal">
  <a
    class="photo"
    routerLink="/places/{{ place.index }}"
    [target]="newTab ? '_blank' : '_self'">
    <img
      [ngSrc]="getMainPictureUrl([place.preview], 'small')"
      fill
      alt="{{ place.name }}" />

    <div class="closed" *ngIf="place.closed">
      <ng-icon name="bootstrapDashCircleFill"></ng-icon>
      {{ formatClosedType(place) }}
    </div>

    <div class="favourite" *ngIf="place.favourite">
      Top Picks
      <ng-icon name="matStarOutline"></ng-icon>
    </div>

    <app-favourite-popup
      [textMode]="false"
      [itemId]="place.id"
      [saved]="saved"
      [itemType]="
        CreateFavouriteItemDtoItemTypeEnum.Place
      "></app-favourite-popup>
  </a>

  <div class="info">
    <a
      class="title"
      routerLink="/places/{{ place.index }}"
      [target]="newTab ? '_blank' : '_self'">
      <h3>
        {{ place.name }}
      </h3>
    </a>

    <!--    <div class="rating-number" *ngIf="place.ra">-->
    <!--      <ng-icon name="matStarOutline"></ng-icon>-->
    <!--      <div class="value">{{ place.rating! }}</div>-->
    <!--    </div>-->

    <div class="activities" *ngIf="place.activities">
      <div
        class="activity"
        *ngFor="let a of getPlaceActivities()"
        [title]="getActivityName(a)">
        <ng-icon [name]="getActivityIcon(a)"></ng-icon>
        <span>{{ getActivityName(a) }}</span>
      </div>
    </div>

    <div class="short-info">
      <div class="closed" *ngIf="place.closed">
        <ng-icon name="bootstrapDashCircleFill"></ng-icon>
        <div>{{ formatClosedType(place) }}</div>
      </div>

      <div class="duration" *ngIf="place.suggestedVisitDuration">
        <ng-icon name="matHourglassTopOutline"></ng-icon>
        <div>{{ formatMinutes(place.suggestedVisitDuration) }}</div>
      </div>

      <div
        class="costs"
        *ngIf="
          place.viatorFromPriceAed || place.minPrice || place.isFreeOption
        ">
        <ng-icon name="matPaidOutline"></ng-icon>
        <div>
          {{
            place?.minPrice || place?.viatorFromPriceAed
              ? 'from ' +
                currencyManager.format(
                  place.minPrice || place.viatorFromPriceAed
                )
              : ''
          }}{{
            place.isFreeOption
              ? place?.minPrice || place?.viatorFromPriceAed
                ? ', free entry available'
                : 'free entry available'
              : ''
          }}
        </div>
      </div>
    </div>

    <div
      class="description"
      [innerHTML]="removeHtmlTags(place.description)"></div>

    <div class="rating">
      <app-rating-selector
        [tripadvisor]="place!.tripadvisorData"
        [small]="false"
        [textOnly]="!horizontal"
        [google]="place!.googleData"></app-rating-selector>

      <div
        class="facts"
        [title]="place.facts[0]"
        *ngIf="
          !place.tripadvisorData && !horizontal && place.facts && place.facts[0]
        ">
        {{ place.facts[0] }}
      </div>

      <div class="facts" *ngIf="horizontal && place.facts && place.facts[0]">
        {{ place.facts.slice(0, 3).join(', ') }}
      </div>
    </div>
  </div>
</div>
