<app-not-found *ngIf="place === null"></app-not-found>

<div id="place_card" class="content-wrap" *ngIf="place !== null">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="place-title-block">
    <div class="place-title">
      <app-loader
        *ngIf="!place"
        [animation]="false"
        width="300px"
        height="20px"></app-loader>

      <h1 class="value">{{ place?.name }}</h1>
    </div>

    <div class="place-activities" *ngIf="place?.activities">
      <div class="place-activity" *ngFor="let a of place?.activities!">
        <ng-icon [name]="getActivityIcon(a)"></ng-icon>
        <div class="name">{{ getActivityName(a) }}</div>
      </div>
    </div>
  </div>

  <div class="subtitle-block">
    <div
      class="place-tripadvisor-google-block"
      *ngIf="place?.tripadvisorData || place?.googleData">
      <app-rating-selector
        [tripadvisor]="place!.tripadvisorData"
        [google]="place!.googleData"></app-rating-selector>
    </div>

    <div class="actions" *ngIf="place">
      <app-favourite-popup
        [textMode]="true"
        [itemId]="place.id"
        [saved]="isSavedInFavourites"
        [itemType]="
          CreateFavouriteItemDtoItemTypeEnum.Place
        "></app-favourite-popup>

      <app-share></app-share>

      <app-suggestion
        title="Leave suggestion for {{ place.name }}"
        entityId="{{ place.id }}"
        [entityType]="CreateSuggestionDtoEntityTypeEnum.Place"></app-suggestion>
    </div>
  </div>

  <div class="place-media-block">
    <div class="loading-block" *ngIf="!place">
      <div class="left">
        <app-loader [animation]="false" width="100%" height="100%"></app-loader>
      </div>

      <div class="right">
        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>

        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>
      </div>
    </div>

    <div class="favourite" *ngIf="place?.favourite">
      Top Picks
      <ng-icon name="matStarOutline"></ng-icon>
    </div>

    <app-gallery *ngIf="media && place" [files]="media"></app-gallery>
  </div>

  <div class="place-content">
    <div class="facts-block" *ngIf="facts.length > 0">
      <div class="fact" *ngFor="let f of getFacts()">
        <ng-icon name="matStarOutline"></ng-icon>

        <div class="value">{{ f }}</div>
      </div>
    </div>

    <div class="main-info-block">
      <div class="left">
        <div class="closed-block" *ngIf="place && place.closed">
          <ng-icon name="bootstrapDashCircleFill"></ng-icon>
          <div>{{ formatClosedType(place) }}</div>
          <div class="reason" *ngIf="place.closedReason">
            ({{ place.closedReason }})
          </div>
        </div>

        <div
          class="description-block"
          *ngIf="place"
          [class.open]="isDescriptionOpen">
          <div class="html-content" [innerHTML]="place.description"></div>

          <div class="show-all-button" (click)="isDescriptionOpen = true">
            Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
          </div>
        </div>

        <div class="tips-block" *ngIf="place && place.tips">
          <h3 class="title">Useful tips</h3>

          <div class="html-content" [innerHTML]="place.tips"></div>
        </div>

        <div
          class="history-block"
          *ngIf="place && place.history"
          [class.open]="isHistoryExpand">
          <h3 class="title">History</h3>

          <div class="html-content" [innerHTML]="place.history"></div>

          <div class="show-all-button" (click)="isHistoryExpand = true">
            Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
          </div>
        </div>
      </div>

      <div class="widgets">
        <div class="widget shadow-block general-info" *ngIf="place">
          <div class="general-info-item time-item">
            <ng-icon name="matScheduleOutline"></ng-icon>
            <div class="item-value">
              <div class="open-status">
                {{
                  isOpenNow(getCurrentWorkingTime(place.workingTime), place.tz)
                    ? 'Open now: '
                    : 'Closed: '
                }}
              </div>

              <div class="current-time">
                {{
                  formatWorkingTime(getCurrentWorkingTime(place.workingTime))
                }}
              </div>

              <div class="expand-block">
                <ng-icon
                  class="expand"
                  (click)="isScheduleExpand = !isScheduleExpand"
                  [name]="
                    isScheduleExpand
                      ? 'matExpandLessOutline'
                      : 'matExpandMoreOutline'
                  "></ng-icon>
              </div>
            </div>
          </div>

          <div
            class="general-info-item full-schedule-item"
            *ngIf="isScheduleExpand">
            <div class="schedule" *ngFor="let time of placeWorkingTime">
              <div class="day">{{ time.dayName }}:</div>
              <div class="time">{{ formatWorkingTime(time.hours) }}</div>
            </div>
          </div>

          <div class="general-info-item site-item" *ngIf="place?.site">
            <ng-icon name="matLanguageOutline"></ng-icon>
            <a class="item-value" [href]="place.site" target="_blank">
              {{ place.site }}
            </a>
          </div>

          <div class="general-info-item phone-item" *ngIf="place?.phone">
            <ng-icon name="matCallOutline"></ng-icon>
            <div class="item-value">
              {{ place.phone }}
            </div>
          </div>

          <div
            class="general-info-item costs-item"
            *ngIf="
              place?.viatorFromPriceAed || place?.minPrice || place.isFreeOption
            ">
            <ng-icon name="matPaidOutline"></ng-icon>
            <div class="item-value">
              {{
                place?.minPrice || place?.viatorFromPriceAed
                  ? 'from ' +
                    currencyManager.format(
                      place?.minPrice || place?.viatorFromPriceAed
                    )
                  : ''
              }}{{
                place?.isFreeOption
                  ? place?.minPrice || place?.viatorFromPriceAed
                    ? ', free entry available'
                    : 'free entry available'
                  : ''
              }}
            </div>
          </div>

          <div
            class="general-info-item duration-item"
            *ngIf="place?.suggestedVisitDuration">
            <ng-icon name="matHourglassTopOutline"></ng-icon>
            <div class="item-value">
              We suggest you spend at least
              <b>{{ formatMinutes(place.suggestedVisitDuration!) }}</b> on it
            </div>
          </div>
        </div>

        <div class="widget shadow-block ai-widget" *ngIf="place">
          <div class="prompt">
            Have any questions about <b>{{ place.name }}</b
            >?
          </div>
          <div class="ai-button" (click)="openAiChat()">
            <ng-icon name="ionSparklesSharp"></ng-icon>
            Speak with AI
          </div>

          <app-ai-chat
            title="Chat with AI: {{ place.name }}"
            [placeId]="place.id"
            firstMessage="Hey there! I’m here to help you with any questions about {{
              place.name
            }}"
            [isClosed]="true"></app-ai-chat>
        </div>

        <div class="widget shadow-block weather" *ngIf="weather">
          <app-weather [weather]="weather"></app-weather>
        </div>

        <div class="widget" *ngIf="!place">
          <app-loader
            [animation]="false"
            width="100%"
            height="150px"></app-loader>
        </div>
      </div>
    </div>

    <div class="location-block">
      <div class="widget shadow-block map" *ngIf="place">
        <app-places-map
          #placesMap
          [center]="{
            lng: place.location.x,
            lat: place.location.y
          }"
          [googleMapLink]="place.googleData?.googleMapsUri"></app-places-map>
      </div>

      <div
        class="widget shadow-block nearest-places"
        *ngIf="nearestPlaces && nearestPlaces.length > 0">
        <div class="title">Nearby Finds:</div>

        <app-place-near-by
          *ngFor="let p of nearestPlaces"
          [place]="p.place"
          [distance]="p.distance"
          (mouseenter)="highlightItemOnMap(p.place.index, true)"
          (focus)="highlightItemOnMap(p.place.index, true)"
          (mouseleave)="highlightItemOnMap(p.place.index, false)"
          (blur)="highlightItemOnMap(p.place.index, false)"></app-place-near-by>
      </div>
    </div>
  </div>

  <div class="activities-block">
    <app-entities-preview-list
      blockTitle="Activities"
      routerLinkValue="/activities"
      [nowrap]="true"
      subtitle="Discover Exciting Activities for Every Passion"
      [activities]="activities"></app-entities-preview-list>
  </div>

  <div class="similar-places">
    <app-entities-preview-list
      blockTitle="Similar places"
      [nowrap]="true"
      [places]="similarPlaces"></app-entities-preview-list>
  </div>

  <div class="connected-routes">
    <app-entities-preview-list
      blockTitle="Connected routes"
      [nowrap]="true"
      [routes]="connectedRoutes"></app-entities-preview-list>
  </div>

  <app-tripadvisor-reviews
    [tripadvisorLocationId]="place!.tripadvisorLocationId!"
    *ngIf="place?.tripadvisorLocationId"></app-tripadvisor-reviews>
</div>
