import { forwardRef, NgModule, Provider } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AbudubaApiModule } from './generated/abuduba-api';
import { ApiHelper } from './api.helper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../auth/auth-interceptor';

const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => AuthInterceptor),
  multi: true,
};

@NgModule({
  imports: [AbudubaApiModule.forRoot({ rootUrl: environment.apiUrl })],
  providers: [API_INTERCEPTOR_PROVIDER, AuthInterceptor, ApiHelper],
  exports: [],
})
export class ApiModule {}
