<div class="favourite-list-preview" [class.checked]="list.checked">
  <a
    class="photo"
    [routerLink]="!onClick ? '/favourites/list/' + list.id : null"
    (click)="clickWrapper($event, list)">
    <div class="img" *ngFor="let item of getListPhotos()">
      <img [ngSrc]="item" [alt]="list.title" fill />
    </div>

    <div class="delete" (click)="open($event)" title="Delete list">
      <ng-icon name="matCloseOutline"></ng-icon>
    </div>

    <div class="check">
      <ng-icon name="matCheck"></ng-icon>
    </div>
  </a>

  <div class="info">
    <a
      class="title"
      [routerLink]="!onClick ? '/favourites/list/' + list.id : null"
      (click)="clickWrapper($event, list)">
      <h3>
        {{ list.title }}
      </h3>
    </a>

    <div class="amount">{{ list.itemsCount }} items</div>
  </div>

  <div
    class="delete-popup-content"
    *ngIf="isDeletedPopupOpened"
    (click)="close()">
    <div
      class="delete-popup"
      (click)="$event.stopPropagation(); $event.preventDefault()">
      <div class="header">
        <div class="actions">
          <div class="close" (click)="close()">
            <ng-icon name="matCloseOutline"></ng-icon>
          </div>
        </div>
      </div>

      <div class="body">
        <div class="title">Delete this list?</div>
        <div class="list-title">{{ list.title }}</div>

        <div class="error" *ngIf="error">{{ error }}</div>

        <div class="buttons">
          <div class="cancel" (click)="close()">Cancel</div>
          <div class="create" (click)="delete()">Delete</div>
        </div>
      </div>
    </div>
  </div>
</div>
