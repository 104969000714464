/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPlaceCollectionByIndex } from '../fn/place-collections/get-place-collection-by-index';
import { GetPlaceCollectionByIndex$Params } from '../fn/place-collections/get-place-collection-by-index';
import { getPlaceCollections } from '../fn/place-collections/get-place-collections';
import { GetPlaceCollections$Params } from '../fn/place-collections/get-place-collections';
import { getPlaceCollectionsPreview } from '../fn/place-collections/get-place-collections-preview';
import { GetPlaceCollectionsPreview$Params } from '../fn/place-collections/get-place-collections-preview';
import { PlaceCollectionDto } from '../models/place-collection-dto';
import { PlaceCollectionPreviewDto } from '../models/place-collection-preview-dto';
import { PlaceCollectionsPreviewPaginatedResponseDto } from '../models/place-collections-preview-paginated-response-dto';
import { searchPlaceCollectionsPreview } from '../fn/place-collections/search-place-collections-preview';
import { SearchPlaceCollectionsPreview$Params } from '../fn/place-collections/search-place-collections-preview';

@Injectable({ providedIn: 'root' })
export class PlaceCollectionsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlaceCollections()` */
  static readonly GetPlaceCollectionsPath = '/api/v1/places/collections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceCollections()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollections$Response(
    params?: GetPlaceCollections$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PlaceCollectionDto>>> {
    return getPlaceCollections(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceCollections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollections(
    params?: GetPlaceCollections$Params,
    context?: HttpContext,
  ): Observable<Array<PlaceCollectionDto>> {
    return this.getPlaceCollections$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<PlaceCollectionDto>>,
        ): Array<PlaceCollectionDto> => r.body,
      ),
    );
  }

  /** Path part for operation `getPlaceCollectionsPreview()` */
  static readonly GetPlaceCollectionsPreviewPath =
    '/api/v1/places/collections/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceCollectionsPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollectionsPreview$Response(
    params?: GetPlaceCollectionsPreview$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PlaceCollectionPreviewDto>>> {
    return getPlaceCollectionsPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceCollectionsPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollectionsPreview(
    params?: GetPlaceCollectionsPreview$Params,
    context?: HttpContext,
  ): Observable<Array<PlaceCollectionPreviewDto>> {
    return this.getPlaceCollectionsPreview$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<PlaceCollectionPreviewDto>>,
        ): Array<PlaceCollectionPreviewDto> => r.body,
      ),
    );
  }

  /** Path part for operation `searchPlaceCollectionsPreview()` */
  static readonly SearchPlaceCollectionsPreviewPath =
    '/api/v1/places/collections/search/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPlaceCollectionsPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPlaceCollectionsPreview$Response(
    params?: SearchPlaceCollectionsPreview$Params,
    context?: HttpContext,
  ): Observable<
    StrictHttpResponse<PlaceCollectionsPreviewPaginatedResponseDto>
  > {
    return searchPlaceCollectionsPreview(
      this.http,
      this.rootUrl,
      params,
      context,
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPlaceCollectionsPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPlaceCollectionsPreview(
    params?: SearchPlaceCollectionsPreview$Params,
    context?: HttpContext,
  ): Observable<PlaceCollectionsPreviewPaginatedResponseDto> {
    return this.searchPlaceCollectionsPreview$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<PlaceCollectionsPreviewPaginatedResponseDto>,
        ): PlaceCollectionsPreviewPaginatedResponseDto => r.body,
      ),
    );
  }

  /** Path part for operation `getPlaceCollectionByIndex()` */
  static readonly GetPlaceCollectionByIndexPath =
    '/api/v1/places/collections/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlaceCollectionByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollectionByIndex$Response(
    params: GetPlaceCollectionByIndex$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PlaceCollectionDto>> {
    return getPlaceCollectionByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlaceCollectionByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlaceCollectionByIndex(
    params: GetPlaceCollectionByIndex$Params,
    context?: HttpContext,
  ): Observable<PlaceCollectionDto> {
    return this.getPlaceCollectionByIndex$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<PlaceCollectionDto>): PlaceCollectionDto =>
          r.body,
      ),
    );
  }
}
