/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { askAssistant } from '../fn/assistant/ask-assistant';
import { AskAssistant$Params } from '../fn/assistant/ask-assistant';
import { AssistantResponseDto } from '../models/assistant-response-dto';

@Injectable({ providedIn: 'root' })
export class AssistantApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `askAssistant()` */
  static readonly AskAssistantPath = '/api/v1/assistant/ask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `askAssistant()` instead.
   *
   * This method doesn't expect any request body.
   */
  askAssistant$Response(
    params: AskAssistant$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AssistantResponseDto>> {
    return askAssistant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `askAssistant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  askAssistant(
    params: AskAssistant$Params,
    context?: HttpContext,
  ): Observable<AssistantResponseDto> {
    return this.askAssistant$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<AssistantResponseDto>): AssistantResponseDto =>
          r.body,
      ),
    );
  }
}
