import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as uuid from 'uuid';
import { max } from 'lodash';

export interface FilterCheckboxOption {
  label: string;
  value: string | number;
}

@Component({
  selector: 'app-filter-checkbox',
  templateUrl: 'filter-checkbox.component.html',
  styleUrl: 'filter-checkbox.component.scss',
})
export class FilterCheckboxComponent implements OnInit, OnChanges {
  @Input()
  public options: FilterCheckboxOption[];

  @Input()
  public value: (string | number)[] = [];

  @Output()
  public valueChange = new EventEmitter<(string | number)[]>();

  @Input()
  public title: string;

  @Input()
  public name?: string;

  @Input()
  public radioButtons?: boolean;

  public inputsResultName: string;
  public isCropped = true;
  public shownOptions: FilterCheckboxOption[] = [];

  ngOnInit() {
    this.inputsResultName = this.name || uuid.v4();
    this.showAll(true);
  }

  ngOnChanges(): void {
    this.showAll(this.isCropped);
  }

  onChange(event: any, item: any) {
    if (this.radioButtons) {
      this.value = event.target.checked ? [item.value] : [];
    } else {
      if (event.target.checked) {
        this.value.push(item.value);
      } else {
        const index = this.value.indexOf(item.value);
        if (index > -1) {
          this.value.splice(index, 1);
        }
      }
    }

    this.valueChange.emit(this.value);
  }

  public showAll(isCropped: boolean) {
    this.isCropped = isCropped;
    const limit = 5;

    const options = [...this.options];

    let sortedOptions =
      limit < this.options.length && isCropped
        ? options.sort((a, b) => {
            const aIncluded = this.value.includes(a.value);
            const bIncluded = this.value.includes(b.value);
            if (aIncluded && !bIncluded) return -1;
            if (!aIncluded && bIncluded) return 1;
            return 0;
          })
        : options;

    if (isCropped) {
      sortedOptions = sortedOptions.slice(0, max([limit, this.value.length]));
    }

    this.shownOptions = sortedOptions;
  }

  clear() {
    this.value = [];
    this.valueChange.emit(this.value);
  }
}
