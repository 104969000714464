export enum ActivityAdditionalInfoDtoTypeEnum {
  OTHER = 'OTHER',
  WHEELCHAIR_ACCESSIBLE = 'WHEELCHAIR_ACCESSIBLE',
  NOT_WHEELCHAIR_ACCESSIBLE = 'NOT_WHEELCHAIR_ACCESSIBLE',
  STROLLER_ACCESSIBLE = 'STROLLER_ACCESSIBLE',
  PETS_WELCOME = 'PETS_WELCOME',
  PUBLIC_TRANSPORTATION_NEARBY = 'PUBLIC_TRANSPORTATION_NEARBY',
  INFANTS_MUST_SIT_ON_LAPS = 'INFANTS_MUST_SIT_ON_LAPS',
  INFANT_SEATS_AVAILABLE = 'INFANT_SEATS_AVAILABLE',
  TRANSPORTATION_WHEELCHAIR_ACCESSIBLE = 'TRANSPORTATION_WHEELCHAIR_ACCESSIBLE',
  SURFACES_WHEELCHAIR_ACCESSIBLE = 'SURFACES_WHEELCHAIR_ACCESSIBLE',
  NO_BACK_PROBLEMS = 'NO_BACK_PROBLEMS',
  NO_PREGNANT = 'NO_PREGNANT',
  NO_HEART_PROBLEMS = 'NO_HEART_PROBLEMS',
  HEALTH_OTHER = 'HEALTH_OTHER',
  PHYSICAL_EASY = 'PHYSICAL_EASY',
  PHYSICAL_MEDIUM = 'PHYSICAL_MEDIUM',
  PHYSICAL_HARD = 'PHYSICAL_HARD',
}

export enum ActivityAvailabilityDtoUnavailableReasonEnum {
  NOT_OPERATING = 'NOT_OPERATING',
  SOLD_OUT = 'SOLD_OUT',
  PAST_BOOKING_CUTOFF = 'PAST_BOOKING_CUTOFF',
  TRAVELER_MISMATCH = 'TRAVELER_MISMATCH',
  UNKNOWN = 'UNKNOWN',
}

export enum ActivityAvailabilityPriceBreakdownDtoAgeBandEnum {
  ADULT = 'ADULT',
  SENIOR = 'SENIOR',
  YOUTH = 'YOUTH',
  CHILD = 'CHILD',
  INFANT = 'INFANT',
  TRAVELER = 'TRAVELER',
}

export enum ActivityCancellationPolicyDtoTypeEnum {
  STANDARD = 'STANDARD',
  ALL_SALES_FINAL = 'ALL_SALES_FINAL',
  CUSTOM = 'CUSTOM',
}

export enum ActivityDtoConfirmationTypeEnum {
  INSTANT = 'INSTANT',
  MANUAL = 'MANUAL',
  INSTANT_THEN_MANUAL = 'INSTANT_THEN_MANUAL',
}

export enum ActivityInclusionDtoCategoryEnum {
  EQUIPMENT = 'EQUIPMENT',
  FOOD_AND_DRINK = 'FOOD_AND_DRINK',
  TRANSPORT_AMENITIES = 'TRANSPORT_AMENITIES',
  FEES_AND_TAXES = 'FEES_AND_TAXES',
  SOUVENIRS = 'SOUVENIRS',
  OTHER = 'OTHER',
}

export enum ActivityItineraryDtoTypeEnum {
  STANDARD = 'STANDARD',
  ACTIVITY = 'ACTIVITY',
  MULTI_DAY_TOUR = 'MULTI_DAY_TOUR',
  HOP_ON_HOP_OFF = 'HOP_ON_HOP_OFF',
  UNSTRUCTURED = 'UNSTRUCTURED',
}

export enum ActivityItineraryFoodMenuItemDtoCourseEnum {
  STARTER = 'STARTER',
  MAIN = 'MAIN',
  DESSERT = 'DESSERT',
}

export enum ActivityLogisticPickupDtoTypeEnum {
  PICKUP_EVERYONE = 'PICKUP_EVERYONE',
  PICKUP_AND_MEET_AT_START_POINT = 'PICKUP_AND_MEET_AT_START_POINT',
  MEET_EVERYONE_AT_START_POINT = 'MEET_EVERYONE_AT_START_POINT',
}

export enum ActivityLogisticPickupLocationDtoPickupTypeEnum {
  AIRPORT = 'AIRPORT',
  HOTEL = 'HOTEL',
  PORT = 'PORT',
  LOCATION = 'LOCATION',
  OTHER = 'OTHER',
}

export enum ActivityLogisticRedemptionDtoTypeEnum {
  NONE = 'NONE',
  ATTRACTION_START_POINT = 'ATTRACTION_START_POINT',
  DIFFERENT_LOCATION = 'DIFFERENT_LOCATION',
  INDIRECT_DELIVERY = 'INDIRECT_DELIVERY',
}

export enum ActivityOptionLangGuideDtoTypeEnum {
  GUIDE = 'GUIDE',
  AUDIO = 'AUDIO',
  WRITTEN = 'WRITTEN',
}

export enum ActivityPricingDtoTypeEnum {
  UNIT = 'UNIT',
  PER_PERSON = 'PER_PERSON',
}

export enum ActivityPricingDtoUnitTypeEnum {
  BIKE = 'BIKE',
  BOAT = 'BOAT',
  GROUP = 'GROUP',
  PACKAGE = 'PACKAGE',
  ROOM = 'ROOM',
  AIRCRAFT = 'AIRCRAFT',
  VEHICLE = 'VEHICLE',
}

export enum ArticleDtoCategoryEnum {
  Food = 'Food',
  Features = 'Features',
  ThingsToDo = 'ThingsToDo',
}

export enum CreateFavouriteItemDtoItemTypeEnum {
  Place = 'Place',
  Restaurant = 'Restaurant',
  Route = 'Route',
}

export enum CreateSuggestionDtoEntityTypeEnum {
  Place = 'Place',
  Route = 'Route',
  Restaurant = 'Restaurant',
  Activity = 'Activity',
  General = 'General',
}

export enum FileDtoContextEnum {
  public = 'public',
}

export enum PlaceCollectionDataDtoActivitiesEnum {
  Cultural = 'Cultural',
  Shopping = 'Shopping',
  Entertainment = 'Entertainment',
  Natural = 'Natural',
  Beaches = 'Beaches',
  Family = 'Family',
  Trekking = 'Trekking',
  Cycling = 'Cycling',
  Camping = 'Camping',
  Extreme = 'Extreme',
  Photo = 'Photo',
  IconicBuilding = 'IconicBuilding',
  ViewPoint = 'ViewPoint',
  Walking = 'Walking',
  WaterActivity = 'WaterActivity',
}

export enum PlaceDtoClosedSeasonsEnum {
  Winter = 'Winter',
  Spring = 'Spring',
  Summer = 'Summer',
  Autumn = 'Autumn',
}

export enum PlaceDtoClosedEnum {
  Closed = 'Closed',
  TemporarilyClosed = 'TemporarilyClosed',
  PartiallyClosed = 'PartiallyClosed',
}

export enum RestaurantDtoAwardsEnum {
  MichelinStar = 'MichelinStar',
  Worlds50BestRestaurants = 'Worlds50BestRestaurants',
  JamesBeardAward = 'JamesBeardAward',
  WineSpectatorAward = 'WineSpectatorAward',
  ForbesTravelGuideStars = 'ForbesTravelGuideStars',
  TimeOutDubaiRestaurantAward = 'TimeOutDubaiRestaurantAward',
  BBCGoodFoodMiddleEastAward = 'BBCGoodFoodMiddleEastAward',
}

export enum RestaurantDtoCuisinesEnum {
  Afghan = 'Afghan',
  African = 'African',
  American = 'American',
  Arabic = 'Arabic',
  Argentinean = 'Argentinean',
  Armenian = 'Armenian',
  Asian = 'Asian',
  Assyrian = 'Assyrian',
  Australian = 'Australian',
  Bangladeshi = 'Bangladeshi',
  Bar = 'Bar',
  Barbecue = 'Barbecue',
  Belgian = 'Belgian',
  Brazilian = 'Brazilian',
  BrewPub = 'BrewPub',
  British = 'British',
  Burmese = 'Burmese',
  Cafe = 'Cafe',
  CajunAndCreole = 'CajunAndCreole',
  Campania = 'Campania',
  Cantonese = 'Cantonese',
  Caribbean = 'Caribbean',
  CentralAmerican = 'CentralAmerican',
  CentralAsian = 'CentralAsian',
  CentralEuropean = 'CentralEuropean',
  Chinese = 'Chinese',
  Contemporary = 'Contemporary',
  Deli = 'Deli',
  Diner = 'Diner',
  Diningbars = 'Diningbars',
  EasternEuropean = 'EasternEuropean',
  Egyptian = 'Egyptian',
  Ethiopian = 'Ethiopian',
  European = 'European',
  FastFood = 'FastFood',
  Filipino = 'Filipino',
  French = 'French',
  Fusion = 'Fusion',
  Gastropub = 'Gastropub',
  Georgian = 'Georgian',
  German = 'German',
  Greek = 'Greek',
  Hawaiian = 'Hawaiian',
  Healthy = 'Healthy',
  HongKong = 'HongKong',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  International = 'International',
  Irish = 'Irish',
  Italian = 'Italian',
  Japanese = 'Japanese',
  JapaneseFusion = 'JapaneseFusion',
  Korean = 'Korean',
  Latin = 'Latin',
  Lebanese = 'Lebanese',
  Malaysian = 'Malaysian',
  Mediterranean = 'Mediterranean',
  Mexican = 'Mexican',
  MiddleEastern = 'MiddleEastern',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Neapolitan = 'Neapolitan',
  Pakistani = 'Pakistani',
  Persian = 'Persian',
  Peruvian = 'Peruvian',
  Pizza = 'Pizza',
  Portuguese = 'Portuguese',
  Pub = 'Pub',
  Romanian = 'Romanian',
  Seafood = 'Seafood',
  Singaporean = 'Singaporean',
  SouthAmerican = 'SouthAmerican',
  Southwestern = 'Southwestern',
  Spanish = 'Spanish',
  SriLankan = 'SriLankan',
  Steakhouse = 'Steakhouse',
  StreetFood = 'StreetFood',
  Sushi = 'Sushi',
  Szechuan = 'Szechuan',
  Thai = 'Thai',
  Turkish = 'Turkish',
  Tuscan = 'Tuscan',
  Uzbek = 'Uzbek',
  Vietnamese = 'Vietnamese',
  WineBar = 'WineBar',
}

export enum RestaurantDtoHighlightsEnum {
  HiddenGem = 'HiddenGem',
  InstagramSpot = 'InstagramSpot',
  BestForDining = 'BestForDining',
  FamilyFriendly = 'FamilyFriendly',
  Romantic = 'Romantic',
  BestForGroups = 'BestForGroups',
  OutdoorSeating = 'OutdoorSeating',
  PetFriendly = 'PetFriendly',
  LiveMusic = 'LiveMusic',
  RooftopView = 'RooftopView',
  CasualDining = 'CasualDining',
  FineDining = 'FineDining',
  QuickBites = 'QuickBites',
  LateNightDining = 'LateNightDining',
  VeganFriendly = 'VeganFriendly',
  GlutenFreeOptions = 'GlutenFreeOptions',
  KidFriendly = 'KidFriendly',
  ScenicView = 'ScenicView',
}

export enum RestaurantDtoPriceLevelEnum {
  Cheap = 'Cheap',
  Medium = 'Medium',
  Expensive = 'Expensive',
}

export enum RouteDtoTypeEnum {
  RoadTrip = 'RoadTrip',
  Hiking = 'Hiking',
  CityTour = 'CityTour',
}

export enum SearchActivitiesSortByEnum {
  Title = 'Title',
  Rating = 'Rating',
  PriceLow = 'PriceLow',
  PriceHigh = 'PriceHigh',
  Random = 'Random',
}

export enum GetArticlesPreviewSortByEnum {
  CreatedAt = 'CreatedAt',
}

export enum GetPlaceCollectionsPreviewSortByEnum {
  Random = 'Random',
  Favourite = 'Favourite',
}

export enum GetPlacesPreviewWorkingTimesEnum {
  OpenNow = 'OpenNow',
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  Night = 'Night',
}

export enum GetPlacesPreviewSortByEnum {
  Rating = 'Rating',
  Favourite = 'Favourite',
  Price = 'Price',
  Random = 'Random',
}

export enum GetRegionsSortByEnum {
  Rating = 'Rating',
}

export enum GetRestaurantsPreviewSortByEnum {
  Rating = 'Rating',
  Price = 'Price',
  Random = 'Random',
}

export enum GetRoutesPreviewSortByEnum {
  Random = 'Random',
  DistanceAsc = 'DistanceAsc',
  DistanceDesc = 'DistanceDesc',
  DurationAsc = 'DurationAsc',
  DurationDesc = 'DurationDesc',
}
