import { SearchActivitiesSortByEnum } from '../../shared-enums';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivitiesPaginatedResponseDto } from '../../models/activities-paginated-response-dto';

export interface SearchByPlace$Params {
  placeId: number;
  limit?: number;
  page?: number;
  sortBy?: SearchActivitiesSortByEnum;
}

export function searchByPlace(
  http: HttpClient,
  rootUrl: string,
  params: SearchByPlace$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ActivitiesPaginatedResponseDto>> {
  const rb = new RequestBuilder(rootUrl, searchByPlace.PATH, 'get');
  if (params) {
    rb.query('placeId', params.placeId, {});
    rb.query('limit', params.limit, {});
    rb.query('page', params.page, {});
    rb.query('sortBy', params.sortBy, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivitiesPaginatedResponseDto>;
      }),
    );
}

searchByPlace.PATH = '/api/v1/activities/search/place';
