import { RouteDtoTypeEnum } from '../../shared-enums';
import { GetRoutesPreviewSortByEnum } from '../../shared-enums';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RoutePreviewDto } from '../../models/route-preview-dto';

export interface GetRoutesPreview$Params {
  offset?: number;
  limit?: number;

  /**
   * RoadTrip = 1; Hiking = 2; CityTour = 3
   */
  types?: RouteDtoTypeEnum[];
  minMaxDuration?: Array<number>;
  ids?: Array<number>;
  indexes?: Array<string>;
  regionIds?: Array<number>;
  distanceMax?: number;
  sortBy?: GetRoutesPreviewSortByEnum;
}

export function getRoutesPreview(
  http: HttpClient,
  rootUrl: string,
  params?: GetRoutesPreview$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<RoutePreviewDto>>> {
  const rb = new RequestBuilder(rootUrl, getRoutesPreview.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('types', params.types, {});
    rb.query('minMaxDuration', params.minMaxDuration, {});
    rb.query('ids', params.ids, {});
    rb.query('indexes', params.indexes, {});
    rb.query('regionIds', params.regionIds, {});
    rb.query('distanceMax', params.distanceMax, {});
    rb.query('sortBy', params.sortBy, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoutePreviewDto>>;
      }),
    );
}

getRoutesPreview.PATH = '/api/v1/routes/preview';
