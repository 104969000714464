<div id="activities_list" class="content-wrap">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="content">
    <div
      id="filters"
      [class.open]="isFiltersOpen"
      (click)="closeFilters($event)">
      <div class="filters-body shadow-block">
        <div class="header">
          <div class="title">Filters</div>
          <div class="actions">
            <div class="close" (click)="isFiltersOpen = false">
              <ng-icon name="matCloseOutline"></ng-icon>
            </div>
          </div>
        </div>
        <div class="filters-list">
          <app-filter-checkbox
            [options]="ratingFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="ratingFilter"
            class="rating-filter"
            title="Rating"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="hoursFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="hoursFilter"
            title="Working hours"></app-filter-checkbox>

          <app-filter-slider
            [min]="0"
            [max]="24 * 60"
            (valueChange)="updateDurationFilter($event)"
            [format]="formatDuration.bind(this)"
            [step]="5"
            title="Duration"></app-filter-slider>

          <app-filter-slider
            [min]="0"
            [max]="maxCostsAed"
            (valueChange)="updateCostsFilter($event)"
            [format]="formatCosts.bind(this)"
            #costsFilter
            title="Costs"></app-filter-slider>

          <app-filter-checkbox
            [options]="additionalFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="additionalFilter"
            title="Additional"></app-filter-checkbox>
        </div>
        <div class="show-button" (click)="isFiltersOpen = false">
          Show {{ totalFilteredItems }} results
        </div>
      </div>
    </div>

    <div id="activities_list_body">
      <div class="header">
        <div class="filters">
          <div class="filters-button" (click)="isFiltersOpen = true">
            <ng-icon name="matFilterAltOutline"></ng-icon>
            Filters
          </div>
        </div>

        <div class="header-group">
          <div class="title-block">
            <div class="title">
              <h1>Explore activities</h1>
              <div class="loading-animation" *ngIf="isLoading"></div>
            </div>
            <div class="totals">
              <div class="total">{{ totalFilteredItems }} results</div>
            </div>
          </div>

          <div class="sorting-block">
            <app-custom-select
              icon="bootstrapSortDown"
              title="Sort By"
              (valueChange)="updateSortBy($event)"
              value="Rating"
              [items]="[
                {
                  label: 'Title',
                  value: 'Title'
                },
                {
                  label: 'Rating',
                  value: 'Rating'
                },
                {
                  label: 'Lowest Price',
                  value: 'PriceLow'
                },
                {
                  label: 'Higher Price',
                  value: 'PriceHigh'
                }
              ]"></app-custom-select>
          </div>
        </div>
      </div>

      <div class="not-found" *ngIf="activities?.length === 0 && !isLoading">
        Activities not found
      </div>

      <app-loader *ngIf="isLoading && !activities"></app-loader>

      <div class="list">
        <app-activity-preview
          *ngFor="let activity of activities; trackBy: trackBy"
          [horizontal]="isHorizontal"
          [activity]="activity"></app-activity-preview>
      </div>

      <div class="pagination-block" *ngIf="activities && activities.length > 0">
        <app-pagination
          [totalPages]="totalPages"
          [currentPage]="page"
          (pageChanged)="onPageChanged($event)"
          [isLoading]="isLoading"></app-pagination>
      </div>
    </div>
  </div>
</div>
