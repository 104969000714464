import { ChangeDetectorRef, Component } from '@angular/core';
import { GoogleAuthService } from '../google-auth.service';
import { AuthApiService } from '../../api/generated/abuduba-api';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth-popup',
  templateUrl: 'auth-popup.component.html',
  styleUrl: 'auth-popup.component.scss',
})
export class AuthPopupComponent {
  public isVisible = false;

  constructor(
    private authApiService: AuthApiService,
    private changeDetectorRef: ChangeDetectorRef,
    public googleAuthService: GoogleAuthService,
    public authService: AuthService,
  ) {}

  public open() {
    this.isVisible = true;
  }

  public close() {
    this.isVisible = false;
  }

  public loginGoogle() {
    this.googleAuthService.login();
  }
}
