<div
  class="reviews-block shadow-block"
  data-nosnippet
  *ngIf="reviewsData && reviewsData.reviews.length > 0">
  <div class="title-block">
    <img
      ngSrc="/assets/tripadvisor-logo-small.png"
      alt="Tripadvisor"
      height="30"
      width="30" />

    <div class="title">
      <div class="value">Tripadvisor reviews</div>
      <div class="count">{{ reviewsData.total }} travelers have been there</div>
    </div>
  </div>

  <div class="list">
    <div class="item" *ngFor="let item of reviewsData.reviews">
      <div class="user">
        <div class="avatar">
          <img
            [ngSrc]="item.userAvatarUrl"
            width="40"
            height="40"
            [alt]="item.username" />
        </div>

        <div class="user-info">
          <div class="name">{{ item.username }}</div>
          <div class="location">{{ item.userLocation }}</div>
        </div>
      </div>

      <div class="title-block">
        <div class="title">{{ item.title }}</div>

        <div class="rating">
          <div class="img">
            <img
              [ngSrc]="item.ratingImageUrl"
              width="110"
              height="20"
              [alt]="item.username" />
          </div>
        </div>
      </div>

      <div class="date-block">
        <div class="date">
          {{ item.publishedDate | date: 'hh:mm MMMM d, y' }}
        </div>
        <div class="type">
          {{ item.tripType }}
        </div>
      </div>

      <div class="text">{{ item.text }}</div>
    </div>
  </div>

  <a [href]="reviewsData.reviewsPageUrl" target="_blank" class="see-all"
    >See all</a
  >
</div>
