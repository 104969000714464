import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-page',
  styleUrls: ['terms-page.component.scss'],
  templateUrl: 'terms-page.component.html',
})
export class TermsPageComponent {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Abuduba - Terms');

    metaService.updateTag({
      name: 'description',
      content:
        'Review the terms and conditions for using Abuduba’s services across the UAE. Understand your rights, responsibilities, and the guidelines for accessing and using our platform in Dubai, Abu Dhabi, and other emirates.',
    });
    metaService.updateTag({
      name: 'keywords',
      content:
        'Abuduba terms and conditions, service terms UAE, user agreement UAE, Abuduba platform guidelines, legal terms UAE, Abuduba service, terms of use UAE, Dubai terms, Abu Dhabi terms, Abuduba user responsibilities',
    });
  }
}
