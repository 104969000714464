import { isUndefined } from 'lodash';
import dayjs from 'dayjs';
import {
  FileDto,
  PlaceDailyOpenHoursDto,
  PlaceDto,
  PlacePreviewDto,
  PlaceWeeklyOpenHoursDto,
} from '../core/api/generated/abuduba-api';
import { placeActivities } from './places.types';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import pluralize from 'pluralize';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours >= 0 && remainingMinutes === 0) {
    return pluralize('hour', hours, true);
  }

  let result = hours > 0 ? `${hours}h` : '';
  if (remainingMinutes > 0) {
    result += result
      ? ` ${remainingMinutes}m`
      : pluralize('minute', remainingMinutes, true);
  }

  return result;
}

export function isOpenNow(time: PlaceDailyOpenHoursDto, tz: string) {
  if (!time.isOpen) {
    return false;
  }

  if (time.isOpen24h) {
    return true;
  }

  const currentTime = dayjs().tz(tz);

  for (const interval of time.intervals) {
    const { openTime, closeTime } = interval;

    if (isUndefined(openTime) || isUndefined(closeTime)) {
      continue;
    }

    const start = dayjs.tz(
      `${currentTime.format('YYYY-MM-DD')} ${openTime}`,
      'YYYY-MM-DD HH:mm',
      tz,
    );
    const end = dayjs.tz(
      `${currentTime.format('YYYY-MM-DD')} ${closeTime}`,
      'YYYY-MM-DD HH:mm',
      tz,
    );

    // Handle overnight times (closing past midnight)
    if (end.isBefore(start)) {
      // If current time is before end time, it means the place is open overnight
      if (currentTime.isAfter(start) || currentTime.isBefore(end)) {
        return true;
      }
    } else {
      // Compare current time with start and end times
      if (currentTime.isAfter(start) && currentTime.isBefore(end)) {
        return true;
      }
    }
  }

  // If no interval matches, return false
  return false;
}

export function getCurrentWorkingTime(
  workingTime?: PlaceWeeklyOpenHoursDto,
): PlaceDailyOpenHoursDto {
  const currentDay: keyof PlaceWeeklyOpenHoursDto = <
    keyof PlaceWeeklyOpenHoursDto
  >dayjs().format('dddd').toLowerCase();

  if (!workingTime || !workingTime[currentDay]) {
    return {
      isOpen: false,
      isOpen24h: false,
      intervals: [],
    };
  }

  return workingTime[currentDay];
}

export function getActivityName(
  activity: PlaceDto['activities'][number],
): string {
  return placeActivities[activity]?.name || '';
}

export function getActivityIcon(
  activity: PlaceDto['activities'][number],
): string {
  return placeActivities[activity]?.icon || '';
}

export function formatDistance(distanceVal?: number): string {
  const distance = distanceVal || 0;

  return `${(distance / 1000).toFixed(1)}km`;
}

export function getMainPictureUrl(
  files?: (FileDto | undefined)[],
  size?: 'medium' | 'thumb' | 'small' | 'circle',
): string {
  let key: keyof FileDto = 'mediumSizeUrl';

  if (size === 'thumb') {
    key = 'thumbSizeUrl';
  }

  if (size === 'circle') {
    key = 'thumbCircleSizeUrl';
  }

  if (size === 'small') {
    key = 'smallSizeUrl';
  }

  return files && files[0] ? files[0][key] : '/assets/stub.jpg';
}

export function formatWorkingTime(time: PlaceDailyOpenHoursDto): string {
  if (!time.isOpen) {
    return 'Closed';
  }

  if (time.isOpen24h) {
    return 'Open 24h';
  }

  if (time.intervals.length === 0) {
    return 'Closed';
  }

  const intervalsFormatted = time.intervals
    .map((interval) => `${interval.openTime}-${interval.closeTime}`)
    .join(', ');

  return intervalsFormatted;
}

export function removeHtmlTags(str: string) {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

export function formatClosedType(place: PlaceDto | PlacePreviewDto): string {
  let closedPlaceDescription: string;

  switch (place.closed) {
    case 'TemporarilyClosed':
      closedPlaceDescription = 'Place temporarily closed';
      break;
    case 'PartiallyClosed':
      closedPlaceDescription = 'Place partially closed';
      break;
    case 'Closed':
    default:
      closedPlaceDescription = 'Place closed';
  }

  if (place.closedSeasons && place.closedSeasons.length > 0) {
    closedPlaceDescription += ' for ' + place.closedSeasons.join(', ');
  }

  return closedPlaceDescription;
}
