import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NearestPlaceDto,
  PlaceCollectionPreviewDto,
  PlaceCollectionsApiService,
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { placeActivities } from '../places.types';
import { ActivatedRoute } from '@angular/router';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import { FilterCheckboxOption } from '../../core/filter-checkbox/filter-checkbox.component';
import { orderBy } from 'lodash';
import { SearchPlaces$Params } from '../../core/api/generated/abuduba-api/fn/places/search-places';
import { CurrencyManager } from '../../core/currency-manager';
import { FilterSliderComponent } from '../../core/filter-slider/filter-slider.component';
import { formatMinutes } from '../places.utils';
import { Subject, takeUntil } from 'rxjs';
import { SearchPlacesPreview$Params } from '../../core/api/generated/abuduba-api/fn/places/search-places-preview';
import { ListPopoverItem } from '../../core/custom-select/custom-select.component';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { PaginationComponent } from '../../core/pagination/pagination.component';
import {
  GetPlaceCollectionsPreviewSortByEnum,
  GetPlacesPreviewSortByEnum,
  GetPlacesPreviewWorkingTimesEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import { ApiHelper } from '../../core/api/api.helper';
import { MapCoordinates } from '../../core/mapbox/mapbox.component';
import { Geolocation } from '@capacitor/geolocation';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';

dayjs.extend(utc);

@Component({
  selector: 'app-places-list-page',
  styleUrls: ['places-list-page.component.scss'],
  templateUrl: 'places-list-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesListPageComponent implements OnInit {
  public places?: PlacePreviewDto[];
  public nearestPlaces?: NearestPlaceDto[];
  public collections?: PlaceCollectionPreviewDto[];
  public regions: RegionDto[] = [];
  public totalItems = 0;
  public totalFilteredItems = 0;

  public page = 1;
  public limit = 25;
  public isLoading = false;
  public isMapLoading = false;
  public isMapView = false;
  public isFiltersOpen = false;
  public userLocation?: MapCoordinates | null;
  public userLocationError?: string;

  public sortBy: SearchPlacesPreview$Params['sortBy'] =
    GetPlacesPreviewSortByEnum.Favourite;

  private destroy$ = new Subject<void>();

  @ViewChild(PlacesMapComponent)
  public map: PlacesMapComponent;

  @ViewChild(PaginationComponent)
  public paginationComponent?: PaginationComponent;

  @ViewChild('costsFilter', { static: true })
  public costsFilterComponent: FilterSliderComponent;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly placeCollectionsApiService: PlaceCollectionsApiService,
    private readonly regionsApiService: RegionsApiService,
    private route: ActivatedRoute,
    public readonly currencyManager: CurrencyManager,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private apiHelper: ApiHelper,
  ) {
    this.generatePageMeta();
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.places) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Places',
        url: `/places`,
      },
    ];
  }

  public generatePageMeta() {
    const title = 'Abuduba - Explore Places';
    const description =
      'Explore the best places to visit in the UAE with Abuduba. Discover iconic landmarks, hidden gems, and must-see attractions across popular emirates like Dubai and Abu Dhabi. Let Abuduba guide you to the top destinations in the UAE.';
    const keywords =
      'Abuduba explore places UAE, top destinations UAE, Dubai landmarks, Abu Dhabi attractions, must-see places UAE, Abuduba travel guide, explore UAE, popular emirates, UAE tourism, hidden gems UAE, Abuduba service';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  public closeFilters(e: MouseEvent) {
    const barBody = document.querySelector('#filters .filters-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isFiltersOpen = false;
    }
  }

  public toggleMapView(isMapView: boolean) {
    if (isMapView === this.isMapView) {
      return;
    }

    this.isMapView = isMapView;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapPlaces();
      } else {
        this.map?.cleanMap();
        this.updatePlaces();
      }
    }, 0);
  }

  public activitiesFilterOptions: FilterCheckboxOption[] = orderBy(
    Object.entries(placeActivities).map(([i, val]) => ({
      label: val.name,
      value: i,
    })),
    'label',
    'asc',
  );
  public activitiesFilter: PlacePreviewDto['activities'] = [];

  public regionsFilterOptions: FilterCheckboxOption[] = [];
  public regionsFilter: number[] = [];

  public hoursFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Open now',
      value: GetPlacesPreviewWorkingTimesEnum.OpenNow,
    },
    {
      label: 'Morning (7AM-12PM)',
      value: GetPlacesPreviewWorkingTimesEnum.Morning,
    },
    {
      label: 'Afternoon (12PM-5PM)',
      value: GetPlacesPreviewWorkingTimesEnum.Afternoon,
    },
    {
      label: 'Evening (5PM-12AM)',
      value: GetPlacesPreviewWorkingTimesEnum.Evening,
    },
    {
      label: 'Night (12AM-7AM)',
      value: GetPlacesPreviewWorkingTimesEnum.Night,
    },
  ];
  public hoursFilter: string[] = [];

  public additionalFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Can visit for free',
      value: 'free',
    },
    {
      label: 'Skip closed places',
      value: 'skip-closed',
    },
    {
      label: 'Travelers favorite',
      value: 'favourite',
    },
  ];
  public additionalFilter: string[] = [];

  public durationFilter: number[] = [];

  public costsFilterAed: number[] = [];
  public maxCostsAed: number = 0;

  public ratingFilterOptions: FilterCheckboxOption[] = [
    {
      label: '★ ★ ★ ★ ★',
      value: 5,
    },
    {
      label: '★ ★ ★ ★',
      value: 4,
    },
    {
      label: '★ ★ ★',
      value: 3,
    },
    {
      label: '★ ★',
      value: 2,
    },
    {
      label: '★',
      value: 1,
    },
  ];
  public ratingFilter: number[] = [];

  ngOnInit() {
    this.parseQueryParams();
    this.initFiltersData();
    this.updatePlaces();
    this.updateCollections();

    this.updateCurrentPosition(true);
  }

  public updateDurationFilter(value: number[]) {
    this.durationFilter = value;
    this.onFiltersChange();
  }

  public updateCostsFilter(value: number[]) {
    this.costsFilterAed = [value[0], value[1]];
    this.onFiltersChange();
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;
    const activities = params.get('activities')?.split(',') || [];

    if (activities.length > 0) {
      this.activitiesFilter = <any>activities;
    }

    const regions = params.get('regions')?.split(',') || [];

    if (regions.length > 0) {
      this.regionsFilter = regions.map(Number);
    }

    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }

    this.changeDetectorRef.markForCheck();
  }

  public placeTrackBy(index: number, place: PlacePreviewDto) {
    return place.id;
  }

  public formatCosts(val: number) {
    return this.currencyManager.format(val);
  }

  public formatDuration(val: number) {
    return formatMinutes(val);
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updatePlaces();
  }

  public onFiltersChange() {
    this.page = 1;
    this.paginationComponent?.setPage(this.page, false);
    //this.places = undefined;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapPlaces();
      } else {
        this.updatePlaces();
      }
    }, 0);
  }

  public updateCurrentPosition(checkPermissions = false) {
    if (!this.apiHelper.isBrowser) {
      return;
    }

    Geolocation.checkPermissions().then(({ location }) => {
      if (location === 'granted' || !checkPermissions) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 3 * 60 * 1000,
        })
          .then((position) => {
            this.userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            if (this.isMapView) {
              this.map?.locateUser();
            }

            this.getNearestPlaces();
            this.changeDetectorRef.detectChanges();
          })
          .catch((e) => {
            this.userLocationError = e.message;
            this.changeDetectorRef.detectChanges();
          });
      } else {
        this.userLocation = null;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public getNearestPlaces() {
    if (!this.userLocation) {
      return;
    }

    this.placesApiService
      .getNearestPlaces({
        y: this.userLocation.lat,
        x: this.userLocation.lng,
        limit: 10,
      })
      .subscribe((data) => {
        this.nearestPlaces = data;
        this.changeDetectorRef.detectChanges();
      });
  }

  public initFiltersData() {
    this.regionsApiService.getRegions().subscribe((regions) => {
      this.regions = regions;

      this.regionsFilterOptions = this.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));

      this.changeDetectorRef.detectChanges();
    });

    this.placesApiService.getMaxPlacesCost().subscribe((data) => {
      this.maxCostsAed = data.valueAed;
      this.changeDetectorRef.detectChanges();
      this.costsFilterComponent.clear(false);

      this.currencyManager.currentCurrency$.subscribe(() => {
        // this.changeDetectorRef.detectChanges();
        this.costsFilterComponent.rerender();
      });
    });
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  private getFilters(): SearchPlaces$Params {
    const isFreeOption = !!this.additionalFilter.find((f) => f === 'free');
    const skipClosed = !!this.additionalFilter.find((f) => f === 'skip-closed');
    const favourite = !!this.additionalFilter.find((f) => f === 'favourite');

    return {
      activities: this.activitiesFilter,
      workingTimes: <GetPlacesPreviewWorkingTimesEnum[]>this.hoursFilter,
      regionIds: this.regionsFilter,
      minMaxDuration: this.durationFilter,
      minMaxPrice: this.costsFilterAed,
      ratings: this.ratingFilter,
      isFreeOption: isFreeOption || undefined,
      skipClosed: skipClosed || undefined,
      favourite: favourite || undefined,
    };
  }

  public updateSortBy(val: ListPopoverItem) {
    this.sortBy = <SearchPlacesPreview$Params['sortBy']>val.value;
    this.onFiltersChange();
  }

  public updateCollections() {
    this.placeCollectionsApiService
      .getPlaceCollectionsPreview({
        limit: 10,
        sortBy: GetPlaceCollectionsPreviewSortByEnum.Favourite,
      })
      .subscribe((data) => {
        this.collections = data;
        this.changeDetectorRef.detectChanges();
      });
  }

  public updatePlaces() {
    this.isLoading = true;

    this.destroy$.next();

    this.placesApiService
      .searchPlacesPreview({
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        sortBy: this.sortBy,
        ...this.getFilters(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.places = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  public updateMapPlaces() {
    this.isMapLoading = true;

    this.placesApiService
      .searchPlacesPreview({
        ...this.getFilters(),
      })
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;

          this.map?.renderPlaces(data.items.map((p) => ({ data: p })) || []);
          setTimeout(() => {
            if (this.nearestPlaces) {
              this.map?.locateUser();
            }
          }, 500);

          this.isMapLoading = false;
        },
        error: () => {
          this.isMapLoading = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }
}
