import { Component, Input } from '@angular/core';
import { PlaceGoogleInfoDto } from '../api/generated/abuduba-api';

@Component({
  selector: 'app-google-rating',
  templateUrl: 'google-rating.component.html',
  styleUrl: 'google-rating.component.scss',
})
export class GoogleRatingComponent {
  @Input()
  public data: PlaceGoogleInfoDto;

  @Input()
  public small: boolean = false;

  @Input()
  public textOnly: boolean = false;
}
