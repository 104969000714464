import { GetPlaceCollectionsPreviewSortByEnum } from '../../shared-enums';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PlaceCollectionsPreviewPaginatedResponseDto } from '../../models/place-collections-preview-paginated-response-dto';

export interface SearchPlaceCollectionsPreview$Params {
  offset?: number;
  limit?: number;
  ids?: Array<number>;
  sortBy?: GetPlaceCollectionsPreviewSortByEnum;
  favourite?: boolean;
}

export function searchPlaceCollectionsPreview(
  http: HttpClient,
  rootUrl: string,
  params?: SearchPlaceCollectionsPreview$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<PlaceCollectionsPreviewPaginatedResponseDto>> {
  const rb = new RequestBuilder(
    rootUrl,
    searchPlaceCollectionsPreview.PATH,
    'get',
  );
  if (params) {
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('ids', params.ids, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('favourite', params.favourite, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceCollectionsPreviewPaginatedResponseDto>;
      }),
    );
}

searchPlaceCollectionsPreview.PATH =
  '/api/v1/places/collections/search/preview';
