/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PlaceFullWeatherDto } from '../../models/place-full-weather-dto';

export interface GetPlaceWeather$Params {
  index: string;
}

export function getPlaceWeather(
  http: HttpClient,
  rootUrl: string,
  params: GetPlaceWeather$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<PlaceFullWeatherDto>> {
  const rb = new RequestBuilder(rootUrl, getPlaceWeather.PATH, 'get');
  if (params) {
    rb.path('index', params.index, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceFullWeatherDto>;
      }),
    );
}

getPlaceWeather.PATH = '/api/v1/places/{index}/weather';
