import { ApiHelper } from '../../core/api/api.helper';
import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-internal-error',
  styleUrls: ['./internal-error.component.scss'],
  templateUrl: './internal-error.component.html',
})
export class InternalErrorComponent {
  public errorCode: string | null = null;
  public errorMessage: string | null = null;

  constructor(
    public readonly apiHelper: ApiHelper,
    private titleService: Title,
    private route: ActivatedRoute, // Inject ActivatedRoute to get query params
  ) {
    titleService.setTitle('Abuduba - Internal error');

    // Get query parameters (if any)
    this.route.queryParams.subscribe((params) => {
      this.errorCode = params['code'] || '500'; // Default to 500 if no code provided
      this.errorMessage = params['message'];

      console.log(this.errorCode, this.errorMessage);
    });
  }
}
