/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMe } from '../fn/users/get-me';
import { GetMe$Params } from '../fn/users/get-me';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class UsersApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/api/v1/users/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(
    params?: GetMe$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserDto>> {
    return getMe(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params?: GetMe$Params, context?: HttpContext): Observable<UserDto> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body),
    );
  }
}
