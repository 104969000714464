import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, skip } from 'rxjs';
import { ApiHelper } from './api/api.helper';

@Injectable({ providedIn: 'root' })
export class CurrencyManager {
  private currencySubject = new BehaviorSubject<string>(
    this.getStoredCurrency(),
  );
  currentCurrency$ = this.currencySubject.asObservable().pipe(
    skip(1), // Ignore the initial emission
    distinctUntilChanged(), // Emit only on value change
  );

  constructor(private readonly apiHelper: ApiHelper) {}

  setCurrency(currency: string) {
    try {
      localStorage.setItem('currentCurrency', currency);
      this.currencySubject.next(currency);
    } catch (e) {
      console.error(e);
    }
  }

  getCurrency(): string {
    return this.currencySubject.value || 'AED';
  }

  getCurrencyWithSymbol(): string {
    const currency = this.getCurrency();
    let symbol = '';

    switch (currency) {
      case 'USD':
        symbol = '$';
        break;
      case 'AED':
        symbol = 'د.إ';
        break;
    }

    return `${currency} ${symbol}`;
  }

  convertCurrency(
    value: number,
    targetCurrency: string = 'AED',
    currentCurrency?: string,
  ): number {
    if (!currentCurrency) {
      currentCurrency = this.getCurrency();
    }

    switch (currentCurrency) {
      case 'USD':
        return targetCurrency === 'AED' ? Math.ceil(value / 3.67) : value;

      case 'AED':
        return targetCurrency === 'USD' ? Math.ceil(value * 3.67) : value;
    }

    return value;
  }

  toggleCurrency(): string {
    const val = this.getCurrency();
    const newVal = val === 'AED' ? 'USD' : 'AED';
    this.setCurrency(newVal);

    return newVal;
  }

  private getStoredCurrency(): string {
    try {
      if (this.apiHelper.isBrowser) {
        return localStorage.getItem('currentCurrency') || 'USD';
      } else {
        return 'USD';
      }
    } catch (e) {
      return 'USD';
    }
  }

  public format(
    value?: number,
    targetCurrency: string = 'AED',
    currentCurrency?: string,
  ) {
    if (!currentCurrency) {
      currentCurrency = this.getCurrency();
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currentCurrency,
      maximumFractionDigits: 0,
    });

    if (value !== undefined) {
      return formatter.format(
        this.convertCurrency(value, targetCurrency, currentCurrency),
      );
    }

    return '';
  }
}
