import {
  ActivityCancellationPolicyDto,
  ActivityDto,
  ActivityItineraryDurationDto,
  ActivityItineraryFoodMenuItemDto,
  ActivityPreviewDto,
  AvailabilityPaxMixDto,
} from '../core/api/generated/abuduba-api';
import pluralize from 'pluralize';
import { ActivityAvailabilityPriceBreakdownDtoAgeBandEnum } from '../core/api/generated/abuduba-api/shared-enums';
import { formatMinutes } from '../places/places.utils';

export function formatActivityDuration(duration: ActivityItineraryDurationDto) {
  if (duration.unstructuredDuration) {
    return duration.unstructuredDuration;
  }

  if (duration.fixedDurationInMinutes) {
    return formatMinutes(duration.fixedDurationInMinutes);
  }

  if (
    duration.variableDurationFromMinutes &&
    duration.variableDurationToMinutes
  ) {
    return `${formatMinutes(duration.variableDurationFromMinutes)} - ${formatMinutes(duration.variableDurationToMinutes)}`;
  }

  return '';
}

export function getActivityDestinations(
  activity: ActivityDto | ActivityPreviewDto,
) {
  return (
    activity?.destinations
      .slice(0, 3)
      .map((d) => d.name)
      .join(', ') || ''
  );
}

export function isActivityPickupIncluded(
  activity: ActivityDto | ActivityPreviewDto,
) {
  return (
    activity.logistics.pickup?.type === 'PICKUP_EVERYONE' ||
    'PICKUP_AND_MEET_AT_START_POINT'
  );
}

export function formatAgeBand(
  band: AvailabilityPaxMixDto['ageBand'] = ActivityAvailabilityPriceBreakdownDtoAgeBandEnum.TRAVELER,
  count?: number,
): string {
  const names = {
    ADULT: 'Adult',
    SENIOR: 'Senior',
    YOUTH: 'Youth',
    CHILD: 'Child',
    INFANT: 'Infant',
    TRAVELER: 'Traveler',
  };

  const singular = names[band];

  return pluralize(singular, count || 1, true);
}

export function formatCancellationPolicy(
  policy: ActivityCancellationPolicyDto,
) {
  switch (policy.type) {
    case 'ALL_SALES_FINAL':
      return 'Non-cancellable and non-amendable; 100% penalty applies.';
    case 'CUSTOM':
      return 'Refunds vary by cancellation timing; mainly applies to multi-day tours with custom policies.';
    case 'STANDARD':
      return 'Cancellable up to 24 hours before travel for a full refund; 100% penalty for later cancellations.';
    default:
      return 'Unknown cancellation policy.';
  }
}

export function formatDishType(
  course: ActivityItineraryFoodMenuItemDto['course'],
) {
  switch (course) {
    case 'STARTER':
      return 'Starter';
    case 'MAIN':
      return 'Main';
    case 'DESSERT':
      return 'Dessert';
    default:
      return 'Unknown';
  }
}
