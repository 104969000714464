import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapboxComponent } from './mapbox.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [MapboxComponent],
  imports: [CommonModule, NgxSkeletonLoaderModule],
  providers: [],
  bootstrap: [],
  exports: [MapboxComponent],
})
export class MapboxModule {}
