import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap, take, filter } from 'rxjs/operators';
import {
  CreateFavouriteItemDto,
  CreateFavouriteListDto,
  DeleteFavouriteItemDto,
  FavouriteApiService,
  FavouriteItemDto,
  FavouriteListDto,
} from './api/generated/abuduba-api';
import { CreateFavouriteItemDtoItemTypeEnum } from './api/generated/abuduba-api/shared-enums';
import { flatten, omit } from 'lodash';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FavouritesService {
  private cachedData:
    | (FavouriteListDto & { items: FavouriteItemDto[] })[]
    | null = null;

  private requestInProgress$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private api: FavouriteApiService,
    private authService: AuthService,
  ) {}

  private clearCache(): void {
    this.cachedData = null;
  }

  getData(): Observable<(FavouriteListDto & { items: FavouriteItemDto[] })[]> {
    if (this.cachedData) {
      return of(this.cachedData);
    }

    if (!this.authService.isAuth()) {
      return of([]);
    }

    if (this.requestInProgress$.getValue()) {
      return this.requestInProgress$.pipe(
        filter((inProgress) => !inProgress),
        take(1),
        switchMap(() => of(this.cachedData || [])), // Provide fallback for null
      );
    }

    this.requestInProgress$.next(true);

    return forkJoin({
      lists: this.api.getLists(),
      items: this.api.getListItems(),
    }).pipe(
      map(({ lists, items }) =>
        lists.map((list) => ({
          ...list,
          items: items.filter((item) => item.listId === list.id),
        })),
      ),
      tap((data) => {
        this.cachedData = data;
        this.requestInProgress$.next(false);
      }),
      tap({
        error: () => this.requestInProgress$.next(false),
      }),
    );
  }

  getListById(listId: number): Observable<FavouriteListDto | null> {
    return this.getData().pipe(
      map((data) => data.find((list) => list.id === listId) || null),
    );
  }

  getLists(): Observable<FavouriteListDto[]> {
    return this.getData().pipe(
      map((data) => data.map((list) => omit(list, ['items']))),
    );
  }

  getItems(): Observable<FavouriteItemDto[]> {
    return this.getData().pipe(
      map((data) => flatten(data.map((list) => list.items))),
    );
  }

  getItemsByList(listId: number): Observable<FavouriteItemDto[]> {
    return this.getData().pipe(
      map((data) => {
        const list = data.find((list) => list.id === listId);
        return list ? list.items : [];
      }),
    );
  }

  getListsOfItem(
    itemId: number,
    type: CreateFavouriteItemDtoItemTypeEnum,
  ): Observable<FavouriteListDto[]> {
    return this.getData().pipe(
      map((data) =>
        data.filter((list) =>
          list.items.some(
            (item) => item.itemId === itemId && item.itemType === type,
          ),
        ),
      ),
    );
  }

  addItemToList(item: CreateFavouriteItemDto) {
    this.clearCache();
    return this.api.createListItem({ body: item });
  }

  removeItemFromList(data: DeleteFavouriteItemDto) {
    this.clearCache();
    return this.api.deleteItem({ body: data });
  }

  createList(list: CreateFavouriteListDto) {
    this.clearCache();
    return this.api.createList({ body: list });
  }

  removeList(id: number) {
    this.clearCache();
    return this.api.deleteListById({ id });
  }
}
