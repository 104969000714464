<div id="auth_page">
  <div class="error" *ngIf="error">
    <div class="title">Sorry, something went wrong on our end</div>

    <div class="subtitle">
      {{ error }}
    </div>

    <a class="home" routerLink="/">Go Back Home</a>
  </div>
</div>
