import { Injectable } from '@angular/core';
import {
  AuthApiService,
  AuthResponseDto,
  UserDto,
  UsersApiService,
} from '../api/generated/abuduba-api';
import { Observable, of, switchMap, tap } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { ApiHelper } from '../api/api.helper';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private tokenKey = 'accessToken';
  private refreshTokenKey = 'refreshToken';
  private user: UserDto | null = null;

  constructor(
    private readonly authApi: AuthApiService,
    private readonly usersApi: UsersApiService,
    private readonly apiHelper: ApiHelper,
  ) {}

  refreshToken(): Observable<AuthResponseDto> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    return this.authApi.refreshAuthToken({ refreshToken }).pipe(
      tap((res) => {
        this.storeTokens(res.accessToken, res.refreshToken);
        this.storeUser(res.user);
      }),
    );
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  public storeUser(user: UserDto): void {
    this.user = user;
  }

  public getUser(): Observable<UserDto | null> {
    if (this.user) {
      return of(this.user);
    } else {
      return this.usersApi.getMe().pipe(
        switchMap((res) => {
          this.storeUser(res);
          return of(res);
        }),
      );
    }
  }

  public storeTokens(accessToken: string, refreshToken: string): void {
    this.storeAccessToken(accessToken);
    this.storeRefreshToken(refreshToken);
  }

  public storeAccessToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  public storeRefreshToken(token: string): void {
    localStorage.setItem(this.refreshTokenKey, token);
  }

  public clearTokens(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }

  isAuth(): boolean {
    return this.apiHelper.isBrowser && !!this.getAccessToken();
  }

  getTokenExpiration(token: string): Date | null {
    try {
      const decoded: { exp: number } = jwtDecode(token);
      return new Date(decoded.exp * 1000);
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }

  isTokenExpired(token: string, bufferInSeconds: number = 0): boolean {
    const expirationDate = this.getTokenExpiration(token);

    if (!expirationDate) {
      return true; // Treat as expired if invalid
    }
    const bufferMilliseconds = bufferInSeconds * 1000;
    return new Date().getTime() > expirationDate.getTime() - bufferMilliseconds;
  }

  getAccessTokenExpiration(): Date | null {
    const token = this.getAccessToken();
    return token ? this.getTokenExpiration(token) : null;
  }
}
