import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FavouriteItemDto,
  FavouriteListDto,
  PlacePreviewDto,
  PlacesApiService,
  RestaurantPreviewDto,
  RestaurantsApiService,
  RoutePreviewDto,
  RoutesApiService,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { ApiHelper } from '../../core/api/api.helper';
import { Meta, Title } from '@angular/platform-browser';
import { forkJoin, of, switchMap, tap } from 'rxjs';
import { FavouritesService } from '../../core/favourites.service';
import { EventBus } from '../../core/event-bus';
import { CreateFavouriteItemDtoItemTypeEnum } from '../../core/api/generated/abuduba-api/shared-enums';

dayjs.extend(utc);

@Component({
  selector: 'app-favourite-list-full-page',
  styleUrls: ['favourite-list-full-page.component.scss'],
  templateUrl: 'favourite-list-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouriteListFullPageComponent implements OnInit {
  public list?: FavouriteListDto | null;
  public items?: FavouriteItemDto[];
  public error?: string;
  public isDeletedPopupOpened = false;

  public places: PlacePreviewDto[] = [];
  public restaurants: RestaurantPreviewDto[] = [];
  public routes: RoutePreviewDto[] = [];

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly restaurantsApiService: RestaurantsApiService,
    public readonly apiHelper: ApiHelper,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private favouritesService: FavouritesService,
    private eventBus: EventBus,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    titleService.setTitle('Abuduba - Favourites');
  }

  closeDeletePopup() {
    this.isDeletedPopupOpened = false;
    this.changeDetectorRef.detectChanges();
  }

  openDeletePopup(e: Event) {
    this.isDeletedPopupOpened = true;
    this.changeDetectorRef.detectChanges();
    e.preventDefault();
    e.stopPropagation();
  }

  delete() {
    if (!this.list) {
      return;
    }

    this.favouritesService.removeList(this.list.id).subscribe({
      next: () => {
        this.router.navigate(['/favourites']);
      },
      error: (err) => {
        this.error =
          'An error occurred while deleting list. Please try again later. If the problem persists, please contact us. Thank you.';
        console.error(err);
      },
    });
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.list) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Favourites',
        url: `/favourites`,
      },
      {
        label: this.list.title,
        url: `/favourites/list/${this.list.id}`,
      },
    ];
  }

  ngOnInit(): void {
    this.updateData();
  }

  public fetchItems() {
    const placeIds =
      this.items
        ?.filter((i) => i.itemType === CreateFavouriteItemDtoItemTypeEnum.Place)
        .map((i) => i.itemId) || [];
    const routeIds =
      this.items
        ?.filter((i) => i.itemType === CreateFavouriteItemDtoItemTypeEnum.Route)
        .map((i) => i.itemId) || [];
    const restaurantIds =
      this.items
        ?.filter(
          (i) => i.itemType === CreateFavouriteItemDtoItemTypeEnum.Restaurant,
        )
        .map((i) => i.itemId) || [];

    forkJoin({
      places:
        placeIds.length > 0
          ? this.placesApiService.getPlacesPreview({ ids: placeIds })
          : of([]),
      routes:
        routeIds.length > 0
          ? this.routesApiService.getRoutesPreview({ ids: routeIds })
          : of([]),
      restaurants:
        restaurantIds.length > 0
          ? this.restaurantsApiService.getRestaurantsPreview({
              ids: restaurantIds,
            })
          : of([]),
    }).subscribe({
      next: ({ places, routes, restaurants }) => {
        this.places = places;
        this.routes = routes;
        this.restaurants = restaurants;
        this.changeDetectorRef.detectChanges();
      },
      error: (err) => {
        console.error('Error fetching items:', err);
      },
    });
  }

  public updateData(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));

    this.favouritesService
      .getListById(id)
      .pipe(
        switchMap((list) => {
          this.list = list;
          if (!list) {
            throw { status: 404 };
          }
          return this.favouritesService.getItemsByList(id);
        }),
        tap((items) => {
          this.items = items;
        }),
      )
      .subscribe({
        next: () => {
          this.fetchItems();
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          if (err?.status === 404) {
            this.list = null;
            this.items = [];
          } else {
            console.error('Unexpected error:', err);
          }
          this.changeDetectorRef.detectChanges();
        },
      });
  }
}
