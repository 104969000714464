import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FavouriteListDto } from '../api/generated/abuduba-api';
import { CreateFavouriteItemDtoItemTypeEnum } from '../api/generated/abuduba-api/shared-enums';
import { EventBus } from '../event-bus';
import { AuthService } from '../auth/auth.service';
import { FavouritesService } from '../favourites.service';
import { keyBy } from 'lodash';

@Component({
  selector: 'app-favourite-popup',
  templateUrl: 'favourite-popup.component.html',
  styleUrl: 'favourite-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritePopupComponent {
  @Input()
  public textMode: boolean = true;

  @Input()
  public itemId: number;

  @Input()
  public saved: boolean = false;

  @Input()
  public popup: boolean = true;

  @Input()
  public itemType: CreateFavouriteItemDtoItemTypeEnum;

  public isVisible = false;
  public isProgress = false;
  public isError = false;
  public createListMode = false;
  public listTitleValue: string;
  public lists: (FavouriteListDto & Partial<{ checked: boolean }>)[];

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private favouritesService: FavouritesService,
    public eventBus: EventBus,
  ) {}

  onFavouriteListClick(
    e: Event,
    list: FavouriteListDto & Partial<{ checked: boolean }>,
  ): void {
    list.checked = !list.checked;

    const reqData = {
      listId: list.id,
      itemId: this.itemId,
      itemType: this.itemType,
    };

    if (list.checked) {
      this.favouritesService.addItemToList(reqData).subscribe({
        next: (data) => {
          list.itemsCount++;
          this.eventBus.emit('favourite-item-added', data);
          this.changeDetectorRef.detectChanges();
        },
        error: console.error,
      });
    } else {
      this.favouritesService.removeItemFromList(reqData).subscribe({
        next: () => {
          list.itemsCount--;
          this.eventBus.emit('favourite-item-removed', reqData);
          this.changeDetectorRef.detectChanges();
        },
        error: console.error,
      });
    }
  }

  public getData() {
    this.favouritesService.getLists().subscribe((data) => {
      this.lists = data;

      this.favouritesService
        .getListsOfItem(this.itemId, this.itemType)
        .subscribe((data) => {
          const checkedListsMap = keyBy(data, 'id');

          for (const list of this.lists) {
            if (checkedListsMap[list.id]) {
              list.checked = true;
            }
          }

          if (this.lists.length === 0) {
            this.createListMode = true;
          }

          this.changeDetectorRef.detectChanges();
        });
    });
  }

  public open(e: Event) {
    if (this.authService.isAuth()) {
      this.isVisible = true;
      this.getData();
    } else {
      this.eventBus.emit('open-auth-popup');
    }

    e.preventDefault();
    e.stopPropagation();
  }

  public close(e: Event) {
    this.isVisible = false;
    e.stopPropagation();
    e.preventDefault();
  }

  public createList() {
    if (this.isProgress) {
      return;
    }

    this.isProgress = true;

    this.favouritesService
      .createList({
        title: this.listTitleValue,
      })
      .subscribe({
        next: (data) => {
          this.createListMode = false;
          this.lists.push(data);
        },
        error: () => {
          this.isError = true;
        },
        complete: () => {
          this.isProgress = false;
          this.changeDetectorRef.detectChanges();
        },
      });
  }
}
