'use strict';

/**
 * Create a new object by picking properties off an existing object.
 * The second param can be overloaded as a callback for
 * more fine grained picking of properties.
 * @param {Object} source
 * @param {Array<string>|function(string, Object):boolean} keys
 * @returns {Object}
 */
function pick(source, keys) {
  var filter = function (key, val) {
    return keys.indexOf(key) !== -1 && val !== undefined;
  };
  if (typeof keys === 'function') {
    filter = keys;
  }
  return Object.keys(source).filter(function (key) {
    return filter(key, source[key]);
  }).reduce(function (result, key) {
    result[key] = source[key];
    return result;
  }, {});
}
module.exports = pick;