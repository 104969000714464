<div class="activity-preview" [class.horizontal]="horizontal">
  <a
    class="photo"
    routerLink="/activities/{{ activity.code }}"
    [target]="newTab ? '_blank' : '_self'">
    <img [ngSrc]="activity.preview.smallUrl" fill alt="{{ activity.title }}" />
  </a>

  <div class="info">
    <div>
      <a
        class="title"
        routerLink="/activities/{{ activity.code }}"
        [target]="newTab ? '_blank' : '_self'">
        <h3>{{ activity.title }}</h3>
      </a>

      <div class="rating" *ngIf="activity.reviewRating">
        <ng-icon name="matStarOutline"></ng-icon>
        <div class="value">{{ activity.reviewRating.toFixed(1) }}</div>
        <div class="count">({{ activity.reviewCount }} reviews)</div>
      </div>

      <div class="destinations">
        <ng-icon name="matLocationOnOutline"></ng-icon>
        <div class="value">
          {{ getActivityDestinations(activity) }}
        </div>
      </div>

      <div class="short-info">
        <div class="time" *ngIf="activity.itinerary?.duration">
          <ng-icon name="matScheduleOutline"></ng-icon>
          <div class="value">
            {{ formatActivityDuration(activity.itinerary!.duration!) }}
          </div>
        </div>

        <div class="price" *ngIf="activity.fromPriceAed">
          <ng-icon name="matPaidOutline"></ng-icon>

          <div class="value">
            from {{ currencyManager.format(activity.fromPriceAed) }}
          </div>
        </div>

        <div
          class="free-cancellation"
          *ngIf="activity.cancellationPolicy.type === 'STANDARD'">
          <ng-icon name="matFreeCancellationOutline"></ng-icon>

          <div class="value">Free cancellation</div>
        </div>

        <div class="pickup" *ngIf="isActivityPickupIncluded(activity)">
          <ng-icon name="bootstrapCarFrontFill"></ng-icon>

          <div class="value">Pickup</div>
        </div>
      </div>
    </div>

    <div class="description" [innerHTML]="activity.description"></div>

    <a class="availability" routerLink="/activities/{{ activity.code }}"
      >Check availability</a
    >
  </div>
</div>
