import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ActivitiesApiService,
  ActivityAvailabilityDto,
  ActivityDto,
  ActivityOptionDto,
  ActivityPricingAgeBandDto,
  AvailabilityPaxMixDto,
} from '../../core/api/generated/abuduba-api';
import { formatAgeBand } from '../activities.utils';
import { every, groupBy, max, min, orderBy, some, sortBy, sumBy } from 'lodash';
import { FormControl } from '@angular/forms';
import moment from 'moment/moment';
import { CurrencyManager } from '../../core/currency-manager';
import { ActivityAvailabilityPriceBreakdownDtoAgeBandEnum } from '../../core/api/generated/abuduba-api/shared-enums';

@Component({
  selector: 'app-activity-check-availability',
  styleUrls: ['activity-check-availability.component.scss'],
  templateUrl: 'activity-check-availability.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCheckAvailabilityComponent implements OnInit {
  @Input()
  public activity: ActivityDto;

  public availability: ActivityAvailabilityDto[];
  public sortedOptions: (ActivityOptionDto & {
    availability: ActivityAvailabilityDto[];
    isAnyAvailableOptions: boolean;
  })[];

  @Output()
  public availabilityChange = new EventEmitter<ActivityAvailabilityDto[]>();

  public value: AvailabilityPaxMixDto[] = [];
  public isVisible = false;
  public totalMaxValue: number = 0;
  public totalMinValue: number = 0;
  public unavailableAmount: number = 0;
  public inProgress: boolean = false;
  public showMore = false;
  public errorMessage?: string;

  public datePicker = new FormControl(moment());
  public minDate = new Date();

  constructor(
    private readonly activitiesApiService: ActivitiesApiService,
    public readonly currencyManager: CurrencyManager,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.setDefaultValue();

    this.totalMaxValue =
      max(
        this.activity.pricing.ageBands.map((b) => b.maxTravelersPerBooking),
      ) || 99;
    this.totalMinValue =
      max(
        this.activity.pricing.ageBands.map((b) => b.minTravelersPerBooking),
      ) || 0;

    this.changeDetectorRef.detectChanges();
  }

  public trackBy(index: number) {
    return index;
  }

  public getSortedOptions(): (ActivityOptionDto & {
    availability: ActivityAvailabilityDto[];
    isAnyAvailableOptions: boolean;
  })[] {
    const options = this.activity.productOptions;
    const availabilityMap = groupBy(this.availability, 'productOptionCode');

    if (availabilityMap['undefined']) {
      options.push({
        description: '',
        languageGuides: [],
        productOptionCode: 'undefined',
        title: this.activity.title,
      });
    }

    let fullData = options.map((op) => ({
      ...op,
      availability: orderBy(
        availabilityMap[op.productOptionCode] || [],
        'startTime',
      ),
      isAnyAvailableOptions: !!availabilityMap[op.productOptionCode]?.find(
        (a) => a.available,
      ),
    }));

    if (!this.showMore) {
      fullData = fullData.filter((d) =>
        some(d.availability, { available: true }),
      );
    }

    return orderBy(
      fullData,
      [
        (item) => some(item.availability, { available: true }), // First, elements with any available options
        (item) => some(item.availability), // Then, elements with any unavailable options
        (item) => item.availability && item.availability.length > 0, // Lastly, elements without options
      ],
      ['desc', 'desc', 'desc'],
    );
  }

  public getOptionWithMinPrice(availability: ActivityAvailabilityDto[]) {
    const availabilities = sortBy(availability, 'priceAed');

    return availabilities[0];
  }

  public checkAvailability() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.errorMessage = undefined;
    this.showMore = false;
    this.unavailableAmount = 0;

    this.activitiesApiService
      .getActivityAvailability({
        body: {
          paxMix: this.value,
          productCode: this.activity.code,
          travelDate:
            this.datePicker.value?.utc(true).toISOString() ||
            new Date().toISOString(),
        },
      })
      .subscribe({
        next: (data) => {
          this.availability = data;
          this.availabilityChange.emit(data);
          this.inProgress = false;

          const availabilityMap = groupBy(data, 'productOptionCode');

          this.unavailableAmount = Object.values(availabilityMap).filter((d) =>
            every(d, { available: false }),
          ).length;

          if (this.unavailableAmount === data.length) {
            this.showMore = true;
          }

          this.sortedOptions = this.getSortedOptions();
        },
        error: (err) => {
          this.inProgress = false;
          this.errorMessage =
            'An error occurred while checking availability. Please try again later. If the problem persists, please contact us. Thank you.';
          console.error(err);
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  public isBlocked(
    mode: 'min' | 'max',
    band?: ActivityPricingAgeBandDto['ageBand'],
  ): boolean {
    const foundBand =
      band && this.activity.pricing.ageBands.find((b) => b.ageBand === band);
    const maxBandValue = foundBand?.maxTravelersPerBooking || 99;
    const minBandValue = foundBand?.minTravelersPerBooking || 0;
    const currentBandValue =
      this.value.find((v) => v.ageBand === band)?.numberOfTravelers || 0;

    const maxValue = this.totalMaxValue;
    const minValue = this.totalMinValue;
    const current = sumBy(this.value, 'numberOfTravelers') || 0;

    return mode === 'max'
      ? maxValue <= current || (band ? maxBandValue <= currentBandValue : false)
      : minValue >= current ||
          (band ? minBandValue >= currentBandValue : false);
  }

  public less(band: ActivityPricingAgeBandDto['ageBand']) {
    const bandValue = this.value.find((v) => v.ageBand === band);
    const isBlocked = this.isBlocked('min', band);

    if (bandValue && !isBlocked) {
      bandValue.numberOfTravelers--;
    }
  }

  public more(band: ActivityPricingAgeBandDto['ageBand']) {
    const bandValue = this.value.find((v) => v.ageBand === band);
    const isBlocked = this.isBlocked('max', band);

    if (!isBlocked) {
      if (bandValue) {
        bandValue.numberOfTravelers++;
      } else {
        this.value.push({
          ageBand:
            band || ActivityAvailabilityPriceBreakdownDtoAgeBandEnum.TRAVELER,
          numberOfTravelers: 1,
        });
      }
    }
  }

  public getLabel() {
    const values = this.value.filter((v) => v.numberOfTravelers > 0);
    const maxLength = 3;

    let label =
      values
        .map((v) => formatAgeBand(v.ageBand, v.numberOfTravelers))
        .slice(0, maxLength)
        .join(', ') || 'Number of travelers';

    if (values.length > maxLength) {
      label += `, +${sumBy(values.slice(maxLength), 'numberOfTravelers')}`;
    }

    return label;
  }

  public getBandValue(band: ActivityPricingAgeBandDto['ageBand']): number {
    const bandValue = this.value.find((v) => v.ageBand === band);

    return bandValue?.numberOfTravelers || 0;
  }

  public setDefaultValue() {
    if (this.activity.pricing.type === 'UNIT') {
      this.value.push({
        ageBand: ActivityAvailabilityPriceBreakdownDtoAgeBandEnum.TRAVELER,
        numberOfTravelers: 1,
      });
    } else {
      const bandsByPriority = ['ADULT', 'TRAVELER', 'SENIOR'];

      let ageBand: ActivityPricingAgeBandDto =
        this.activity.pricing.ageBands[0];
      for (const band of bandsByPriority) {
        const foundBand = this.activity.pricing.ageBands.find(
          (b) => b.ageBand === band,
        );

        if (foundBand) {
          ageBand = foundBand;
          break;
        }
      }

      this.value.push({
        ageBand:
          ageBand.ageBand ??
          ActivityAvailabilityPriceBreakdownDtoAgeBandEnum.TRAVELER,
        numberOfTravelers:
          min([
            max([ageBand.minTravelersPerBooking, 2]),
            ageBand.maxTravelersPerBooking,
          ]) || 1,
      });
    }
  }

  protected readonly formatAgeBand = formatAgeBand;
}
