<div class="autocomplete-input">
  <ng-autocomplete
    [id]="id"
    [data]="searchResults"
    [isLoading]="isLoading"
    searchKeyword="title"
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [placeholder]="placeholder"
    (inputChanged)="search($event)"
    [minQueryLength]="minQueryLength"
    [debounceTime]="700"
    [customFilter]="customFilter"
    (selected)="onSearchResultClick($event)"
    (inputCleared)="search('')"
    #searchInput></ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <div class="found-item">
      <div class="img">
        <img
          [ngSrc]="item.previewUrl || '/assets/stub.jpg'"
          fill
          alt="{{ item.title }}" />
      </div>

      <div class="title-block">
        <a [innerHTML]="item.title"></a>
        <span>{{ item.label }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #notFoundTemplate let-item>
    <div>Not found</div>
  </ng-template>
</div>
