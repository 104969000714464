<app-not-found *ngIf="region === null"></app-not-found>

<div id="region_card" *ngIf="region !== null">
  <app-ai-chat
    *ngIf="region"
    title="Chat with AI"
    firstMessage="Hey there! I’m here to help you with any questions about {{
      region.name
    }}"
    [isClosed]="true"></app-ai-chat>

  <div class="content-wrap breadcrumb">
    <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>
  </div>

  <div class="content-wrap" *ngIf="!region">
    <app-loader [animation]="false" width="100%" height="300px"></app-loader>
  </div>

  <div
    class="region-header"
    [style.background]="
      'url(' + region.file?.originalUrl + ') no-repeat center'
    "
    *ngIf="region">
    <div class="title-block content-wrap">
      <h1 class="title">How to explore</h1>
      <h2 class="subtitle">{{ region.name }}</h2>
    </div>

    <div class="general-info-block content-wrap">
      <div class="general-info-item places-item">
        <ng-icon name="matPinDropOutline"></ng-icon>
        <div class="item-value">{{ totalPlaces }} places</div>
      </div>

      <div class="general-info-item routes-item" *ngIf="totalRoutes > 0">
        <ng-icon name="matRouteOutline"></ng-icon>
        <div class="item-value">{{ totalRoutes }} routes</div>
      </div>

      <div
        class="general-info-item restaurants-item"
        *ngIf="totalRestaurants > 0">
        <ng-icon name="phosphorBowlFood"></ng-icon>
        <div class="item-value">{{ totalRestaurants }} restaurants</div>
      </div>
    </div>

    <div class="activities-list">
      <a
        class="activity"
        routerLink="/places"
        [queryParams]="{ activities: [activity.name], regions: [region.id] }"
        *ngFor="let activity of placeActivities">
        <ng-icon [name]="activity.icon"></ng-icon>
        <div class="activity-name">{{ activity.name }}</div>
      </a>

      <a
        class="activity see-all"
        routerLink="/places"
        [queryParams]="{ regions: [region.id] }">
        <ng-icon name="matArrowForwardOutline"></ng-icon>
        <div class="activity-name">See all</div>
      </a>
    </div>
  </div>

  <div class="region-content content-wrap" *ngIf="region">
    <div class="ai-content" *ngIf="region">
      <div class="text">
        <div class="subtitle">Powered by AI <span>BETA</span></div>
        <h2 class="title">Discover {{ region.name }} with Our AI Assistant</h2>
        <div class="prompt">
          Ask our AI chat guide for personalized recommendations and travel
          tips!
        </div>

        <div class="button-block">
          <div class="ai-button" (click)="openAiChat()">
            <ng-icon name="ionSparklesSharp"></ng-icon>
            Speak with AI
          </div>
        </div>
      </div>

      <div class="image">
        <img
          ngSrc="/assets/ai-background.webp"
          width="674"
          height="450"
          alt="AI" />
      </div>
    </div>

    <div class="description-block" [class.open]="isDescriptionOpen">
      <div class="html-content" [innerHTML]="region.description"></div>

      <div class="show-all-button" (click)="isDescriptionOpen = true">
        Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
      </div>
    </div>

    <div class="region-page-block">
      <app-entities-preview-list
        blockTitle="Explore {{ region.name }}"
        subtitle="Find the most interesting places to visit"
        routerLinkValue="/places"
        [nowrap]="true"
        [queryParams]="{
          regions: [region.id]
        }"
        [places]="places"></app-entities-preview-list>
    </div>

    <div class="region-page-block">
      <app-entities-preview-list
        blockTitle="Taste the Flavors"
        subtitle="Discover the best restaurants and culinary delights around you"
        routerLinkValue="/restaurants"
        [nowrap]="true"
        [queryParams]="{
          regions: [region.id]
        }"
        [restaurants]="restaurants"></app-entities-preview-list>
    </div>

    <div class="region-page-block">
      <app-entities-preview-list
        blockTitle="Seeking Challenges and Adventures"
        subtitle="Explore New Challenges and Exciting Adventures in {{
          region.name
        }}"
        [nowrap]="true"
        routerLinkValue="/routes"
        [queryParams]="{
          regions: [region.id]
        }"
        [routes]="routes"></app-entities-preview-list>
    </div>

    <div class="region-page-block">
      <app-entities-preview-list
        blockTitle="Top Photo Spots in UAE"
        subtitle="The best photo spots in {{ region.name }}"
        routerLinkValue="/places"
        [nowrap]="true"
        [queryParams]="{ activities: ['Photo'], regions: [region.id] }"
        [places]="photoSpots"></app-entities-preview-list>
    </div>
  </div>
</div>
