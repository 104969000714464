/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { HealthApiService } from './services/health-api.service';
import { PlacesApiService } from './services/places-api.service';
import { RoutesApiService } from './services/routes-api.service';
import { RegionsApiService } from './services/regions-api.service';
import { PlaceCollectionsApiService } from './services/place-collections-api.service';
import { RestaurantsApiService } from './services/restaurants-api.service';
import { ArticlesApiService } from './services/articles-api.service';
import { ActivitiesApiService } from './services/activities-api.service';
import { SearchApiService } from './services/search-api.service';
import { AssistantApiService } from './services/assistant-api.service';
import { CombinedInfoApiService } from './services/combined-info-api.service';
import { ReviewsApiService } from './services/reviews-api.service';
import { SuggestionsApiService } from './services/suggestions-api.service';
import { ApiApiService } from './services/api-api.service';
import { AuthApiService } from './services/auth-api.service';
import { UsersApiService } from './services/users-api.service';
import { FavouriteApiService } from './services/favourite-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    HealthApiService,
    PlacesApiService,
    RoutesApiService,
    RegionsApiService,
    PlaceCollectionsApiService,
    RestaurantsApiService,
    ArticlesApiService,
    ActivitiesApiService,
    SearchApiService,
    AssistantApiService,
    CombinedInfoApiService,
    ReviewsApiService,
    SuggestionsApiService,
    ApiApiService,
    AuthApiService,
    UsersApiService,
    FavouriteApiService,
    ApiConfiguration,
  ],
})
export class AbudubaApiModule {
  static forRoot(
    params: ApiConfigurationParams,
  ): ModuleWithProviders<AbudubaApiModule> {
    return {
      ngModule: AbudubaApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: AbudubaApiModule,
    @Optional() http: HttpClient,
  ) {
    if (parentModule) {
      throw new Error(
        'AbudubaApiModule is already loaded. Import in your base AppModule only.',
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
