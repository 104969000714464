import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiHelper } from '../api/api.helper';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false; // To prevent multiple refresh calls
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private authService: AuthService,
    private apiHelper: ApiHelper,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.includes(environment.apiUrl) ||
      !this.apiHelper.isBrowser ||
      !this.authService.isAuth()
    ) {
      return next.handle(req);
    }

    const accessToken = this.authService.getAccessToken()!;

    if (accessToken) {
      req = this.addAuthorizationHeader(req, accessToken);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 401 && !req.url.includes('/refresh-token')) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private addAuthorizationHeader(
    req: HttpRequest<any>,
    token: string,
  ): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(res.accessToken); // Notify all waiting requests
          return next.handle(this.addAuthorizationHeader(req, res.accessToken));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.clearTokens();
          return throwError(() => error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addAuthorizationHeader(req, token!));
        }),
      );
    }
  }
}
