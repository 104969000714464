<div class="share-component" appOutsideClick (outsideClick)="isVisible = false">
  <div class="share-button" (click)="isVisible = !isVisible">
    <ng-icon name="bootstrapShare"></ng-icon>
    <span>Share</span>
  </div>

  <div class="share-buttons-block" *ngIf="isVisible">
    <share-buttons
      [theme]="'default'"
      [include]="[
        'telegram',
        'whatsapp',
        'email',
        'print',
        'copy',
        'facebook',
        'x',
        'vk'
      ]"
      [show]="5"></share-buttons>
  </div>
</div>
