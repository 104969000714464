<app-not-found *ngIf="restaurant === null"></app-not-found>

<div id="restaurant_card" class="content-wrap" *ngIf="restaurant !== null">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="restaurant-title-block">
    <div class="restaurant-title">
      <app-loader
        *ngIf="!restaurant"
        [animation]="false"
        width="300px"
        height="20px"></app-loader>

      <h1 class="value">{{ restaurant?.name }}</h1>
    </div>
  </div>

  <div
    class="restaurant-cuisines"
    *ngIf="restaurant?.cuisines && restaurant!.cuisines.length > 0">
    {{ restaurant!.cuisines!.slice(0, 3).map(getCuisineName).join(', ') }}
  </div>

  <div class="subtitle-block">
    <div
      class="restaurant-tripadvisor-google-block"
      *ngIf="restaurant?.tripadvisorData || restaurant?.googleData">
      <app-rating-selector
        [tripadvisor]="restaurant!.tripadvisorData"
        [google]="restaurant!.googleData"></app-rating-selector>
    </div>

    <div class="actions" *ngIf="restaurant">
      <app-favourite-popup
        [textMode]="true"
        [itemId]="restaurant.id"
        [saved]="isSavedInFavourites"
        [itemType]="
          CreateFavouriteItemDtoItemTypeEnum.Restaurant
        "></app-favourite-popup>

      <app-share></app-share>

      <app-suggestion
        title="Leave suggestion for {{ restaurant.name }}"
        entityId="{{ restaurant.id }}"
        [entityType]="
          CreateSuggestionDtoEntityTypeEnum.Restaurant
        "></app-suggestion>
    </div>
  </div>

  <div class="restaurant-media-block">
    <div class="loading-block" *ngIf="!restaurant">
      <div class="left">
        <app-loader [animation]="false" width="100%" height="100%"></app-loader>
      </div>

      <div class="right">
        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>

        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>
      </div>
    </div>

    <app-gallery *ngIf="media" [files]="media"></app-gallery>
  </div>

  <div class="restaurant-content">
    <div
      class="highlights-block"
      *ngIf="restaurant?.highlights && restaurant!.highlights.length > 0">
      <div class="highlight" *ngFor="let f of restaurant?.highlights">
        <ng-icon name="matStarOutline"></ng-icon>

        <div class="value">{{ f }}</div>
      </div>
    </div>

    <div class="main-info-block">
      <div class="left">
        <div
          class="description-block"
          *ngIf="restaurant"
          [class.open]="isDescriptionOpen">
          <div class="html-content" [innerHTML]="restaurant.description"></div>

          <div class="show-all-button" (click)="isDescriptionOpen = true">
            Read more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
          </div>
        </div>
      </div>

      <div class="widgets">
        <div class="widget shadow-block menu" *ngIf="restaurant?.menuUrl">
          <a class="button" [href]="restaurant?.menuUrl" target="_blank">
            <ng-icon name="ionDocumentText"></ng-icon>
            Open menu
          </a>
        </div>

        <div class="widget shadow-block general-info" *ngIf="restaurant">
          <div class="general-info-item cuisines-item">
            <ng-icon name="phosphorBowlFood"></ng-icon>
            <div class="item-value">
              {{
                restaurant.cuisines.slice(0, 3).map(getCuisineName).join(', ')
              }}
            </div>
          </div>

          <div class="general-info-item time-item">
            <ng-icon name="matScheduleOutline"></ng-icon>
            <div class="item-value">
              <div class="open-status">
                {{
                  isOpenNow(
                    getCurrentWorkingTime(restaurant.workingTime),
                    restaurant.tz
                  )
                    ? 'Open now: '
                    : 'Closed: '
                }}
              </div>

              <div class="current-time">
                {{
                  formatWorkingTime(
                    getCurrentWorkingTime(restaurant.workingTime)
                  )
                }}
              </div>

              <div class="expand-block">
                <ng-icon
                  class="expand"
                  (click)="isScheduleExpand = !isScheduleExpand"
                  [name]="
                    isScheduleExpand
                      ? 'matExpandLessOutline'
                      : 'matExpandMoreOutline'
                  "></ng-icon>
              </div>
            </div>
          </div>

          <div
            class="general-info-item full-schedule-item"
            *ngIf="isScheduleExpand">
            <div class="schedule" *ngFor="let time of restaurantWorkingTime">
              <div class="day">{{ time.dayName }}:</div>
              <div class="time">{{ formatWorkingTime(time.hours) }}</div>
            </div>
          </div>

          <div class="general-info-item site-item" *ngIf="restaurant?.site">
            <ng-icon name="matLanguageOutline"></ng-icon>
            <a class="item-value" [href]="restaurant.site" target="_blank">
              {{ restaurant.site }}
            </a>
          </div>

          <div class="general-info-item phone-item" *ngIf="restaurant?.phone">
            <ng-icon name="matCallOutline"></ng-icon>
            <div class="item-value">
              {{ restaurant.phone }}
            </div>
          </div>

          <div class="general-info-item price-level-item">
            <ng-icon name="matPaidOutline"></ng-icon>
            <div class="item-value">
              {{ getPriceLevelName(restaurant.priceLevel) }}
            </div>
          </div>
        </div>

        <div class="widget" *ngIf="!restaurant">
          <app-loader
            [animation]="false"
            width="100%"
            height="150px"></app-loader>
        </div>
      </div>
    </div>

    <div class="location-block">
      <div class="widget shadow-block map" *ngIf="restaurant">
        <app-places-map
          #placesMap
          [center]="{
            lng: restaurant.location.x,
            lat: restaurant.location.y
          }"
          [googleMapLink]="
            restaurant.googleData?.googleMapsUri
          "></app-places-map>
      </div>

      <div
        class="widget shadow-block nearest-restaurants"
        *ngIf="nearestRestaurants && nearestRestaurants.length > 0">
        <div class="title">Nearby Finds:</div>

        <app-restaurant-near-by
          *ngFor="let p of nearestRestaurants"
          [restaurant]="p.restaurant"
          [distance]="p.distance"
          (mouseenter)="highlightItemOnMap(p.restaurant.index, true)"
          (focus)="highlightItemOnMap(p.restaurant.index, true)"
          (mouseleave)="highlightItemOnMap(p.restaurant.index, false)"
          (blur)="
            highlightItemOnMap(p.restaurant.index, false)
          "></app-restaurant-near-by>
      </div>
    </div>
  </div>

  <app-tripadvisor-reviews
    [tripadvisorLocationId]="restaurant!.tripadvisorLocationId!"
    *ngIf="restaurant?.tripadvisorLocationId"></app-tripadvisor-reviews>
</div>
