import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ApiHelper } from '../api/api.helper';

@Component({
  selector: 'app-filter-slider',
  templateUrl: 'filter-slider.component.html',
  styleUrl: 'filter-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSliderComponent implements OnInit {
  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public step: number = 1;

  @Input()
  public valueMin: number = 0;

  @Input()
  public valueMax: number = 0;

  @Output()
  public valueChange = new EventEmitter<number[]>();

  @Input()
  public title: string;

  @Input()
  public name?: string;

  @Input()
  public format?: (val: number) => string;

  public manualRefresh = new EventEmitter<void>();

  constructor(
    public readonly apiHelper: ApiHelper,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  get options() {
    return {
      floor: this.min,
      ceil: this.max || 1,
      translate: this.translate.bind(this),
      step: this.step,
    };
  }

  ngOnInit(): void {
    this.valueMin = 0;
    this.valueMax = this.max;
    this.changeDetectorRef.detectChanges();
  }

  onChange() {
    this.valueChange.emit([this.valueMin, this.valueMax]);
  }

  rerender() {
    this.manualRefresh.emit();
    this.changeDetectorRef.detectChanges();
  }

  clear(emitChange = true) {
    this.valueMin = 0;
    this.valueMax = this.max || 1;
    this.changeDetectorRef.detectChanges();

    if (emitChange) {
      this.onChange();
    }
  }

  translate(value: number): string {
    const formattedValue = this.format ? this.format(value) : String(value);

    return formattedValue;
  }
}
