<app-not-found *ngIf="article === null"></app-not-found>

<div id="article_card" *ngIf="article !== null">
  <div class="content-wrap breadcrumb">
    <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>
  </div>

  <div class="content-wrap" *ngIf="!article">
    <app-loader [animation]="false" width="100%" height="300px"></app-loader>
  </div>

  <div
    class="article-header"
    [style.background]="
      'url(' + article.file?.originalUrl + ') no-repeat center'
    "
    *ngIf="article">
    <div class="title-block content-wrap">
      <h1 class="title">{{ article.title }}</h1>
      <h2 class="category">{{ getCategoryName(article.category) }}</h2>
    </div>
  </div>

  <div class="article-content content-wrap" *ngIf="article">
    <div class="body-block">
      <div class="html-content" [innerHTML]="article.body"></div>
    </div>
  </div>
</div>
