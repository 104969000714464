/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CombedInfoMainPageDto } from '../models/combed-info-main-page-dto';
import { getMainPageData } from '../fn/combined-info/get-main-page-data';
import { GetMainPageData$Params } from '../fn/combined-info/get-main-page-data';

@Injectable({ providedIn: 'root' })
export class CombinedInfoApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMainPageData()` */
  static readonly GetMainPageDataPath = '/api/v1/combined-info/main-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMainPageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMainPageData$Response(
    params?: GetMainPageData$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<CombedInfoMainPageDto>> {
    return getMainPageData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMainPageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMainPageData(
    params?: GetMainPageData$Params,
    context?: HttpContext,
  ): Observable<CombedInfoMainPageDto> {
    return this.getMainPageData$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<CombedInfoMainPageDto>): CombedInfoMainPageDto =>
          r.body,
      ),
    );
  }
}
