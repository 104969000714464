/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ArticleDto } from '../models/article-dto';
import { ArticlePreviewDto } from '../models/article-preview-dto';
import { ArticlesPreviewPaginatedResponseDto } from '../models/articles-preview-paginated-response-dto';
import { getArticleById } from '../fn/articles/get-article-by-id';
import { GetArticleById$Params } from '../fn/articles/get-article-by-id';
import { getArticleByIndex } from '../fn/articles/get-article-by-index';
import { GetArticleByIndex$Params } from '../fn/articles/get-article-by-index';
import { getArticles } from '../fn/articles/get-articles';
import { GetArticles$Params } from '../fn/articles/get-articles';
import { getArticlesPreview } from '../fn/articles/get-articles-preview';
import { GetArticlesPreview$Params } from '../fn/articles/get-articles-preview';
import { searchArticlesPreview } from '../fn/articles/search-articles-preview';
import { SearchArticlesPreview$Params } from '../fn/articles/search-articles-preview';

@Injectable({ providedIn: 'root' })
export class ArticlesApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getArticleById()` */
  static readonly GetArticleByIdPath = '/api/v1/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleById$Response(
    params: GetArticleById$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ArticleDto>> {
    return getArticleById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleById(
    params: GetArticleById$Params,
    context?: HttpContext,
  ): Observable<ArticleDto> {
    return this.getArticleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleDto>): ArticleDto => r.body),
    );
  }

  /** Path part for operation `getArticleByIndex()` */
  static readonly GetArticleByIndexPath = '/api/v1/articles/index/{index}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleByIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleByIndex$Response(
    params: GetArticleByIndex$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ArticleDto>> {
    return getArticleByIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticleByIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleByIndex(
    params: GetArticleByIndex$Params,
    context?: HttpContext,
  ): Observable<ArticleDto> {
    return this.getArticleByIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleDto>): ArticleDto => r.body),
    );
  }

  /** Path part for operation `getArticles()` */
  static readonly GetArticlesPath = '/api/v1/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticles$Response(
    params?: GetArticles$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ArticleDto>>> {
    return getArticles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticles(
    params?: GetArticles$Params,
    context?: HttpContext,
  ): Observable<Array<ArticleDto>> {
    return this.getArticles$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<Array<ArticleDto>>): Array<ArticleDto> => r.body,
      ),
    );
  }

  /** Path part for operation `getArticlesPreview()` */
  static readonly GetArticlesPreviewPath = '/api/v1/articles/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticlesPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticlesPreview$Response(
    params?: GetArticlesPreview$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ArticlePreviewDto>>> {
    return getArticlesPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticlesPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticlesPreview(
    params?: GetArticlesPreview$Params,
    context?: HttpContext,
  ): Observable<Array<ArticlePreviewDto>> {
    return this.getArticlesPreview$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<ArticlePreviewDto>>,
        ): Array<ArticlePreviewDto> => r.body,
      ),
    );
  }

  /** Path part for operation `searchArticlesPreview()` */
  static readonly SearchArticlesPreviewPath = '/api/v1/articles/search/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchArticlesPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchArticlesPreview$Response(
    params?: SearchArticlesPreview$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ArticlesPreviewPaginatedResponseDto>> {
    return searchArticlesPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchArticlesPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchArticlesPreview(
    params?: SearchArticlesPreview$Params,
    context?: HttpContext,
  ): Observable<ArticlesPreviewPaginatedResponseDto> {
    return this.searchArticlesPreview$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<ArticlesPreviewPaginatedResponseDto>,
        ): ArticlesPreviewPaginatedResponseDto => r.body,
      ),
    );
  }
}
