/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AssistantResponseDto } from '../../models/assistant-response-dto';

export interface AskAssistant$Params {
  input: string;
  threadId?: string;
  placeId?: number;
}

export function askAssistant(
  http: HttpClient,
  rootUrl: string,
  params: AskAssistant$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<AssistantResponseDto>> {
  const rb = new RequestBuilder(rootUrl, askAssistant.PATH, 'get');
  if (params) {
    rb.query('input', params.input, {});
    rb.query('threadId', params.threadId, {});
    rb.query('placeId', params.placeId, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssistantResponseDto>;
      }),
    );
}

askAssistant.PATH = '/api/v1/assistant/ask';
