import { Component, Input } from '@angular/core';
import { ApiHelper } from '../api/api.helper';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrl: 'loader.component.scss',
})
export class LoaderComponent {
  @Input()
  public animation = true;

  @Input()
  public count?: number;

  @Input()
  public height?: string;

  @Input()
  public width?: string;

  @Input()
  public marginTop?: string;

  public options: AnimationOptions = {
    path: 'assets/loading.json',
  };

  constructor(public readonly apiHelper: ApiHelper) {}
}
