import { ArticleDtoCategoryEnum } from '../core/api/generated/abuduba-api/shared-enums';

export const articleCategories: {
  [key in ArticleDtoCategoryEnum]: any;
} = {
  Food: {
    name: 'Food',
    icon: 'phosphorBowlFood',
  },
  Features: {
    name: 'Features',
    icon: 'matStarOutline',
  },
  ThingsToDo: {
    name: 'Things to do',
    icon: 'matAttractions',
  },
};
