/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authGoogleUser } from '../fn/auth/auth-google-user';
import { AuthGoogleUser$Params } from '../fn/auth/auth-google-user';
import { AuthResponseDto } from '../models/auth-response-dto';
import { refreshAuthToken } from '../fn/auth/refresh-auth-token';
import { RefreshAuthToken$Params } from '../fn/auth/refresh-auth-token';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authGoogleUser()` */
  static readonly AuthGoogleUserPath = '/api/v1/auth/google';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authGoogleUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  authGoogleUser$Response(
    params: AuthGoogleUser$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseDto>> {
    return authGoogleUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authGoogleUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authGoogleUser(
    params: AuthGoogleUser$Params,
    context?: HttpContext,
  ): Observable<AuthResponseDto> {
    return this.authGoogleUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseDto>): AuthResponseDto => r.body),
    );
  }

  /** Path part for operation `refreshAuthToken()` */
  static readonly RefreshAuthTokenPath = '/api/v1/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshAuthToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshAuthToken$Response(
    params: RefreshAuthToken$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseDto>> {
    return refreshAuthToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshAuthToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshAuthToken(
    params: RefreshAuthToken$Params,
    context?: HttpContext,
  ): Observable<AuthResponseDto> {
    return this.refreshAuthToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseDto>): AuthResponseDto => r.body),
    );
  }
}
