<div class="article-preview" [class.horizontal]="horizontal">
  <a
    class="img"
    routerLink="/articles/{{ article.index }}"
    [target]="newTab ? '_blank' : '_self'">
    <img
      [ngSrc]="getMainPictureUrl([article.file], 'medium')"
      fill
      [alt]="article.title" />
  </a>

  <div class="info">
    <a
      routerLink="/articles/{{ article.index }}"
      class="title"
      [target]="newTab ? '_blank' : '_self'">
      <h3>{{ article.title }}</h3>
    </a>

    <div class="category">{{ getCategoryName(article.category) }}</div>

    <div class="prompt">{{ article.prompt }}</div>
  </div>
</div>
