<div id="internal_error">
  <div class="error-content">
    <ng-lottie
      *ngIf="apiHelper.isBrowser"
      width="300px"
      [options]="{
        path: 'assets/404.json'
      }" />

    <div class="title">Sorry, something went wrong on our end</div>

    <div class="subtitle">
      There was an issue processing your request. Please try again later, or
      contact support if the problem continues.
    </div>

    <a class="home" routerLink="/">Go Back Home</a>
  </div>
</div>
