<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li
      *ngFor="let item of items; let isLast = last"
      class="breadcrumb-item"
      [class.active]="isLast">
      <ng-container *ngIf="!isLast; else lastBreadcrumb">
        <a [routerLink]="item.url" [queryParams]="item.qs">{{ item.label }}</a>
      </ng-container>
      <ng-template #lastBreadcrumb>
        {{ item.label }}
      </ng-template>
    </li>
  </ol>
</nav>
