import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  CreateSuggestionDto,
  SuggestionsApiService,
} from '../api/generated/abuduba-api';

@Component({
  selector: 'app-suggestion',
  templateUrl: 'suggestion.component.html',
  styleUrl: 'suggestion.component.scss',
})
export class SuggestionComponent {
  @Input()
  public title: string;

  @Input()
  public entityId?: string;

  @Input()
  public hideButton: boolean;

  @Input()
  public entityType: CreateSuggestionDto['entityType'];

  public isVisible = false;
  public isProgress = false;
  public isSuccess = false;
  public isError = false;
  public bodyValue: string;

  constructor(
    private suggestionsApiService: SuggestionsApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public sendSuggestion(body: string) {
    if (this.isProgress) {
      return;
    }

    this.isProgress = true;
    this.isError = false;
    this.isSuccess = false;

    this.suggestionsApiService
      .createSuggestion({
        body: {
          entityId: this.entityId,
          entityType: this.entityType,
          body,
          pageUrl: window.location.href,
        },
      })
      .subscribe({
        next: () => {
          this.isSuccess = true;
          this.isProgress = false;
        },
        error: () => {
          this.isError = true;
          this.isProgress = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }
}
