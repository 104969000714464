import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import { PlaceFullPageComponent } from './places/place-full-page/place-full-page.component';
import { PlacesListPageComponent } from './places/places-list-page/places-list-page.component';
import { RoutesListPageComponent } from './routes/routes-list-page/routes-list-page.component';
import { RouteFullPageComponent } from './routes/route-full-page/route-full-page.component';
import { MainComponent } from './main/main.component';
import { PlaceCollectionsPageComponent } from './places/place-collections-page/place-collections-page.component';
import { SearchPageComponent } from './search/search-page.component';
import { PrivacyPageComponent } from './legal/privacy/privacy-page.component';
import { TermsPageComponent } from './legal/terms/terms-page.component';
import { PlaceCollectionFullPageComponent } from './places/place-collection-full-page/place-collection-full-page.component';
import { RegionComponent } from './region/region-full-page/region.component';
import { RegionsListComponent } from './region/regions-page/regions.component';
import { ActivitiesListPageComponent } from './activities/activities-list-page/activities-list-page.component';
import { ActivityFullPageComponent } from './activities/activity-full-page/activity-full-page.component';
import { InternalErrorComponent } from './misc/internal-error/internal-error.component';
import { RestaurantsListPageComponent } from './restaurants/restaurants-list-page/restaurants-list-page.component';
import { RestaurantFullPageComponent } from './restaurants/restaurant-full-page/restaurant-full-page.component';
import { ArticleListPageComponent } from './articles/articles-list-page/article-list-page.component';
import { ArticleFullPageComponent } from './articles/article-full-page/article-full-page.component';
import { AuthComponent } from './auth/auth.component';
import { FavouritesPageComponent } from './favourites/favourites-page/favourites-page.component';
import { AuthGuard } from './core/auth/auth.guard';
import { FavouriteListFullPageComponent } from './favourites/favourite-list-full-page/favourite-list-full-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MainComponent,
      },
      {
        path: 'auth/:provider',
        component: AuthComponent,
      },
      {
        path: 'privacy',
        component: PrivacyPageComponent,
      },
      {
        path: 'terms',
        component: TermsPageComponent,
      },
      {
        path: 'favourites',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: FavouritesPageComponent,
          },
          {
            path: 'list/:id',
            component: FavouriteListFullPageComponent,
          },
        ],
      },
      {
        path: 'regions',
        children: [
          {
            path: '',
            component: RegionsListComponent,
          },
          {
            path: ':index',
            component: RegionComponent,
          },
        ],
      },
      {
        path: 'places',
        children: [
          {
            path: '',
            component: PlacesListPageComponent,
          },

          {
            path: ':index',
            component: PlaceFullPageComponent,
          },
        ],
      },
      {
        path: 'articles',
        children: [
          {
            path: '',
            component: ArticleListPageComponent,
          },

          {
            path: ':index',
            component: ArticleFullPageComponent,
          },
        ],
      },
      {
        path: 'restaurants',
        children: [
          {
            path: '',
            component: RestaurantsListPageComponent,
          },
          {
            path: ':index',
            component: RestaurantFullPageComponent,
          },
        ],
      },
      {
        path: 'collections',
        children: [
          {
            path: '',
            component: PlaceCollectionsPageComponent,
          },
          {
            path: ':index',
            component: PlaceCollectionFullPageComponent,
          },
        ],
      },
      {
        path: 'activities',
        children: [
          {
            path: '',
            component: ActivitiesListPageComponent,
          },
          {
            path: ':code',
            component: ActivityFullPageComponent,
          },
        ],
      },
      {
        path: 'search',
        children: [
          {
            path: '',
            component: SearchPageComponent,
          },
        ],
      },
      {
        path: 'routes',
        children: [
          {
            path: '',
            component: RoutesListPageComponent,
          },
          {
            path: ':index',
            component: RouteFullPageComponent,
          },
        ],
      },
      {
        path: '500',
        component: InternalErrorComponent,
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
