<div class="google-rating" [class.small]="small" *ngIf="data.rating">
  <img
    *ngIf="!small"
    ngSrc="/assets/google-icon.png"
    [width]="25"
    [height]="25"
    alt="Rating" />

  <div class="rating-block">
    <div class="rating-number">
      <ng-icon name="matStarOutline"></ng-icon>
      <div class="value">{{ data.rating.toFixed(1) }}</div>
    </div>

    <a class="num-reviews" [href]="data.googleMapsUri" target="_blank"
      >{{ data.userRatingCount }} reviews</a
    >
  </div>
</div>
