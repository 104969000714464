import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-page',
  styleUrls: ['privacy-page.component.scss'],
  templateUrl: 'privacy-page.component.html',
})
export class PrivacyPageComponent {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {
    titleService.setTitle('Abuduba - Privacy');

    metaService.updateTag({
      name: 'description',
      content:
        'Learn about Abuduba’s commitment to protecting your privacy. Understand how we handle your personal data and ensure your information is secure while using our services across the UAE.',
    });
    metaService.updateTag({
      name: 'keywords',
      content:
        'Abuduba privacy policy, data protection UAE, personal information security, privacy practices, Abuduba service, user privacy UAE, secure data handling, Abuduba UAE privacy, information security, privacy rights UAE',
    });
  }
}
