import { Component, Input } from '@angular/core';
import ImageViewer from 'awesome-image-viewer';

export interface IGalleryFile {
  url: string;
  mediumUrl: string;
  thumbUrl: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: 'gallery.component.html',
  styleUrl: 'gallery.component.scss',
})
export class GalleryComponent {
  @Input()
  public files: IGalleryFile[];

  openImage(index?: number): void {
    const instance = new ImageViewer({
      images: this.files.map((f) => ({
        mainUrl: f.url,
        thumbnailUrl: f.thumbUrl,
      })),
      // currentSelected: index,
      isZoomable: true,
    });

    // @ts-expect-error protected
    const dom = instance.view;
    dom.querySelector('.touchSurface')?.remove();
    const imagesWrapper = dom.querySelector('.imagesWrapper');
    if (imagesWrapper) {
      imagesWrapper.addEventListener(
        'click',
        (event) => {
          const target = event.target as Element;

          if (target && !target.closest('.imageContainer .image')) {
            instance.hide();
          }
        },
        { once: true },
      );

      const imageContainers = imagesWrapper.children;

      // "Disable" lazyloading
      for (let i = 0; i < imageContainers.length; i++) {
        const imageContainer: any = imageContainers.item(i);
        const url = imageContainer.dataset.url;
        const image = imageContainer!.getElementsByClassName('image')[0];
        (image as any).src = url;
      }

      //Scroll to clicked image (selected by index)
      setTimeout(() => {
        const imageContainer: any = imageContainers.item(index || 0);
        const imageCenterPosition =
          imageContainer.offsetLeft -
          (imagesWrapper.getBoundingClientRect().width -
            imageContainer.getBoundingClientRect().width) /
            2;
        imagesWrapper.scrollTo({
          left: imageCenterPosition,
          behavior: 'instant',
        });
      }, 100);
    }
  }

  get mainImage(): IGalleryFile {
    return this.files[0];
  }

  get sideImages(): IGalleryFile[] {
    return this.files.slice(1, 5);
  }

  public imageTrackBy(index: number) {
    return index;
  }
}
