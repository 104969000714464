import { ArticleDtoCategoryEnum } from '../../shared-enums';
import { GetArticlesPreviewSortByEnum } from '../../shared-enums';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArticleDto } from '../../models/article-dto';

export interface GetArticles$Params {
  offset?: number;
  limit?: number;
  ids?: Array<number>;
  categories?: ArticleDtoCategoryEnum[];
  sortBy?: GetArticlesPreviewSortByEnum;
}

export function getArticles(
  http: HttpClient,
  rootUrl: string,
  params?: GetArticles$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<ArticleDto>>> {
  const rb = new RequestBuilder(rootUrl, getArticles.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {});
    rb.query('limit', params.limit, {});
    rb.query('ids', params.ids, {});
    rb.query('categories', params.categories, {});
    rb.query('sortBy', params.sortBy, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context }),
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ArticleDto>>;
      }),
    );
}

getArticles.PATH = '/api/v1/articles';
