import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PlaceCollectionPreviewDto,
  PlaceDto,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  formatMinutes,
  getActivityIcon,
  getActivityName,
  getMainPictureUrl,
  removeHtmlTags,
} from '../places.utils';
import { compact, flatten, uniq } from 'lodash';
import { CurrencyManager } from '../../core/currency-manager';

dayjs.extend(utc);

@Component({
  selector: 'app-place-collection-preview',
  styleUrls: ['place-collection-preview.component.scss'],
  templateUrl: 'place-collection-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceCollectionPreviewComponent {
  @Input()
  public collection: PlaceCollectionPreviewDto;

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  @Input()
  public minimize = false;

  constructor(public readonly currencyManager: CurrencyManager) {}

  public getActivities(): PlaceDto['activities'] {
    return <PlaceDto['activities']>(
      uniq(
        flatten(this.collection.placesData.map((place) => place.activities)),
      ).splice(0, this.horizontal ? 5 : 3)
    );
  }

  public getPlacePhotos(): { url: string; name: string }[] {
    const images = compact(
      this.collection.placesData.map(
        (p) =>
          p.picture?.smallSizeUrl && {
            url: p.picture?.smallSizeUrl,
            name: p.name,
          },
      ),
    ).slice(0, 4);

    if (images.length < 4) {
      for (let i = images.length; i <= 4; i++) {
        images.push({
          url: '/assets/stub.jpg',
          name: 'Unknown',
        });
      }
    }

    return images;
  }

  public imageTrackBy(index: number) {
    return index;
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
  protected readonly formatMinutes = formatMinutes;
  protected readonly getActivityIcon = getActivityIcon;
  protected readonly getActivityName = getActivityName;
  protected readonly removeHtmlTags = removeHtmlTags;
}
