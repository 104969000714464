<div
  class="place-collection-preview"
  [class.horizontal]="horizontal"
  [class.minimize]="minimize">
  <a
    class="place-photos"
    routerLink="/collections/{{ collection.index }}"
    [target]="newTab ? '_blank' : '_self'">
    <div
      class="img"
      *ngFor="let item of getPlacePhotos(); trackBy: imageTrackBy">
      <img [ngSrc]="item.url" [alt]="item.name" fill />
    </div>

    <div class="favourite" *ngIf="collection.favourite">
      Top Picks
      <ng-icon name="matStarOutline"></ng-icon>
    </div>
  </a>

  <div class="info">
    <a
      class="title"
      routerLink="/collections/{{ collection.index }}"
      [target]="newTab ? '_blank' : '_self'"
      ><h3>{{ collection.name }}</h3></a
    >

    <div class="short-info">
      <div class="count">
        <ng-icon name="matPlaceOutline"></ng-icon>
        {{ collection.placeIds.length }} places
      </div>
    </div>

    <div
      class="description"
      *ngIf="horizontal"
      [innerHTML]="removeHtmlTags(collection.description)"></div>

    <div class="labels">
      <div
        class="label activity"
        *ngFor="let activity of getActivities()"
        [title]="getActivityName(activity)">
        <ng-icon [name]="getActivityIcon(activity)"></ng-icon>
        <span>{{ getActivityName(activity) }}</span>
      </div>
    </div>
  </div>
</div>
