<div class="route-near-by">
  <a class="photo" routerLink="/routes/{{ route.index }}" target="_blank">
    <img
      [ngSrc]="getMainPictureUrl([route.preview], 'thumb')"
      fill
      alt="{{ route.name }}" />
  </a>

  <div class="info">
    <div class="title-block">
      <a
        class="title-value"
        routerLink="/routes/{{ route.index }}"
        target="_blank">
        <h4>{{ route.name }}</h4>
      </a>
      <div class="distance">
        <span class="dot"></span>
        <span class="value">{{ formatDistance(distance) }}</span>
      </div>
    </div>

    <div class="type">
      {{ getTypeName(route.type) }}
    </div>

    <div class="short-info">
      <div class="duration" title="Duration" *ngIf="route.duration">
        <ng-icon name="matHourglassTopOutline"></ng-icon>
        {{ formatMinutes(route.duration) }}
      </div>

      <div class="distance" title="Distance" *ngIf="route.distance">
        <ng-icon name="matRouteOutline"></ng-icon>
        {{ formatDistance(route.distance) }}
      </div>

      <div class="places" title="Places" *ngIf="route.placeIds">
        <ng-icon name="matPlaceOutline"></ng-icon>
        {{ route.placeIds.length }} places
      </div>
    </div>
  </div>
</div>
