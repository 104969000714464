import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

export type ListPopoverItem = { value: string; label: string };

@Component({
  selector: 'app-custom-select',
  templateUrl: 'custom-select.component.html',
  styleUrl: 'custom-select.component.scss',
})
export class CustomSelectComponent {
  @Input()
  public items: ListPopoverItem[];

  @Input()
  public value?: string;

  @Input()
  public icon?: string;

  @Input()
  public title: string;

  @Output()
  public valueChange = new EventEmitter<ListPopoverItem>();

  public isOpen = false;

  @ViewChild('itemsElement')
  public itemsElement: ElementRef;

  ngOnInit() {
    if (this.value) {
      this.setValue(this.value, false);
    }
  }

  public setValue(value: string, emit = true) {
    const item = this.items.find((i) => i.value === value);

    if (item) {
      this.value = value;
      if (emit) {
        this.valueChange.emit(item);
      }
    }

    this.close();
  }

  public get currentItem() {
    return this.items.find((i) => i.value === this.value);
  }

  public open() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
  }

  public toggle() {
    this.isOpen = !this.isOpen;
  }

  public close() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
  }
}
