import { Component, Input, OnInit } from '@angular/core';
import {
  PlaceGoogleInfoDto,
  PlaceTripadvisorInfoDto,
} from '../api/generated/abuduba-api';

@Component({
  selector: 'app-rating-selector',
  templateUrl: 'rating-selector.component.html',
  styleUrl: 'rating-selector.component.scss',
})
export class RatingSelectorComponent implements OnInit {
  @Input()
  public google?: PlaceGoogleInfoDto;

  @Input()
  public tripadvisor?: PlaceTripadvisorInfoDto;

  @Input()
  public small: boolean = false;

  @Input()
  public textOnly: boolean = false;

  public highestRating: {
    source: string;
    data: any;
  } | null = null;

  ngOnInit(): void {
    this.highestRating = [
      { source: 'google', data: this.google },
      { source: 'tripadvisor', data: this.tripadvisor },
    ]
      .filter((s) => s?.data && s.data.rating)
      .reduce(
        (prev, curr) =>
          prev && prev.data.rating! > curr.data!.rating! ? prev : curr,
        null as {
          source: string;
          data: any;
        } | null,
      );
  }
}
