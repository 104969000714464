<div class="filter-checkbox">
  <div class="title-block">
    <div class="title">{{ title }}</div>
    <div class="clear" *ngIf="value.length > 0" (click)="clear()">clear</div>
  </div>

  <div class="list">
    <div class="checkbox" *ngFor="let item of shownOptions; index as i">
      <label class="checkbox-input-block" [class.radio-buttons]="radioButtons">
        <input
          [type]="radioButtons ? 'radio' : 'checkbox'"
          [name]="inputsResultName"
          [value]="item.value"
          [checked]="value.includes(item.value)"
          id="{{ inputsResultName }}_{{ i }}"
          (change)="onChange($event, item)" />
        <span></span>
      </label>

      <label for="{{ inputsResultName }}_{{ i }}">{{ item.label }}</label>
    </div>
  </div>

  <div
    class="show-more"
    *ngIf="shownOptions.length !== options.length && isCropped"
    (click)="showAll(!isCropped)">
    Show more <ng-icon name="matKeyboardArrowDownOutline"></ng-icon>
  </div>

  <div class="show-more" *ngIf="!isCropped" (click)="showAll(!isCropped)">
    Show less <ng-icon name="matKeyboardArrowUpOutline"></ng-icon>
  </div>
</div>
