<div class="pagination" *ngIf="totalPages > 1">
  <div class="arrow left" (click)="previousPage()">
    <ng-icon name="matChevronLeftOutline"></ng-icon>
  </div>
  <div class="pages">
    <div
      class="page"
      [class.current]="i === currentPage"
      *ngFor="let i of paginationArray"
      (click)="i !== '...' && setPage(i)">
      {{ i }}
      <div class="loading-block" *ngIf="isLoading && i === currentPage">
        <div class="loading-animation"></div>
      </div>
    </div>
  </div>
  <div class="arrow right" (click)="nextPage()">
    <ng-icon name="matChevronRightOutline"></ng-icon>
  </div>
</div>
